import React from 'react';
import { theme } from 'theme.js';
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaskedInput from 'react-text-mask';
import Link from '@material-ui/core/Link';
import axios from 'interceptors';
import Typography from '@material-ui/core/Typography';
import logo from 'static/images/logo/LOGOS_TRANSPARENTE-08.png';
import Checkbox from '@material-ui/core/Checkbox';
import {UsersApi} from 'config/config';
import { Redirect } from 'react-router-dom';
import {Translation, initReactI18next } from 'react-i18next';
import i18n from 'i18n'
import 'Signup.css'


i18n
  .use(initReactI18next)

const SignupCheckbox = withStyles((theme) => ({
    root: {
        '&:hover': {
          backgroundColor: '#70707020',
        },
        color: '#ffffff23',
    },
    colorSecondary: {
        '&:hover': {
            backgroundColor: '#70707020',
        },
        '&$checked': {
            color: '#ffffff',
        },
        
    },
    checked: {},
}))(Checkbox);

const SignupLink = withStyles((theme) => ({
    root: {
        color: "#FFFFFF",
        marginTop:'2%',
        paddingLeft: '6px',
    },
    
}))(Link);

const PasswordTextField = withStyles((theme) => ({
    root: {
        '& .MuiFormHelperText-root': {
            color: "#FFFFFF",
            fontSize: "0.85rem"
        }
    }
}))(TextField);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  class SignupContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            waitFor: false,
            redirect: null,
            open: false,
            openError: false,
            openTerms: false,
            openPrivacy: false,
            name: '',
            lastName: '',
            birthdate: '',
            mobile: '',
            cpf: '',
            email: '',
            password: '',
            repeatPassword: '',
            termsChecked: true,
            showPasswordChecked: false,
            passwordHelperText: 'Importante: Sua senha deve possuir no mínimo 8 caracteres e conter letras e números.',
            checkPasswordHelperText: '',
            errorMessage: '',
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleMobileChange = this.handleMobileChange.bind(this);
        this.handleCpfChange = this.handleCpfChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRepeatPasswordChange = this.handleRepeatPasswordChange.bind(this);
        this.handleBirthdateChange = this.handleBirthdateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBackToLogin = this.handleBackToLogin.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseSuccess = this.handleCloseSuccess.bind(this);
        this.handleTermsChange = this.handleTermsChange.bind(this);
        this.handleShowPasswordChange = this.handleShowPasswordChange.bind(this);
        this.getErrorNumber = this.getErrorNumber.bind(this);
    }

    getErrorNumber() {
        if (!this.state.errorMessage) {
            return '#000000';
        }
        return this.state.errorMessage.substring(0,7);
    }

    

    componentDidMount() {
    
    }

    componentDidUpdate() { 
    
    }

    handleShowPasswordChange(event) {
        this.setState({
            showPasswordChecked: event.target.checked,
        });
    }

    handleTermsChange(event) {
        this.setState({
            termsChecked: event.target.checked,
        });
    }

    handleNameChange(event) {
        this.setState({
            name: event.target.value,
        });
    }

    handleLastNameChange(event) {
        this.setState({
            lastName: event.target.value,
        });
    }

    handleMobileChange(event) {
        this.setState({
            mobile: event.target.value,
        });
    }

    handleBirthdateChange(event) {
        this.setState({
            birthdate: event.target.value,
        });
    }

    handleCpfChange(event) {
        this.setState({
            cpf: event.target.value,
        });
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value,
        });
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value,
        });
        if (event.target.value === this.state.repeatPassword) {
            this.setState({
                checkPasswordHelperText: ''
            });
        } else {
            this.setState({
                checkPasswordHelperText: 'Senhas diferentes'
            });
        }
        var strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])?(?=.{8,})");
        if(strongRegex.test(event.target.value)) {
            this.setState({
                passwordHelperText: ''
            });
        } else {
            this.setState({
                passwordHelperText: 'Importante: Sua senha deve possuir no mínimo 8 caracteres e conter letras e números.'
            });
        }
    }

    handleRepeatPasswordChange(event) {
        this.setState({
            repeatPassword: event.target.value,
        });
        if (event.target.value === this.state.password) {
            this.setState({
                checkPasswordHelperText: ''
            });
        } else {
            this.setState({
                checkPasswordHelperText: 'Senhas diferentes'
            });
        }
    }

    handleBackToLogin(event) {
        this.setState({redirect: '/login'});
    }

    handleSubmit(event) {

        const birthdateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
        const cpfRegex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/;
        const mobileRegex = /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/;
        const emailRegex = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
        var strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])?(?=.{8,})");

        
        if (!birthdateRegex.test(this.state.birthdate)) {
            this.setState({
                openError: true,
                errorMessage: '#120410'
            });
            return;
        }

        if (!cpfRegex.test(this.state.cpf)) {
            this.setState({
                openError: true,
                errorMessage: '#120402'
            });
            return;
        }

        if (!mobileRegex.test(this.state.mobile)) {
            this.setState({
                openError: true,
                errorMessage: '#120411'
            });
            return;
        }
        if(!emailRegex.test(this.state.email)) {
            this.setState({
                openError: true,
                errorMessage: '#120412'
            });
            return;
        }
        if(!strongRegex.test(this.state.password)) {
            this.setState({
                openError: true,
                errorMessage: '#120413',
                passwordHelperText: 'Importante: sua senha deve possuir no mínimo 8 caracteres e conter letras e números.'
            });
            return;
        }

        this.setState({ waitFor: true });
        const requestOptions = {
            url: UsersApi.uri,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data:   {
                        name: this.state.name,
                        last_name: this.state.lastName,
                        birthdate: this.state.birthdate,
                        mobile_number: this.state.mobile,
                        cpf: this.state.cpf,
                        email: this.state.email,
                        password: this.state.password,
                    },
            
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                if (response.status === 200 || response.status === 201) {
                    this.setState({ 
                        open: true,
                    });
                } else {
                    const error = (data && data) || response.status; 
                    
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    errorMessage: error.response?.data?.reason,
                    waitFor: false,
                    openError: true,
                });
            });

    }

    handleClose() {
        this.setState({
            openError: false,
            openTerms: false,
            openPrivacy: false,
            redirect: null
        });
    }

    handleCloseSuccess() {
        this.setState({
            open: false,
            redirect: '/login'
        });
    }



    render() {
        const openDialogTerms = (event) => {
            event.preventDefault();
            this.setState({
                openTerms: true
            });
        };
       
        const openPrivacy = (event) => {
            event.preventDefault();
            this.setState({
                openPrivacy: true
            });
        };

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <Translation>
            {
            t =>
            <div className="container">
                <Grid container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start" 
                    spacing={1}
                    className='signup-grid-container' >
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={6} >
                            <img alt="logo" src={logo} className='signup-logo' />
                            <Typography gutterBottom variant="h5" component="h2">
                                    Cadastro
                            </Typography>   
                        </Grid>
                        <Grid item xs={3} >
                        </Grid>



                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={3} >
                            <TextField
                                autoFocus
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder="Nome"
                                label="Nome" 
                                fullWidth
                                required
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                inputProps={{ maxLength: 25 }}
                            />
                        </Grid>
                        <Grid item xs={3} >

                            <TextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder="Sobrenome"
                                label="Sobrenome" 
                                fullWidth
                                required
                                value={this.state.lastName}
                                onChange={this.handleLastNameChange}
                                inputProps={{ maxLength: 40 }}
                            />
                        </Grid>
                        <Grid item xs={3} >
                        </Grid>


                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={2} >
                            <TextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder="Data de nascimento"
                                label="Data de nascimento" 
                                fullWidth
                                required
                                value={this.state.birthdate}
                                onChange={this.handleBirthdateChange}
                                InputProps={{
                                    inputComponent: TextMaskBirthDate,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} >
                            <TextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder="CPF"
                                label="CPF" 
                                fullWidth
                                required
                                value={this.state.cpf}
                                onChange={this.handleCpfChange}
                                InputProps={{
                                    inputComponent: TextMaskCpf,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder="Celular"
                                label="Celular" 
                                fullWidth
                                required
                                value={this.state.mobile}
                                onChange={this.handleMobileChange}
                                InputProps={{
                                    inputComponent: TextMaskMobile,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3} >
                        </Grid>


                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder="Email"
                                label="Email" 
                                type="email"
                                fullWidth
                                required
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                            />
                         </Grid>
                        <Grid item xs={3} >
                        </Grid>


                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={3} >
                            <PasswordTextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder="Senha"
                                label="Senha" 
                                helperText={this.state.passwordHelperText}
                                required
                                fullWidth
                                type={this.state.showPasswordChecked ? 'text' : 'password'}
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <PasswordTextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder="Repetir senha"
                                label="Repetir senha" 
                                required
                                fullWidth
                                helperText={this.state.checkPasswordHelperText}
                                type={this.state.showPasswordChecked ? 'text' : 'password'}
                                value={this.state.repeatPassword}
                                onChange={this.handleRepeatPasswordChange}
                            />
                        </Grid>
                        <Grid item xs={3} >
                        </Grid>
                        


                        <Grid item xs={3}>
                        
                        </Grid>
                        <Grid item xs={6} >
                            <div>
                                <SignupCheckbox
                                        checked={this.state.showPasswordChecked}
                                        onChange={this.handleShowPasswordChange}
                                    />Mostrar as senhas
                            </div>
                            <div>
                                <SignupCheckbox
                                        checked={this.state.termsChecked}
                                        onChange={this.handleTermsChange}
                                    /> 
                                
                                <span>
                                    Li e aceito os
                                    <SignupLink href="" onClick={openDialogTerms}>
                                        termos de uso
                                    </SignupLink>
                                    <span> e</span>
                                    <SignupLink href="" onClick={openPrivacy}>
                                        politica de privacidade
                                    </SignupLink>
                                </span>
                            </div>

                            
                            <div className="signup-spacing"></div>
                            <div className="signup-reset-button">
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    autoFocus
                                    disabled={!this.state.name 
                                                || !this.state.lastName 
                                                || !this.state.birthdate || !this.state.termsChecked
                                                || !this.state.mobile || !this.state.email 
                                                || !this.state.cpf || !this.state.password 
                                                || this.state.repeatPassword !== this.state.password 
                                                || this.state.waitFor}
                                    disableRipple
                                    onClick={this.handleSubmit}>
                                    CADASTRE-SE
                                    {this.state.waitFor && <CircularProgress style={{
                                                                    color: '#FF9666',
                                                                    position: 'absolute',
                                                                    width: 24,
                                                                    height: 24
                                                                }}/>}
                                </Button>
                            </div>
                            <div className="signup-reset-button">
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    autoFocus
                                    disableRipple
                                    onClick={this.handleBackToLogin}>
                                    VOLTAR
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            
                        </Grid>
                </Grid>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleCloseSuccess}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="form-dialog-title">Estamos quase lá</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">                                
                                Um e-mail de confirmação de cadastro foi enviado para {this.state.email}. <br/>
                                Caso não encontre, verifique na caixa de spam.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleCloseSuccess} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    FECHAR
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openError}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-description-error"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-error">
                                    {t(this.getErrorNumber())}
                                </DialogContentText>
                            </DialogContent>
                                
                                
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    FECHAR
                                </Button>
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openTerms}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="form-dialog-title-terms"
                        aria-describedby="alert-dialog-description-terms"
                        >
                            <DialogTitle id="form-dialog-title-terms">TERMOS DE USO DO CRABBER</DialogTitle>                            
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-terms">
                                    <Typography variant="h5" gutterBottom>
                                        TERMOS DE USO DO CRABBER
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Última Atualização: 22 de merço de 2021
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <p>Bem-vindo (a)</p>
                                        <p>Obrigado por utilizar o Crabber!</p>
                                        <p>Esta aplicação e seu conteúdo ("Crabber") são controlados e operados pelo próprio Crabber. Todos os direitos reservados.</p>
                                        <p>Estes termos de uso têm por objeto definir as regras a serem seguidas para a utilização do Crabber ("Termos de Uso"), sem prejuízo da aplicação da legislação vigente.</p>
                                        <p>AO UTILIZAR O CRABBER, VOCÊ AUTOMATICAMENTE CONCORDA COM ESTES TERMOS DE USO, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS PRATICADOS POR VOCÊ NO CRABBER OU EM SERVIÇOS A ELE RELACIONADOS. CASO VOCÊ NÃO CONCORDE COM QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS, VOCÊ NÃO DEVE UTILIZAR O CRABBER. VOCÊ TAMBÉM CONCORDA COM OS TERMOS DESCRITOS EM NOSSA POLÍTICA DE PRIVACIDADE. PARA ACESSÁ-LA, <SignupLink href="http://www.crabber.com.br" >
                                        CLIQUE AQUI
                                        </SignupLink>
                                        <p>Caso queira nos dar algum feedback sobre o Crabber, tenha dúvidas ou precise tratar de qualquer assunto relacionado a estes Termos de Uso, entre em contato conosco através do e-mail suporte@crabber.com.br.</p></p>
                                        <p><b>1.	O QUE É O CRABBER?</b></p>    
                                        <p><b>1.1. Serviços.</b> O Crabber é uma plataforma que oferece os seguintes serviços: Segmentação de vídeos ao vivo, armazenamento de mídias, possibilidade de carregar dados sobre as mídias armazenadas e tratadas.</p>                        
                                        <p><b>1.2. Suspensão.</b> Nós nos reservamos o direito de suspender ou cancelar, a qualquer momento, o seu acesso à aplicação em caso de suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas nestes Termos de Uso, na Política de Privacidade ou na legislação aplicável. Nesses casos, não será devida qualquer indenização a você, e o Crabber poderá promover a competente ação de regresso, se necessário, bem como tomar quaisquer outras medidas necessárias para perseguir e resguardar seus Interesses.</p>
                                        <p><b>2.	COMO ACESSO O CRABBER?</b></p>
                                        <p><b>2.1. Acesso.</b> Para acessar o Crabber e utilizar suas funcionalidades é necessário efetuar um cadastro. Para cadastrar-se, você nos fornecerá informações pessoais, conforme descrito em nossa Política de Privacidade. Para saber mais sobre a privacidade de suas informações pessoais no Crabber, acesse nossa Política de Privacidade.</p>
                                        <p><b>2.2. Idade de Utilização.</b> Para utilizar o Crabber, você deve ter pelo menos 18 (dezoito) anos. Caso você tenha idade inferior, você poderá utilizar o Crabber desde que com a supervisão dos seus pais ou responsáveis legais e desde que eles tenham lido e concordado expressamente com estes Termos de Uso, através da seguinte solução técnica:  </p>
                                        <p><b>2.3. Titularidade.</b> A partir do cadastro, você será titular de uma conta que somente poderá ser acessada por você. Caso o Crabber detecte alguma conta feita a partir de Informações falsas, por usuários que, por exemplo, não possuem a idade mínima permitida, essa conta será automaticamente deletada.</p>
                                        <p><b>2.4. Atualização das informações.</b> Desde já, você se compromete a manter as suas Informações pessoais atualizadas. Você também concorda que irá manter o seu login e senha seguros e fora do alcance de terceiros, e não permitirá que a sua conta no Crabber seja usada por outras pessoas. Dessa forma, o usuário responsabiliza-se por todas as ações realizadas em sua conta.</p>
                                        <p><b>3.	A RELAÇÃO CONTRATUAL ENTRE O CRABBER E O USUÁRIO</b></p>
                                        <p><b>3.1. Relação Contratual.</b> Os serviços e o conteúdo oferecidos pela plataforma são propriedade do Crabber. Ao estabelecer o contrato que permite ao usuário o gozo das funcionalidades do sistema, o Crabber está oferecendo uma licença de uso, que é não-exclusiva, limitada, revogável e de uso pessoal. É da liberalidade do usuário subscrever a qualquer plano oferecido pelo Crabber, sujeito às regras descritas nesses Termos de Uso.</p>
                                        <p><b>3.2. Compra de Produtos.</b> Somado ao oferecimento da subscrição aos planos do Crabber, nossa plataforma permitirá a compra de produtos para o uso do usuário dentro da plataforma. A compra desses produtos estará sujeita às regras de licenciamento, dispostas na plataforma e/ou nesses Termos de Uso. Caso tais produtos envolvam a integração com plataformas de terceiros, o usuário também estará sujeito aos Termos de Uso, Política de Privacidade e Especificações de Segurança de tal terceiro.</p>
                                        <p><b>4.	ASSINATURA E CANCELAMENTO DE PLANOS</b></p>
                                        <p><b>4.1. Assinatura.</b> Nós do Crabber fornecemos a assinatura de planos de serviço da seguinte maneira: onerosa, como definido no capitulo abaixo.</p>
                                        <p><b>4.2. Modo de Subscrição.</b> A subscrição ao(s) plano(s) pago(s) requer o pagamento antecipado para a subscrição ao plano. Dessa forma, você estará pagando hoje pelo acesso durante o próximo mês corrente. Caso o dia em que o pagamento é efetuado não se repita todos os meses (por exemplo: 31), o pagamento será cobrado no dia útil anterior ao que eventualmente ocorreria caso essa data existisse no mês em questão (por exemplo: 28 ou 30). Haverá renovação automática do plano, a não ser que você se manifeste contrariamente requerendo o cancelamento, antes da data de faturamento.</p>
                                        <p><b>4.3. Cancelamento da Subscrição.</b> O cancelamento da subscrição ao plano pode ser realizado a qualquer tempo pelo usuário e será entendido como manifestação expressa de que não pretende renovar a licença no próximo período ainda não contabilizado para fins de cobrança. O cancelamento não enseja qualquer dever ao Crabber de realizar a devolução do pagamento do mês no qual o usuário optou por não renovar a relação contratual.</p>
                                        <p><b>4.4. Trânsito entre Planos.</b> Nós do Crabber sabemos que cada um dos planos é mais adequado para solucionar determinados problemas do usuário. Pensando nisso, oferecemos a possibilidade de você transitar entre os planos (sujeito às mudanças de disponibilidade de recursos oferecidas por cada um), editando ou fazendo upgrade/downgrade de sua subscrição. É importante ressaltar que o usuário que deseja cancelar ou sair das Versões Pagas perderá sua conta e o conteúdo gerado, bem como as atividades realizadas na plataforma após um período de 30 dias de inatividade ou inadimplência. </p>
                                        <p><b>4.5. In-App Purchases.</b> A aplicação possui funcionalidades que podem ser compradas dentro da plataforma, as chamadas "In-App Purchases". As eventuais compras desses serviços ocorrerão de maneira semelhante ao disposto acima.</p>
                                        <p><b>5. COMO SÃO FEITOS 05 PAGAMENTOS NO CRABBER,</b></p>
                                        <p><b>5.1. Meio de Pagamento.</b> Os pagamentos efetuados no Crabber deverão ser realizados dentro da aplicação, por meio de Cartão de crédito, boleto bancário ou transferência bancária entre contas, que serão realizadas diretamente com o Crabber ou plataforma de pagamentos parceira.</p>
                                        <p><b>5.2. Preço Final.</b> O preço pago por você é final e não reembolsável, a menos que diversamente determinado pelo Crabber. O Crabber reserva-se o direito de estabelecer, remover e/ou revisar o preço relativo a todos os serviços ou bens obtidos por meio do uso da aplicação a qualquer momento. Nunca alteraremos o valor da sua subscrição sem antes avisá-lo.</p>
                                        <p><b>5.3. Recolhimento de Impostos.</b> Se houver a incidência de qualquer imposto, o usuário será responsável por seu recolhimento. Em caso de eventual recolhimento por parte da aplicação, a mesma explicita que repassará ao usuário o preço.</p>
                                        <p><b>5.4. Confirmação.</b> A confirmação do pagamento por meio da aplicação ocorrerá em até 2 (dois) dias úteis. O processamento das informações de pagamento e a confirmação do pagamento serão realizados por sistemas de terceiros (ex. Instituição financeira ou administradora do cartão de crédito), sendo o aplicativo uma mera interface entre o cliente e esses sistemas.</p>
                                        <p><b>5.5. Prazo mínimo.</b> Certos produtos ou promoções do Crabber podem exigir um prazo mínimo de assinatura. É importante que o usuário esteja ciente de que, caso decida não utilizar mais tais produtos antes do prazo mínimo de assinatura, podem ser cobradas tarifas adicionais.</p>
                                        <p><b>5.6. Código Promocional.</b> Caso o Crabber crie algum código promocional (por exemplo, cupom de desconto), este deve ser usado de forma legal para a finalidade e o público ou usuário específico a que se destina, seguindo todas suas condições. O código promocional pode ser cancelado caso se verifique que foi transferido, vendido ou utilizado com erro, fraude, ilegalidade ou violação às condições do respectivo código.</p>
                                        <p><b>6.	QUAIS SÃO 05 DIREITOS DO CRABBER SOBRE A APLICAÇÃO?</b></p>
                                        <p><b>6.1. Nossos Direitos. </b> Todos os direitos relativos ao Crabber e suas funcionalidades são de propriedade exclusiva do Crabber, Inclusive no que diz respeito aos seus textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias e demais conteúdos produzidos direta ou indiretamente pelo Crabber ("Conteúdo do Crabber). O Conteúdo do Crabber é protegido pela lei de direitos autorais e de propriedade intelectual. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do Conteúdo do Crabber, para qualquer finalidade, sem o consentimento prévio e expresso do Crabber. Qualquer uso não autorizado do Conteúdo do Crabber será considerado como violação dos direitos autorais e de propriedade intelectual do Crabber.</p>
                                        <p><b>7.	PROPRIEDADE INTELECTUAL SOBRE O SOFTWARE E 05 MATERIAIS DISPONIBILIZADOS</b></p>
                                        <p><b>7.1. Propriedade Intelectual.</b> Para nós do Crabber, a qualidade dos materiais disponibilizados ao usuário é de suma Importância. A criação deles é fruto de muito trabalho e dedicação de nossos desenvolvedores. Por isso, reafirmamos que o Crabber garante que todos os direitos, título e interesse (incluindo, mas não apenas, os direitos autorais, marcados e outros de propriedade intelectual) sobre o serviço disponibilizado por nós permanecerão sob a titularidade do Crabber.</p>
                                        <p><b>7.2. Não-aquisição de direitos.</b> O usuário não adquirirá nenhum direito de propriedade sobre os serviços e conteúdo do Crabber, exceto quando haja outorga expressa neste Termos de Uso.</p>
                                        <p><b>7.3. Download de Conteúdo.</b> É proibido que o usuário faça o download de nosso conteúdo com o intuito de armazená-lo em banco de dados para oferecer para terceiro que não seja o próprio usuário. Veda-se, também, que o conteúdo disponibilizado por nós seja usado para criar uma base de dados ou um serviço que possa concorrer de qualquer maneira com o nosso negócio.</p>
                                        <p><b>8.	RECLAMAÇÕES SOBRE VIOLAÇÃO DE DIREITO AUTORAL</b></p>
                                        <p><b>8.1. Infringência de Direitos.</b> Alegações de infringência de direito autoral de qualquer conteúdo disponível no Crabber devem ser encaminhadas por meio do e-mail suporte@crabber.com.br.</p>
                                        <p><b>9. RESPONSABILIDADES DO USUÁRIO E DO CRABBER</b></p>
                                        <p><b>9.1. Responsabilidade pelo Uso.</b> Você é exclusivamente responsável pelo uso do Crabber e deverá respeitar as regras destes Termos de Uso, bem como a legislação aplicável ao Crabber.</p>
                                        <p><b>9.2. Responsabilização por Eventuais Danos.</b> O Crabber, seu controlador, suas afiliadas, parceiras ou funcionários não serão, em hipótese alguma, responsabilizados por danos diretos ou indiretos que resultem de, ou que tenham relação com o acesso, uso ou a incapacidade de acessar ou utilizar o Crabber.</p>
                                        <p><b>9.3. Não-Responsabilização.</b> TENDO EM VISTA AS CARACTERÍSTICAS INERENTES AO AMBIENTE DA INTERNET, O CRABBER NÃO SE RESPONSABILIZA POR INTERRUPÇÕES OU SUSPENSÕES DE CONEXÃO, TRANSMISSÕES DE COMPUTADOR INCOMPLETAS OU QUE FALHEM, BEM COMO POR FALHA TÉCNICA DE QUALQUER TIPO, INCLUINDO, MAS NÃO SE LIMITANDO, AO MAU FUNCIONAMENTO ELETRÔNICO DE QUALQUER REDE, HARDWARE OU SOFTWARE. A INDISPONIBILIDADE DE ACESSO A INTERNET OU AO CRABBER, ASSIM COMO QUALQUER INFORMAÇÃO INCORRETA OU INCOMPLETA SOBRE O CRABBER E QUALQUER FALHA HUMANA, TÉCNICA OU DE QUALQUER OUTRO TIPO NO PROCESSAMENTO DAS INFORMAÇÕES DO CRABBER NÃO SERÃO CONSIDERADAS RESPONSABILIDADE DO CRABBER. O CRABBER SE EXIME DE QUALQUER RESPONSABILIDADE PROVENIENTE DE TAIS FATOS E/OU ATOS.</p>
                                        <p><b>9.4. Perda de Lucros.</b> Quando permitido por lei, o Crabber e os fornecedores ou distribuidores não serão responsáveis por perda de lucros, perda de receita, perda de dados, perdas financeiras ou por danos indiretos, especiais, consequências, exemplares ou punitivos.</p>
                                        <p><b>9.5. Manutenção.</b> É de sua inteira responsabilidade manter o ambiente de seu dispositivo (computador, celular, tablet, entre outros) seguro, com o uso de ferramentas disponíveis, como antivírus, firewall, entre outras, de modo a contribuir na prevenção de riscos eletrônicos.</p>
                                        <p><b>9.6. Link Externo. </b> É possível que O Crabber possa conter links para sites e aplicativos de terceiros, assim como ter tecnologias integradas. Isso não implica, de maneira alguma, que o Crabber endossa, verifica, garante ou possui qualquer ligação com os proprietários desses seus ou aplicativos, não sendo responsável pelo seu conteúdo, precisão, políticas, práticas ou opiniões. O Crabber recomenda que você leia os termos de uso e políticas de privacidade de cada site de terceiros ou serviço que o usuário vier a visitar ou utilizar. </p>
                                        <p><b>10.	COMO O CRABBER LIDA COM O CONTEÚDO QUE VOCÊ E OUTROS USUÁRIOS PRODUZEM?</b></p>
                                        <p><b>10.1. Criação de Conteúdo.</b> O Crabber poderá, a seu exclusivo critério, permitir que você ou qualquer outro usuário apresente, carregue, publique ou disponibilize, na aplicação, conteúdo ou informações de texto, imagem, áudio ou vídeo ("Conteúdo de Usuário").</p>
                                        <p><b>10.2. Conteúdos Proibidos.</b> É proibido qualquer Conteúdo de Usuário de caráter difamatório, calunioso, injurioso, violento, pornográfico, obsceno, ofensivo ou ilícito, conforme apuração do Crabber a seu critério exclusivo, inclusive informações de propriedade exclusiva pertencentes a outras pessoas ou empresas (ex. direito de autor, marcas), sem a expressa autorização do titular desses direitos, cuja violação não será de responsabilidade do Crabber.</p>
                                        <p><b>10.3. Titularidade do Conteúdo.</b> Qualquer Conteúdo de Usuário fornecido por você permanece de sua propriedade. Contudo, ao fornecê-lo para o Crabber, você nos outorga uma licença em nível mundial, por prazo indeterminado, gratuita e transferível, e com direito a sublicenciar, usar, copiar, modificar, criar obras derivadas, distribuir, publicar, exibir esse Conteúdo de Usuário em todos os formatos e canais de distribuição possíveis, sem necessidade de novo aviso a você, e sem necessidade de qualquer pagamento, desde que isso esteja relacionado ao funcionamento da plataforma.</p>
                                        <p><b>10.4. Exclusão do Conteúdo.</b> O Crabber poderá, mas não se obriga a, analisar, monitorar e remover Conteúdo de Usuário, a critério exclusivo do Crabber, a qualquer momento e por qualquer motivo, sem nenhum aviso a você.</p>
                                        <p><b>11.	O QUE MAIS EU PRECISO SABER SOBRE ESTES TERMOS DE USO?</b></p>
                                        <p><b>11.1. Alterações.</b> Para melhorar sua experiência, O Crabber está sempre sendo atualizado. Por esse motivo, estes Termos de Uso podem ser alterados, a qualquer tempo, a fim de refletir os ajustes realizados. No entanto, sempre que ocorrer qualquer modificação, você será previamente informando pelo endereço de e-mail fornecido por você no momento do cadastro ou por um aviso em destaque na aplicação. Caso você não concorde com os novos Termos de Uso, você poderá rejeitá-los, mas, infelizmente, isso significa que você não poderá mais ter acesso e fazer uso do Crabber. Se de qualquer maneira você utilizar o Crabber mesmo após a alteração destes Termos de Uso, isso significa que você concorda com todas as modificações.</p>
                                        <p><b>11.2. Conflito entre Disposições.</b> Em caso de conflito entre estes termos e os termos modificadores, os termos posteriores prevalecerão com relação a esse conflito.</p>
                                        <p><b>11.3. Lei e Foro.</b> Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. Quaisquer dúvidas e situações não previstas nestes Termos de Uso serão primeiramente resolvidas pelo Crabber e, caso persistam, deverão ser solucionadas pelo Foro da Comarca de Florianópolis, Santa Catarina, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
                                        <p><b>11.4. Dúvidas.</b> Caso você tenha alguma dúvida, comentário ou sugestão, por favor, entre em contato conosco por meio do e-mail suporte@crabber.com.br.</p>
                                        <p><b>12. Google e YouTube.</b> Ao utilizar utilizar os serviços do YouTube para publicação de vídeos por meio do Crabber, você automaticamente aceita e concorda com as políticas destas ferramentas. Para mais informações acesse os&nbsp;<a href="https://www.youtube.com/t/terms" target="_blank" rel="noreferrer">Termos de Serviço do YouTube</a>.</p>
                                        <p>Caso você deseje revogar o acesso do Crabber à sua conta do YouTube pode se direcionar à página de&nbsp;<a href="https://security.google.com/settings/security/permissions" target="_blank" rel="noreferrer">permissões e segurança do Google</a>.</p>
                                        <p>Ao utilizar os serviços vinculados ao Google por meio do Crabber você automaticamente aceita e concorda com os&nbsp;<a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Termos e Privacidade</a>&nbsp;destas plataformas.&nbsp;</p>
                                        <p><b>Mais informações</b></p>
                                        <p>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.</p>
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                                
                                
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    FECHAR
                                </Button>
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openPrivacy}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="form-dialog-title-privacy"
                        aria-describedby="alert-dialog-description-privacy"
                        >
                            <DialogTitle id="form-dialog-title-privacy">POLÍTICA DE PRIVACIDADE DO CRABBER</DialogTitle>                            
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-privacy">
                                    <Typography variant="h5" gutterBottom>
                                        POLÍTICA DE PRIVACIDADE DO CRABBER
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Última Atualização: 22 de março de 2021
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <p><b>A sua privacidade é importante para nós. É política do Crabber respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site Crabber, e outros sites que possuímos e operamos.</b></p>
                                        <p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</p>
                                        <p>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p>
                                        <p>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
                                        <p>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas&nbsp;<a href="https://politicaprivacidade.com/" target="_blank" rel="noreferrer">políticas de privacidade</a>.</p>
                                        <p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>
                                        <p>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.</p>
                                        <p></p>
                                        <p><b>Política de Cookies Crabber</b></p>
                                        <br/>
                                        <p><b>O que são cookies?</b></p>
                                        <p>Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou &#8216;quebrar&#8217; certos elementos da funcionalidade do site.</p>
                                        <p></p>
                                        <p><b>Como usamos os cookies?</b></p>
                                        <p>Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados para fornecer um serviço que você usa.</p>
                                        <p></p>
                                        <p><b>Desativar cookies</b></p>
                                        <p>Você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você não desative os cookies.</p>
                                        <p></p>
                                        <p><b>Cookies que definimos</b></p>
                                        <ul>
                                        <li>Cookies relacionados à conta
                                        <p>Se você criar uma conta connosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.</p>
                                        </li>
                                        <li>Cookies relacionados ao login
                                        <p>Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.</p>
                                        </li>
                                        <li>Cookies relacionados a boletins por e-mail
                                        <p>Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos / não inscritos.</p>
                                        </li>
                                        <li>Pedidos processando cookies relacionados
                                        <p>Este site oferece facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.</p>
                                        </li>
                                        <li>Cookies relacionados a pesquisas
                                        <p>Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.</p>
                                        </li>
                                        <li>Cookies relacionados a formulários
                                        <p>Quando você envia dados por meio de um formulário como os encontrados nas páginas de contacto ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.</p>
                                        </li>
                                        <li>Cookies de preferências do site
                                        <p>Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.</p>
                                        </li>
                                        </ul>
                                        <br/>
                                        <br/>
                                        <p><b>Cookies de Terceiros</b></p>
                                        <p>Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.</p>
                                        <ul>
                                        <li>Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente.</li>
                                        </ul>
                                        <p>Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.</p>
                                        <ul>
                                        <li>As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que você passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você.</li>
                                        <li>Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.</li>
                                        <li>À medida que vendemos produtos, é importante entendermos as estatísticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso é importante para você, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analizar nossos custos de publicidade e produtos para garantir o melhor preço possível.</li>
                                        </ul>
                                        <br/>
                                        <p><b>Google e YouTube</b></p>
                                        <p>Ao utilizar utilizar os serviços do YouTube para publicação de vídeos por meio do Crabber, você automaticamente aceita e concorda com as políticas destas ferramentas. Para mais informações acesse os&nbsp;<a href="https://www.youtube.com/t/terms" target="_blank" rel="noreferrer">Termos de Serviço do YouTube</a>.</p>
                                        <p>Caso você deseje revogar o acesso do Crabber à sua conta do YouTube pode se direcionar à página de&nbsp;<a href="https://security.google.com/settings/security/permissions" target="_blank" rel="noreferrer">permissões e segurança do Google</a>.</p>
                                        <p>Ao utilizar os serviços vinculados ao Google por meio do Crabber você automaticamente aceita e concorda com os&nbsp;<a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Termos e Privacidade</a>&nbsp;destas plataformas.&nbsp;</p>
                                        <p><b>Mais informações</b></p>
                                        <p>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.</p>

                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                                
                                
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    FECHAR
                                </Button>
                            </DialogActions>
                    </Dialog>
                </div>
                
            </div>
            }
            </Translation>
        )
    }
}

function TextMaskCpf(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        
      />
    );
  }

function TextMaskBirthDate(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
        {...other}
        ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        
        />
    );
}

function TextMaskMobile(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
        {...other}
        ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        
        />
    );
}

function Signup(props) {
    

    return (
        
        <div className='signup-container'>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SignupContainer />
            </ThemeProvider>
        </div>
            
    )

}

export default Signup