import React from 'react';
import { Redirect } from "react-router-dom";
import Sidebar from './components/commons/Sidebar'
import Controller from './components/studio/Controller'
import Usage from './components/studio/Usage'
import Player from './components/studio/Player'
import Clips from './components/studio/Clips'
import Grid from '@material-ui/core/Grid';
import Utils from 'functions/Utils'
import { theme } from 'theme.js';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { FeedingSubscriberApi} from 'config/config'
import Dialog from '@material-ui/core/Dialog';
import {UserUsageApi} from 'config/config';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import axios from './interceptors';
import 'Studio.css'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function getReasonMessage(reason) {
    const prefix = 'Ops, sua gravação foi interrompida. ';
    const suffix = 'Clique em OK para voltar a tela inicial.';
    switch(reason) {
        case -1:
        case 0:
            return prefix + ' O link da live não está mais ativo. ' + suffix;
        case 2:
            return prefix + ' Possivelmente você ficou mais de 5 minutos fora da tela do Crabber ou link da live não está mais ativo. ' + suffix;
        case 3:
            return prefix + ' Você utilizou 100% do tempo de captura contratado.' + suffix;
        default:
            return prefix + ' O link da live não está mais ativo. ' + suffix;
    }
}

class StudioContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openItsTimeToRedirect: false,
            openIngestWarning: false,
            openClipLimit: false,
            redirect: null,
            killReason: -1,
            ingestWarningAlreadyShown: false,
            attempt: 0,
        };
        
        
        this.interval = null;
        this.onFeed = this.onFeed.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseIngestWarning = this.handleCloseIngestWarning.bind(this);
        this.itsTimeToRedirect = this.itsTimeToRedirect.bind(this);
        this.openDialogClipLimit = this.openDialogClipLimit.bind(this);
        this.handleCloseClipLimit = this.handleCloseClipLimit.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.onUnload = this.onUnload.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onClick = this.onClick.bind(this);
        
        window.studioComponent = this;
    }

    openDialogClipLimit() {
        this.setState({
            openClipLimit: true,
        });
    }

    handleCloseClipLimit() {
        this.setState({
            openClipLimit: false,
        });
    }

    handleClose() {
        this.setState({
            open: false,
            openItsTimeToRedirect: false,
            redirect: '/'
        });
    }

    handleCloseIngestWarning() {
        console.log('CLOSE');
        this.setState({
            openIngestWarning: false,
        });
    }

    onUnload = e => { // the method that will be used for both add and remove event
        var confirmationMessage = "";
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    }

    onVisibilityChange = e => {
        if ("hidden" === e.target.visibilityState) {
            Player.hls.media.pause();
            this.onFeed();
            clearInterval(this.interval);            
        } else {
            window.playerComponent.handleToggleLive();
            this.onFeed();
            clearInterval(this.interval);
            this.interval = setInterval(this.onFeed, 60000);            
        }
    }

    onKeyPress = e => {
        if (e.target.className.includes('MuiInputBase-input')) {
            return;
        }
        window.controllerComponent.state.crabberSlider?.querySelector('.MuiSlider-thumb')?.focus();
        switch (e.key) {
            case 'i':
            case 'I':
                if (!Player.live) {
                    window.controllerComponent.handleCurrentIn();
                }
                break;
            case 'o':
            case 'O':
                if (!Player.live) {
                    window.controllerComponent.handleCurrentOut();
                }
                break;                
            case ' ':
                window.playerControllerComponent.handleTogglePlay();
                break;
            case 'c':
            case 'C':                
                if (window.controllerComponent.state.waitFor || window.controllerComponent.state.playerLive || window.controllerComponent.state.currentIn < 0 || window.controllerComponent.state.currentOut < 0 || window.controllerComponent.state.currentIn >  window.controllerComponent.state.currentOut) {
                    break;
                }
                window.controllerComponent.handleSubmit();
                break;
            case 'm':
            case 'M':
                window.playerControllerComponent.handleToggleMute();
                break;
            case 'e':
            case 'E':
                window.playerControllerComponent.handleOpenDialog();
                break;
            case 'l':
            case 'L':
                if (!Player.live && Player.hls && Player.hls.liveSyncPosition) {
                    window.playerControllerComponent.handleToggleLive();
                }
                break;
            case '+':
                window.controllerComponent.handleZoomIn();
                break;
            case '-':
                window.controllerComponent.handleZoomOut();
                break;
            default:
                
                
        }        
    }

    onClick = e => {
        
        if (typeof e.target.className === 'object') {
            window.controllerComponent.state.crabberSlider?.querySelector('.MuiSlider-thumb')?.focus();
            return;
        }
        if (typeof e.target.className === 'string' && e.target.className.includes('MuiInputBase-input')) {
            return;
        }
        window.controllerComponent.state.crabberSlider?.querySelector('.MuiSlider-thumb')?.focus();
    }
    
    itsTimeToRedirect() {
        let id =localStorage.getItem('@crabber/username');
        axios({
            url: UserUsageApi.uri + '/' + id + `/sessions`, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                if (!response.data || response.data.length === 0) {
                    this.setState({
                        //openItsTimeToRedirect: Player.hls && Player.hls.liveSyncPosition,
                        openItsTimeToRedirect: true,
                    })
                }
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    componentDidMount() {
        document.addEventListener("visibilitychange", this.onVisibilityChange);
        document.addEventListener("keypress", this.onKeyPress);
        document.addEventListener("click", this.onClick);
        this.onFeed();
        this.interval = setInterval(this.onFeed, 60000);
        setTimeout(this.itsTimeToRedirect, 2000);
        window.controllerComponent.state.crabberSlider?.querySelector('.MuiSlider-thumb')?.focus();
        
        
    }

    componentDidUpdate() { 

    }

    componentWillUnmount() {
        //window.removeEventListener("beforeunload", this.onUnload);
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
        document.removeEventListener("keypress", this.onKeyPress);
        document.removeEventListener("click", this.onClick);
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    onFeed() {
        let subscriber = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber'));
        let id = subscriber.id;

        axios.get(FeedingSubscriberApi.uri + id)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    if (!data.is_running) {
                        console.log(localStorage.getItem('@crabber/Player.liveStatus'))
                        if (localStorage.getItem('@crabber/Player.liveStatus') === 'Carregando sinal') {
                            let attempt = this.state.attempt + 1;
                            this.setState({
                                attempt: attempt
                            });
                        }
                        console.log((Player.hls && Player.hls.liveSyncPosition), this.state.attempt)
                        if ((Player.hls && Player.hls.liveSyncPosition) || this.state.attempt > 1) {
                            this.setState({
                                open: true,
                                killReason: data.kill_reason,
                            });
                            clearInterval(this.interval);
                        }
                    } else {
                        this.setState({
                            attempt: 0
                        });
                        let begin = new Date(data.session_begin);
                        let now = new Date();
                        let diff = (now.getTime() - begin.getTime()) / 1000;
                        let minutes = Math.floor(diff / 60);
                        localStorage.setItem('@crabber/Ingest.minutes', minutes);                             
                        let message = minutes > 0 ? 'Gravando sessão há cerca de ' + Utils.humanMinutes(minutes) : 'Gravando sessão';
                        localStorage.setItem('@crabber/Player.liveStatus', message);                   
                        if (!this.state.ingestWarningAlreadyShown && window.usageIngestContainerComponent.state.ingest.limit > 0 && window.usageIngestContainerComponent.state.ingest.current_amount > window.usageIngestContainerComponent.state.ingest.limit) {                            
                            let openIngestWarning = !this.state.ingestWarningAlreadyShown;
                            this.setState({
                                openIngestWarning: openIngestWarning,
                                ingestWarningAlreadyShown: true
                            });
                        }
                    }
                }
            })
            .catch(error => {
                console.error(error);
            });
    }



    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div>
                <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="center" className='studio-grid-container' >
                    <Grid item xs={12}>
                        <Usage />
                    </Grid>
                    <Grid item xs={7}>
                        <Player />
                    </Grid>
                    <Grid item xs={4} >
                        <Clips />
                    </Grid>
                    <Grid item xs={1} >

                    </Grid>
                    <Grid item xs={12}>
                        <Controller />
                    </Grid>
                </Grid>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            {getReasonMessage(this.state.killReason)}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openItsTimeToRedirect}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-redirect"
                        aria-describedby="alert-dialog-description-redirect">
                        <DialogTitle id="alert-dialog-title-redirect">Atenção</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-redirect">
                            Não tem nenhuma gravação disponivel. Você será redirecionado para tela inicial.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openIngestWarning}
                        onClose={this.handleCloseIngestWarning}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-warn"
                        aria-describedby="alert-dialog-description-warn">
                        <DialogTitle id="alert-dialog-title-warn">Atenção</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-warn">
                            Ops, você utilizou 100% do tempo de captura contratado, mas vou liberar mais {5 - Math.floor(window.usageIngestContainerComponent?.state?.ingest?.excedeed/60)} minutos (aproximadamente) para você concluir o seu trabalho. 
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseIngestWarning} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openClipLimit}
                        onClose={this.handleCloseClipLimit}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-clip"
                        aria-describedby="alert-dialog-description-clip">
                        <DialogTitle id="alert-dialog-title-clip">Atenção</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-clip">
                            Ops, você está tentando recortar um clip com duração maior de 60 minutos 
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseClipLimit} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>

        )
    }
}

function Studio(props) {
    if (!JSON.parse(localStorage.getItem('@crabber/Ingest.ingestData'))) {
        return <Redirect to="/" />
    }

    return (
        <div className='studio-container'>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Sidebar />
                <StudioContainer />
            </ThemeProvider>
        </div>
    )

}

export default Studio