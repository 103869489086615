import React from 'react';
import AvatarCrabber from 'static/images/icons/CRAB-01.png';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { UserSpacesApi, VideosApi, UsersApi, YouTubeApi, FacebookApi } from 'config/config'
import axios from 'interceptors';

import './VideoLibrary.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CrabberMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ));
  
  const CrabberMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

const CrabberCard = withStyles({
    root: {
        width: 300,
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#00000030',
      }
  })(Card);

const CrabberCardHeader = withStyles({
    content: {
        flex: '1 1 auto',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        overflowWrap: 'break-word',
      }
  })(CardHeader);

const CrabberCardMedia = withStyles({
    root: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        position: 'relative',
        cursor: 'pointer',
      }
  })(CardMedia);

function LongMenu(props) {
    const video = props.video;

    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
        window.VideoLibraryContainerComponent.setState({selectedVideo: video});
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = (e) => {
        setAnchorEl(null);
    };
  
    const menuDeleteVideo = (e) => {
        setAnchorEl(null);
        window.VideoLibraryContainerComponent.handleOpenDeleteVideoDialog();
    };

    const menuEditVideo = (e) => {
        setAnchorEl(null);
        window.videoDetailsComponent.notifyChangeSelectedVideo(video)
    }

    return (
      <div>
        <IconButton
            id={video.id}
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={e => handleClick(e)}>
          <MoreVertIcon />
        </IconButton>
        <CrabberMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={e => handleClose(e)}>
                <CrabberMenuItem onClick={e => menuEditVideo(e)}>
                    <ListItemIcon>
                        <PlayCircleOutlineRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Detalhes" />
                </CrabberMenuItem>
                <CrabberMenuItem onClick={e => menuDeleteVideo(e)}>
                    <ListItemIcon>
                        <DeleteForeverRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Excluir" />
                </CrabberMenuItem>
            </CrabberMenu>
      </div>
    );
  }  


function GetVideoCard(props) { 
    const video = props.video;
    return <div>
                <CrabberCard>
                    <CrabberCardHeader
                        title={<Typography gutterBottom variant="h6" component="h4">
                                {video.title ? video.title : 'Sem título'}
                            </Typography>}
                        action={
                            <LongMenu video={video} />
                          }
                    />
                    <div className='video-item-metadata'>
                        <Tooltip title="Dia do clipe" arrow placement="left">
                            <Typography variant="subtitle1" gutterBottom display='block'>
                                <TodayRoundedIcon/>
                                {video.metadata.start_date}
                            </Typography>
                        </Tooltip>
                        <Tooltip title="Intervalo do clipe" arrow placement="left">
                            <Typography variant="subtitle1" gutterBottom display='block'>
                                <ScheduleRoundedIcon/>
                                de {video.metadata.start_time} até {video.metadata.end_time}
                            </Typography>
                        </Tooltip>
                    </div>
                    <CrabberCardMedia 
                            image={video.video_upload.thumbnail ? video.video_upload.thumbnail : AvatarCrabber}
                            title={video.title ? video.title : 'Sem título'}
                            onClick={() => window.videoDetailsComponent.notifyChangeSelectedVideo(video)}>                            
                        <div className='video-item-thumb-duration'>{video.metadata.duration}</div>
                        {video.video_upload.status !== 'COMPLETE' && <div className='video-item-thumb-status'>{
                            video.video_upload.status === 'UPLOADING' ? 'Carregando' : 
                                video.video_upload.status === 'TRANSCODING' ? 'Otimizando' : 
                                    video.video_upload.status === 'CREATED' ? 'Iniciando' : 'Houve um erro'}</div>}
                    </CrabberCardMedia>
                    <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                            Live publicada por {video.origin.uploader}
                        </Typography>
                        <Grid container direction="row" alignItems="flex-start">
                            <Grid item xs={6}>
                                <div className='video-item-origin-thumbnail'>
                                    <img src={video.origin.thumbnail ? video.origin.thumbnail : AvatarCrabber}  alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" gutterBottom display='block'>
                                    {video.origin.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CrabberCard>   
            </div>;
}

function GetVideos(props) { 
    const videos = props.videos;

    if (!videos.data) {
        return <div></div>
    }

    const data = videos.data.map((video) => (
        <Grid key={video.id} item xs={12} md={6} lg={4}>
            <GetVideoCard video={video}/>
        </Grid>
    ));
    return <div>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="stretch"
                    spacing={1}>
                    {data}
                </Grid>            
            </div>; 
}


class VideoLibraryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videosPaged: {},
            page_size: 12,
            page: 0,
            query: "",
            sort: 'createdDate,desc',
            intervalId: null,
            waitFor: true,
            selectedVideo: {},
            openDeleteVideo: false,
            openDeleteVideoSuccess: false,
            openDeleteVideoError: false,
            accounts: {}
        };
        
        this.handleChangePage = this.handleChangePage.bind(this);
        this.timer = this.timer.bind(this);
        this.notifyVideoDeleted = this.notifyVideoDeleted.bind(this);
        this.notifyVideoEdited = this.notifyVideoEdited.bind(this);
        this.notifyQueryChanged = this.notifyQueryChanged.bind(this);
        this.handleCloseDeleteVideoDialog = this.handleCloseDeleteVideoDialog.bind(this);
        this.handleOpenDeleteVideoDialog = this.handleOpenDeleteVideoDialog.bind(this);
        this.handleCloseDeleteVideoSuccess = this.handleCloseDeleteVideoSuccess.bind(this);
        this.handleCloseDeleteVideoError = this.handleCloseDeleteVideoError.bind(this);
        this.handleDeleteVideo = this.handleDeleteVideo.bind(this);
        
        window.VideoLibraryContainerComponent = this;
    }

    notifyStartSearch() {
        this.setState({
            waitFor: true,
        });
    }

    notifyQueryChanged(newQuery, newVideosPaged) {
        this.setState({
            query: newQuery,
            videosPaged: newVideosPaged,
            waitFor: false,
        });
    }

    notifyVideoEdited() {
        let id =localStorage.getItem('@crabber/username');
        
        axios({
            url: UserSpacesApi.uri + '/' + id + `/videos`, 
            method: 'GET',
            params: {
                'size': this.state.page_size,
                'page': this.state.page,
                'sort': this.state.sort,
                'q': this.state.query,
            }
        }).then(response => {
            if (response.status === 200) {
                this.setState({
                    videosPaged: response.data,
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    notifyVideoDeleted() {
        let id =localStorage.getItem('@crabber/username');
        
        axios({
            url: UserSpacesApi.uri + '/' + id + `/videos`, 
            method: 'GET',
            params: {
                'size': this.state.page_size,
                'page': this.state.page,
                'sort': this.state.sort,
                'q': this.state.query,
            }
        }).then(response => {
            if (response.status === 200) {
                this.setState({
                    videosPaged: response.data,
                });
                if (response.data.data.length === 0) {
                    axios({
                        url: UserSpacesApi.uri + '/' + id + `/videos`, 
                        method: 'GET',
                        params: {
                            'size': this.state.page_size,
                            'page': Math.max(0, this.state.page - 1),
                            'sort': this.state.sort
                        }
                    }).then(response => {
                        if (response.status === 200) {
                            this.setState({
                                videosPaged: response.data,
                            });
                            
                            window.videoDetailsComponent.notifyChangeSelectedVideo(response.data.data[0]);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });


                } else {
                    window.videoDetailsComponent.notifyChangeSelectedVideo(response.data.data[0]);
                }
            }
        })
        .catch(error => {
            console.error(error);
        });
    } 

    handleCloseDeleteVideoSuccess() {
        this.notifyVideoDeleted();
        this.setState({
            openDeleteVideoSuccess: false,
        })
    }

    handleCloseDeleteVideoError() {
        this.setState({
            openDeleteVideoError: false,
        })
    }

    handleOpenDeleteVideoDialog(e){
        this.setState({
            openDeleteVideo: true,
        })
    }

    handleCloseDeleteVideoDialog(){
        this.setState({
            openDeleteVideo: false,
        })
    }

    handleDeleteVideo(e){
        let id = this.state.selectedVideo.id;
        axios({
            url: VideosApi.uri + '/' + id, 
            method: 'DELETE',
        }).then(response => {
            if (response.status === 204) {
                this.setState({
                    openDeleteVideo: false,
                    openDeleteVideoSuccess: true,
                })
                
            }
        })
        .catch(error => {
            // TODO gerenciar erro com dialog
            console.error(error);
            this.setState({
                openDeleteVideo: false,
            })
        });
    }
    
    handleChangePage(event, value) {
        
        this.setState({
            page: value - 1,
        });

        let id =localStorage.getItem('@crabber/username');

        axios({
            url: UserSpacesApi.uri + '/' + id + `/videos`, 
            method: 'GET',
            params: {
                'size': this.state.page_size,
                'page': value - 1,
                'sort': this.state.sort,
                'q': this.state.query,
            }
        }).then(response => {
            if (response.status === 200) {
                this.setState({
                    videosPaged: response.data,
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    componentDidMount() {
        var intervalId = setInterval(this.timer, 20000);
        this.setState({
            intervalId: intervalId,
            waitFor: true,
        });

        let id =localStorage.getItem('@crabber/username');
        
        axios({
            url: UserSpacesApi.uri + '/' + id + `/videos`, 
            method: 'GET',
            params: {
                'size': this.state.page_size,
                'page': this.state.page,
                'sort': this.state.sort,
                'q': this.state.query,
            }
        }).then(response => {
            if (response.status === 200) {
                this.setState({
                    waitFor: false,
                    videosPaged: response.data,
                });
                
                window.videoDetailsComponent.notifyChangeSelectedVideo(response.data.data[0]);
            }
        })
        .catch(error => {
            console.error(error);
        });
        
        let email = localStorage.getItem('@crabber/username');
        let url = UsersApi.uri + '/' + email + '/accounts';
        const requestOptions = {
            url: url,
            method: 'GET',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        youTube: data.youtube,
                        facebook: data.facebook,
                        instagram: data.instagram,
                    });

                    localStorage.setItem('@crabber/accounts',JSON.stringify(data));

                    let email = localStorage.getItem('@crabber/username');
                    
                    if (data.youtube.length > 0) {
                        let youTubeKey = data.youtube[0].key;
                        let url = YouTubeApi.uri + '/' + email + '/youtube/' + youTubeKey;
                        
                        const requestOptions = {
                            url: url,
                            method: 'GET',
                        };
                        axios(requestOptions)
                            .then(async response => {
                                const youTubeData = response.data;
                                if (response.status === 200) {
                                    data.youtube[0].youTubeChannels = youTubeData.option.channels;
                                    data.youtube[0].youTubePrivacies = youTubeData.option.privacies;
                                    data.youtube[0].youTubeSelectedChannel = youTubeData.option.channels.find(function( item ) {
                                        return youTubeData.selected.channel_id === item.id;
                                    });
                                    data.youtube[0].youTubeSelectedPrivacy= youTubeData.option.privacies.find(function( item ) {
                                        return youTubeData.selected.privacy_id === item.id;
                                    });
                                    localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                    this.setState({
                                        accounts: data,
                                    });
                                        
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                
                            });
                    }
                    
                    if (data.facebook.length > 0) {
                        let facebookKey = this.state.facebook[0].key;
                        
                        let urlFacebook = FacebookApi.uri + '/' + email + '/facebook/' + facebookKey;
                
                        const requestOptionsFacebook = {
                            url: urlFacebook,
                            method: 'GET',
                        };
                        axios(requestOptionsFacebook)
                            .then(async responseFacebook => {
                                const facebookData = responseFacebook.data;
                                if (responseFacebook.status === 200) {
                                    
                                    data.facebook[0].facebookGroups = facebookData.option.groups;
                                    data.facebook[0].facebookPages = facebookData.option.pages;
                                    data.facebook[0].facebookSelectedGroup = facebookData.option.groups.find(function( item ) {
                                        return facebookData.selected.group_id === item.id;
                                    });
                                    data.facebook[0].facebookSelectedPage = facebookData.option.pages.find(function( item ) {
                                        return facebookData.selected.page_id === item.id;
                                    });
                                    localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                    this.setState({
                                        accounts: data,
                                    });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                
                            });
                        }
                    if (data.instagram.length > 0) {
                        let instagramKey = this.state.instagram[0].key;
                        let urlInstagram = FacebookApi.uri + '/' + email + '/instagram/' + instagramKey;
                
                        const requestOptionsInstagram = {
                            url: urlInstagram,
                            method: 'GET',
                        };
                        axios(requestOptionsInstagram)
                            .then(async responseInstagram => {
                                const instagramData = responseInstagram.data;
                                if (responseInstagram.status === 200) {
                                    data.instagram[0].instagramPages = instagramData.option.pages;
                                    data.instagram[0].instagramSelectedPage = instagramData.option.pages.find(function( item ) {
                                        return instagramData.selected.page_id === item.id;
                                    });
                                    data.instagram[0].instagramCaption = instagramData.selected.caption;
                                    localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                    this.setState({
                                        accounts: data,
                                    });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                
                            });
                    }

                }
                
            })
            .catch(error => {
                console.error(error);
                
            });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.setState({
            intervalId: null,
        });
    }

    timer() {
        if (!this.state.videosPaged && this.state.videosPaged.total_elements === 0) {
            return;
        }

        let videosPaged = this.state.videosPaged;
        
        if (!Array.isArray(videosPaged.data)) {
            return;
        }
        let videos = videosPaged.data.slice();

        videos.forEach((video, index) => {
            if (video.video_upload.status) {
                if (video.video_upload.status !== 'COMPLETE' || 
                    (video.video_upload.status === 'COMPLETE' && video.video_upload.thumbnail?.includes("default_"))) {
                    axios({
                        url: VideosApi.uri + '/' + video.id, 
                        method: 'GET'
                    }).then(response => {
                        if (response.status === 200) {
                            videosPaged.data[index].video_upload = response.data.video_upload;
                            this.setState({
                                videosPaged: videosPaged,
                            })
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            }
        });

    }


    render() {
        return (
            <div style={{
                    width: '100%',
                    height: '100%',
                }}>
                {(this.state.waitFor) ? (<div style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            position: 'relative',
                                                        }}>
                    <CircularProgress style={{
                                                            color: 'white',
                                                            position: 'absolute',
                                                            width: 120,
                                                            height: 120,
                                                            left: '50%',
                                                            marginLeft: '-60px',
                                                            top: '50%',
                                                            marginTop: '-60px',
                                                        }}/>
                </div>
                ) : (
                <div>
                    <GetVideos videos={this.state.videosPaged}/>
                    <br />
                
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center">
                           <Grid item>
                            { (this.state.videosPaged && this.state.videosPaged.total_elements > 0) ? (
                                <Pagination 
                                    count={this.state.videosPaged.pages} 
                                    variant="outlined" 
                                    shape="rounded"
                                    onChange={this.handleChangePage}/>
                            ) : (
                                <div>
                                    {(this.state.query.lengt === 0) ? (<Typography variant="h3" gutterBottom display='block'>Ainda não existem vídeos acervados</Typography>) :
                                    (<Typography variant="h3" gutterBottom display='block'>Nenhum resultado encontrado</Typography>)}
                                </div>
                            )} 
                            </Grid>
                    </Grid>
                
                        
                    <br />
                </div>)}
                <div>
                    <Dialog
                        open={this.state.openDeleteVideo}
                        onClose={this.handleCloseDeleteVideoDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-delete"
                        aria-describedby="alert-dialog-description-delete">
                        <DialogTitle id="alert-dialog-title-delete">{"Excluir clipe"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-delete">
                                {"Tem certeza que deseja excluir permanentemente este clipe?"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDeleteVideoDialog} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                NÃO
                            </Button>
                            <Button onClick={this.handleDeleteVideo} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                SIM
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openDeleteVideoSuccess}
                        onClose={this.handleCloseDeleteVideoSuccess}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-delete-success"
                        aria-describedby="alert-dialog-description-delete-success">
                        <DialogTitle id="alert-dialog-title-delete-success">{"Excluir clipe"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-delete-success">
                                {"Video excluido com sucesso!"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDeleteVideoSuccess} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openDeleteVideoError}
                        onClose={this.handleCloseDeleteVideoError}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-delete-error"
                        aria-describedby="alert-dialog-description-delete-error">
                        <DialogTitle id="alert-dialog-title-delete-error">{"Erro"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-delete-error">
                                {"Algum erro ocorreu durante exclusão do clipe. Por favor tentar novamente."}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDeleteVideoError} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}


function VideoLibrary(props) {
    return (
        <div>
            <div className='video-library-container'>
                <VideoLibraryContainer />
            </div>
        </div>
    )
}

export default VideoLibrary