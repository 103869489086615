import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const MarkerCrabberSlider = withStyles((theme) => ({
    root: {
        position: "absolute"
    },
    thumb: {
        height: 85,
        width: 4,
        backgroundColor: "#FF9666",
        border: "1px solid currentColor",
        borderRadius: 3,
        marginLeft: -2,
        marginTop: 15,
        zIndex: 9,
        "&:hover, &$active": {
          boxShadow: "#ffffff50 0 0px 0px 0px",
          backgroundColor: "#FF966699",
        },
        "& .avatar": {
          width: theme.spacing(4),
          height: theme.spacing(4),
        }
      },
      active: {},
    valueLabel: {
      marginTop: 20,
      color: "#fff"
    },
 
    track: {
      height: 80,
      marginTop: 20,
      backgroundColor: "#FF966610"
    },

    rail: {
      color: "#00000010",
      opacity: 1,
      height: 80,
      marginTop: 20,
      borderRadius: 5,
    },
    mark: {
      color: "#FFFFFF",
      height: 5,
      marginTop: 15,
      width: 1
    },
    markActive: {
      color: "#FF9666",
      height: 5,
      marginTop: 15,
      width: 1,
      backgroundColor: "#fff",
    },
    markLabel: {
      marginTop: -20,
      color: "#FFFFFF"
    },
    markLabelActive: {
      marginTop: -20,
      color: "#FF9666"
    }
}))(Slider);

export default MarkerCrabberSlider;