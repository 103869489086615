import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "#000000": "Ops, something was wrong!",
      "#100001": "No query param within uri address.",
      "#100002": "No param v within uri address query string.",
      "#100003": "Just a single path segment is allowed for the uri address.",
      "#100004": "No info was returned by youtube-dl info command.",
      "#100005": "The youtube address is not a live streaming.",
      "#100006": "The youtube address has not valid content.",
      "#100007": "The uri addres should be contains either www.youtube.com or youtu.be",
      "#120300": "Ops, something was wrong during check the CPF.",
      "#120400": "Already exists some user with this CPF.",
      "#120401": "Already exists some user with this email.",
      "#120402": "CPF not found.",
      "#120403": "Invalid CPF.",
      "#120404": "Invalid birthdate CPF pair.",
      "#120405": "Error during confirmation process.",
      "#120406": "The link is expired, please signup again.",
      "#120407": "No user was found with this CPF.",
      "#120408": "User is not enabled yet.",
      "#120409": "No user was found with this email.",
      "#120410": "Invalid birthdate.",
      "#120411": "Invalid mobile number.",
      "#120412": "Invalid email.",
      "#120413": "Just use letters, numbers and special characters.",
      "#130501": "Ivalid Pin.",
      "#100601": "The uri address is not starts with a valid RTMP protocol",
      "#100602": "The uri address ends with /",
      "#100603": "The uri address is not accessible, Check if is running or have permissions to",
      "#100604": "The uri address have not H.264 video codec",
      "#100605": "The uri address have not valid resolution",
      "#100606": "The uri address have not valid aspect ratio",
      "#100607": "The uri address have not video track",
      "#100608": "The uri address have not AAC audio codec",
      "#100609": "The uri address have not audio track",
    }
  },
  pt: {
    translation: {
      "#000000": "Ops, algo deu errado!",
      "#100001": "Esse endereço pode estar incompleto. Verifique novamente.",
      "#100002": "Esse endereço pode estar incompleto. Verifique novamente.",
      "#100003": "Esse endereço pode ser invalido. Verifique novamente.",
      "#100004": "Serviço indisponivel. Favor tentar novamente.",
      "#100005": "Esse endereço não é uma live.",
      "#100006": "Serviço indisponivel. Favor tentar novamente.",
      "#100007": "Esse endereço deve conter www.youtube.com ou youtu.be",
      "#120300": "Algum erro ocorreu durante a verificação do CPF. Favor tentar novamente.",
      "#120400": "CPF já cadastrado.",
      "#120401": "Email já cadastrado.",
      "#120402": "CPF inválido.",
      "#120403": "CPF inválido.",
      "#120404": "Data de nascimento inconsistente com o CPF informado.",
      "#120405": "Link inválido. Favor cadastrar-se novamente.",
      "#120406": "Link expirado. Favor cadastrar-se novamente.",
      "#120407": "CPF não encontrado.",
      "#120408": "Favor verificar o seu email para concluir o cadastro.",
      "#120409": "Email não encontrado.",
      "#120410": "Data de nascimento invalida.",
      "#120411": "Numero de celular invalido.",
      "#120412": "Email invalido.",
      "#120413": "Ops, sua senha deve possuir no mínimo 8 caracteres e conter letras e números.",
      "#130501": "PIN inválido. Favor tentar novamente.",
      "#100601": "Ops, O endereço do servidor deve começar por rtmp ou rtmps.",
      "#100602": "Ops, esse endereço pode ser invalido. Verifique novamente.",
      "#100603": "Ops, parece que o Crabber teve um problema para se conectar com essa transmissão. O acesso pode não estar permitido ou está fora do ar.",
      "#100604": "Ops, esse endereço não é uma transmissão valida. Confira as especificações de vídeo e áudio.",
      "#100605": "Ops, esse endereço não é uma transmissão valida. Confira as especificações de vídeo e áudio.",
      "#100606": "Ops, esse endereço não é uma transmissão valida. Confira as especificações de vídeo e áudio.",
      "#100607": "Ops, esse endereço não é uma transmissão valida. Confira as especificações de vídeo e áudio.",
      "#100608": "Ops, esse endereço não é uma transmissão valida. Confira as especificações de vídeo e áudio.",
      "#100609": "Ops, esse endereço não é uma transmissão valida. Confira as especificações de vídeo e áudio.",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pt",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;