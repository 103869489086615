import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const CurrentPositionCrabberSlider = withStyles((theme) => ({
    root: {
        position: "absolute"
    },
    thumb: {
      height: 85,
      width: 4,
      backgroundColor: "#FFFFFF",
      border: "0px solid currentColor",
      borderRadius: 3,
      marginLeft: -2,
      marginTop: 15,
      zIndex: 99999,
      "&:hover, &$active": {
        boxShadow: "#ffffff50 0px 0px 0px 0px",
        backgroundColor: "#FFFFFF99",
      },
      "& .avatar": {
        marginLeft: 2,
        width: theme.spacing(5),
        height: theme.spacing(5),
      }
    },
    active: {},

    track: {
      height: 80,
      marginTop: 20,
      backgroundColor: "#00000000",
      zIndex: 99999,
    },

    rail: {
      color: "#00000000",
      opacity: 1,
      height: 80,
      marginTop: 20,
      borderRadius: 5,
      zIndex: 99999,
    },

}))(Slider);

export default CurrentPositionCrabberSlider;