import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {UserSpacesApi} from 'config/config';
import UsageStorage from 'components/commons/UsageStorage';
import UsageIngest from 'components/commons/UsageIngest';
import axios from 'interceptors';
import './UsageLibrary.css'


class UsageLibraryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videosPaged: {},
            page_size: 12,
            page: 0,
            sort: 'createdDate,desc',
            query: "",
            intervalId: null,
            version: 0
        };
        this.handleChangeQuery = this.handleChangeQuery.bind(this);
        this.handleClearQuery = this.handleClearQuery.bind(this);
        this.timer = this.timer.bind(this);
        
    }

    componentDidMount() {
        var intervalId = setInterval(this.timer, 2000);
        this.setState({
            query: "",
            version: 0,
            intervalId: intervalId,
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.setState({
            intervalId: null,
        });
    }

    handleChangeQuery(event) {
        let version = this.state.version + 1;
        this.setState({
            query: event.target.value,
            version: version,
        });
    }

    handleClearQuery(event) {
        let version = this.state.version + 1;
        this.setState({
            query: "",
            version: version,
        });
    }

    timer() {   
        if (this.state.version === 0) {
            return;
        }
        

        let query = this.state.query.slice();
        this.setState({
            version: 0,
        });
        window.VideoLibraryContainerComponent.notifyStartSearch();
        let id =localStorage.getItem('@crabber/username');
        
        axios({
            url: UserSpacesApi.uri + '/' + id + `/full_text_videos`, 
            method: 'GET',
            params: {
                'size': this.state.page_size,
                'page': this.state.page,
                'sort': this.state.sort,
                'q': query
            }
        }).then(response => {
            if (response.status === 200) {
                window.VideoLibraryContainerComponent.notifyQueryChanged(query, response.data);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    render() {
        
        return (
            <div>
                <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="stretch"
                        spacing={1}>
                        <Grid 
                            item 
                            xs={7}
                            style= {{
                                heigt: "100%",
                                marginTop: "auto",
                                marginBottom: "auto",
                            }}>
                            <TextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                fullWidth
                                id="input-with-icon-textfield"
                                placeholder="Pesquisar"
                                value={this.state.query}
                                onChange={this.handleChangeQuery}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchRoundedIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {this.state.query.length > 0 && <CloseRoundedIcon onClick={this.handleClearQuery} style={{ cursor: 'pointer',}}/>}
                                    </InputAdornment>
                                )
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={2}>
                            <UsageIngest />
                        </Grid>
                        <Grid item xs={2}>
                            <UsageStorage />
                        </Grid>
                        
                </Grid>
            </div>
        )
    }
}


function UsageLibrary(props) {
    return (
        <div>
            <div className='usage-library-container'>
                <UsageLibraryContainer />
            </div>
        </div>
    )
}

export default UsageLibrary