import React from 'react';
import Grid from '@material-ui/core/Grid';
import AvatarCrabber from 'static/images/icons/CRAB-01.png'
import AvatarButtonCrabber from './AvatarButtonCrabber'
import downloadThumbIcon from 'static/images/icons/icones_crabber-05.png';
import LogoYouTube from 'static/images/icons/youtube.png'
import socialIcon from 'static/images/icons/social.png';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import BackupRoundedIcon from '@material-ui/icons/BackupRounded';
import CloudDoneRoundedIcon from '@material-ui/icons/CloudDoneRounded';
import Switch from '@material-ui/core/Switch';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Utils from 'functions/Utils'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { VideosApi } from 'config/config'
import axios from 'interceptors';
import './VideoDetails.css'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ComboBoxAvatar = withStyles((theme) => ({
    root: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: 10
    },
    
}))(Avatar);

const PublishConfirmationCheckbox = withStyles((theme) => ({
    root: {
        '&:hover': {
          backgroundColor: '#70707020',
        },
        color: '#ffffff23',
    },
    colorSecondary: {
        '&:hover': {
            backgroundColor: '#70707020',
        },
        '&$checked': {
            color: '#ffffff',
        },
        
    },
    checked: {},
}))(Checkbox);

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#FF9666',
    },
  }))(LinearProgress);

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <BorderLinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

const CrabberSwitch = withStyles({
    switchBase: {
      color: '#3C3C3B',
      
      '&$checked': {
        color: '#FF9666',
      },
      '&$checked + $track': {
        backgroundColor: '#EB5E50',
      },
    },
    checked: {},
    track: {},
  })(Switch);

function GetYouTubeTooltip(props) {
    const video = window.videoDetailsComponent.state.selectedVideo;
    if (!video.metadata) {
        return <div></div>
    }

    let accounts = window.VideoLibraryContainerComponent?.state.accounts.youtube ? window.VideoLibraryContainerComponent?.state.accounts : JSON.parse(localStorage.getItem('@crabber/accounts'));

    return <div>
        {(!accounts.youtube?.length || accounts.youtube?.length === 0 || accounts.youtube[0]?.youTubeChannels?.length === 0) ? (
            <Tooltip arrow interactive 
                title={
                    <React.Fragment>
                        <Typography color="inherit" component="span"  display="block">
                            Ops! Publicação não permitida pelo <b>YouTube</b>, é necessário adicionar uma conta de administrador do canal.
                        </Typography>
                        <Typography color="inherit" component="span"  display="block">
                            <Link href="/social">
                                Clique aqui para adicioná-la.
                            </Link>
                        </Typography>
                    </React.Fragment>
                } >
                <div>
                    <CrabberSwitch 
                        disabled = {true}
                    />
                </div>
            </Tooltip>
        ):(
            <CrabberSwitch 
                disabled = {false}
                checked = {window.videoDetailsComponent.state.youTubeChecked}
                onChange={(e) => { window.videoDetailsComponent.setState({youTubeChecked: e.target.checked}); }}
            />
        )}
    </div>
}

function GetFacebookTooltip(props) {
    const video = window.videoDetailsComponent.state.selectedVideo;
    if (!video.metadata) {
        return <div></div>
    }

    //console.log(window.VideoLibraryContainerComponent?.state.accounts.facebook);
    let accounts = window.VideoLibraryContainerComponent?.state.accounts.facebook ? window.VideoLibraryContainerComponent?.state.accounts : JSON.parse(localStorage.getItem('@crabber/accounts'));
    
    return <div>
        {(!accounts.facebook?.length || accounts.facebook.length === 0 || accounts.facebook[0]?.facebookPages?.length === 0) ? (
            <Tooltip arrow interactive 
                title={
                    <React.Fragment>
                        <Typography color="inherit" component="span"  display="block">
                            Ops! Publicação não permitida pelo <b>Facebook</b>, é necessário adicionar uma conta de administrador da página.
                        </Typography>
                        <Typography color="inherit" component="span"  display="block">
                            <Link href="/social">
                                Clique aqui para adicioná-la.
                            </Link>
                        </Typography>
                    </React.Fragment>
                } >
                <div>
                    <CrabberSwitch 
                        disabled = {true}
                    />
                </div>
            </Tooltip>
        ):(
            <CrabberSwitch 
                disabled = {false}
                checked = {window.videoDetailsComponent.state.facebookChecked }
                onChange={(e) => { window.videoDetailsComponent.setState({facebookChecked: e.target.checked}); }}
            />
        )}
    </div>
}

function GetInstagramTooltip(props) {
    const video = window.videoDetailsComponent.state.selectedVideo;
    if (!video.metadata) {
        return <div></div>
    }

    //console.log(window.VideoLibraryContainerComponent?.state.accounts.instagram);

    let accounts = window.VideoLibraryContainerComponent?.state.accounts.instagram ? window.VideoLibraryContainerComponent?.state.accounts : JSON.parse(localStorage.getItem('@crabber/accounts'));

    const duration = video.metadata?.duration.split(':');
    const seconds = (duration.length === 2) ? (+duration[0]) * 60 + (+duration[1]) : 120;
    
    return <div>
        {(!accounts.instagram?.length || accounts.instagram.length === 0 
        || accounts.instagram[0]?.instagramPages?.length === 0
        || seconds < 3 || seconds > 60) ? (
            <Tooltip arrow interactive 
                title={
                    <React.Fragment>
                        <Typography color="inherit" component="span" display="block">
                            Ops! Publicação não permitida pelo <b>Instagram</b>. Verifique as seguintes regras: 
                            <ul>
                                <li>A publicação de conteúdo só está disponível para usuários do <b>Instagram Business</b>. 
                                    <Typography color="inherit" component="span" display="block">
                                        <Link href="/social">
                                            Clique aqui para adicioná-la.
                                        </Link>
                                    </Typography>
                                </li>
                                <li>Máximo de <b>25 postagens</b> dentro de um período contínuo de 24 horas</li>
                                <li>Duração do vídeo - <b>Máximo 60 segundos, mínimo 3 segundos</b></li>
                            </ul>
                        </Typography>
                        
                    </React.Fragment>
                } >
                <div>
                    <CrabberSwitch 
                        disabled = {true}
                    />
                </div>
            </Tooltip>
        ):(
            <CrabberSwitch 
                disabled = {false}
                checked = {window.videoDetailsComponent.state.instagramChecked }
                onChange={(e) => { window.videoDetailsComponent.setState({instagramChecked: e.target.checked}); }}
            />
        )}
    </div>
}

class VideoDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVideo: {},
            intervalId: null,
            openResumeUpload: false,
            openDownloadRenditions: false,
            openCancelUploadVideo: false,
            openDeleteVideo: false,
            openDeleteVideoSuccess: false,
            openPublishConfirmation: false,
            openPublish: false,
            hideCheckedPublishConfirmation: false,
            status: '',
            textInput: {},
            waitFor: true,
            downloadWaitFor: false,
            publishWaitFor: false,
            selectedVideoRenditions: [],
            youTubeChecked: false,
            facebookChecked: false,
            instagramChecked: false,
        };
        
        this.notifyChangeSelectedVideo = this.notifyChangeSelectedVideo.bind(this);
        this.handleResumeUpload = this.handleResumeUpload.bind(this);
        this.handleCloseResumeUpload = this.handleCloseResumeUpload.bind(this);
        this.handleCloseCancelUploadVideoDialog = this.handleCloseCancelUploadVideoDialog.bind(this);
        this.handleCloseDeleteVideoSuccess = this.handleCloseDeleteVideoSuccess.bind(this);
        this.handleOpenCancelUploadVideoDialog = this.handleOpenCancelUploadVideoDialog.bind(this);
        this.handleDeleteVideo = this.handleDeleteVideo.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeTags = this.handleChangeTags.bind(this);
        this.handleOpenDownloadRenditions = this.handleOpenDownloadRenditions.bind(this);
        this.handleCloseDownloadRenditions = this.handleCloseDownloadRenditions.bind(this);
        this.handleClosePublishConfirmation = this.handleClosePublishConfirmation.bind(this);
        this.handleClosePublish = this.handleClosePublish.bind(this);
        this.handlePublishVideo = this.handlePublishVideo.bind(this);
        this.timer = this.timer.bind(this);

        window.videoDetailsComponent = this;
    }

    handleClosePublish() {
        this.setState({
            openPublish: false,
        });
    }

    handlePublishVideo(e) {
        this.setState({
            openPublishConfirmation: false,
            publishWaitFor: true,
        });

        let crabberKey = localStorage.getItem('@crabber/username');
        let youTubeKey = JSON.parse(localStorage.getItem('@crabber/accounts')).youtube[0]?.key;
        let channelId = window.VideoLibraryContainerComponent.state.accounts.youtube[0]?.youTubeSelectedChannel?.id;
        let privacyId = window.VideoLibraryContainerComponent.state.accounts.youtube[0]?.youTubeSelectedPrivacy?.id;
        let facebookKey = window.VideoLibraryContainerComponent.state.accounts.facebook[0]?.key;
        //let groupId = this.state.accounts.facebook[0].facebookSelectedGroup?.id;
        let pageId = window.VideoLibraryContainerComponent.state.accounts.facebook[0]?.facebookSelectedPage?.id;
        let instagramPageId = window.VideoLibraryContainerComponent.state.accounts.instagram[0]?.instagramSelectedPage?.id;
        let caption = window.VideoLibraryContainerComponent.state.accounts.instagram[0] && window.VideoLibraryContainerComponent.state.accounts.instagram[0]?.instagramCaption ? window.VideoLibraryContainerComponent.state.accounts.instagram[0]?.instagramCaption : '';

        let id = this.state.selectedVideo.id;
        let url = VideosApi.uri + '/' + id + `/targets`;
        
        let youTube = this.state.youTubeChecked ?  {
            key: youTubeKey,
            channel_id: channelId,
            privacy_id: privacyId,
        } : null;
        let facebook = this.state.facebookChecked ? {
            key: facebookKey,
            //group_id: groupId,
            page_id: pageId,
        } : null;
        let instagram = this.state.instagramChecked ? {
            key: facebookKey,
            //group_id: groupId,
            page_id: instagramPageId,
            caption: caption,
        } : null;
        let data = {
                title: this.state.selectedVideo.title,
                description: this.state.selectedVideo.description,
                tags: this.state.selectedVideo.keywords ? this.state.selectedVideo.keywords : [],
                crabber_key: crabberKey,
                youtube:  youTube,
                facebook: facebook,
                instagram: instagram,
            };
        axios({
            url: url,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: data,
        }).then(async response => {
            this.setState({
                publishWaitFor: false,
                openPublish: true,
            });
        })
        .catch(error => {
            this.setState({
                publishWaitFor: false,
                openPublish: true,
            });
            console.error(error);
        });

    }

    handleClosePublishConfirmation() {
        this.setState({
            openPublishConfirmation: false,
        })
    }

    handleOpenDownloadRenditions(){
        let id = this.state.selectedVideo.id;
        
        this.setState({
            downloadWaitFor: true,
        })

        axios({
            url: VideosApi.uri + '/' + id + `/download_renditions`, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                this.setState({
                    downloadWaitFor: false,
                    openDownloadRenditions: true,
                    selectedVideoRenditions: response.data,
                })
            }
        })
        .catch(error => {
            console.error(error);
            this.setState({
                downloadwaitFor: false,
            })
        });
    }

    handleCloseDownloadRenditions(){
        this.setState({
            openDownloadRenditions: false,
        })
    }

    handleOpenCancelUploadVideoDialog() {
        this.setState({
            openCancelUploadVideo: true,
        })
    }

    handleCloseResumeUpload() {
        this.setState({
            openResumeUpload: false,
        })
    }

    handleCloseDeleteVideoSuccess() {
        window.VideoLibraryContainerComponent.notifyVideoDeleted();
        this.setState({
            openDeleteVideoSuccess: false,
        })
    }

    handleDeleteVideo(e){
        let id = this.state.selectedVideo.id;

        axios({
            url: VideosApi.uri + '/' + id, 
            method: 'DELETE',
        }).then(response => {
            if (response.status === 204) {
                this.setState({
                    openDeleteVideo: false,
                    openDeleteVideoSuccess: true,
                })
                
            }
        })
        .catch(error => {
            console.error(error);
            this.setState({
                openDeleteVideo: false,
            })
        });
    }


    handleCloseCancelUploadVideoDialog(e){
        this.setState({
            openDeleteVideo: false,
        })
    }

    handleResumeUpload(e) {
        let id = this.state.selectedVideo.id;
        let video = this.state.selectedVideo;
        video.video_upload.progress = '0.00';
        video.video_upload.status = 'CREATED';
        this.setState({
            selectedVideo: video,
        })

        axios({
            url: VideosApi.uri + '/' + id + `/resume_upload`, 
            method: 'POST',
        }).then(response => {
            if (response.status === 200) {
                this.setState({
                    openResumeUpload: true,
                    selectedVideo: video,
                })
            }
        })
        .catch(error => {
            console.error(error);
        });

    }

    componentDidMount() {
        var intervalId = setInterval(this.timer, 5000);
        this.setState({
            intervalId: intervalId,
            waitFor: true,
            selectedVideo: {},
        });
        
    }
    
    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.setState({
            intervalId: null,
        });
    }

    timer() {   
        

        let video = { ...this.state.selectedVideo };
        
        if (video.video_upload && video.video_upload.status && (video.video_upload.status !== 'COMPLETE' || 
            (video.video_upload.status === 'COMPLETE' && video.video_upload.thumbnail?.includes("default_")))) {
            
            axios({
                url: VideosApi.uri + '/' + video.id, 
                method: 'GET'
            }).then(response => {
                if (response.status === 200) {
                    video = response.data;
                    video.metadata.file_size_human = Utils.humanFileSize(video.metadata.file_size);
                    this.setState({
                        selectedVideo: video,
                    });
                }
            })
            .catch(error => {
                console.error(error);
            });
        }

        if (video.version > 0) {
            video.version = 0;
            this.setState({
                status: 'Salvando...',
                selectedVideo: video,
            });
            
            const requestOptions = {
                url: VideosApi.uri + '/' + video.id, 
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    title: this.state.selectedVideo.title,
                    description: this.state.selectedVideo.description,
                    tags: this.state.selectedVideo.tags,
                }
            };

            axios(requestOptions)
                .then(async response => {
                    const data = response.data;
                    if (response.status === 200 || response.status === 201) {
                        this.setState({
                            status: 'Salvo!',
                        });
                        window.VideoLibraryContainerComponent.notifyVideoEdited()
                    } else {
                        const error = (data && data) || response.status; 
                        return Promise.reject(error);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }



    }


    notifyChangeSelectedVideo(video) {
        if (!video) {
            return;
        }

        this.setState({
            waitFor: false,
        });
        
        let oldVideo = {...this.state.selectedVideo}

        if (oldVideo.version > 0) {
            const requestOptions = {
                url: VideosApi.uri + '/' + oldVideo.id, 
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                data: {
                    title: oldVideo.title,
                    description: oldVideo.description,
                    tags: oldVideo.tags,
                }
            };
            
            axios(requestOptions)
                .then(async response => {
                    
                    if (response.status === 200 || response.status === 201) {
                        this.setState({
                            status: 'Salvo!',
                        });
                        window.VideoLibraryContainerComponent.notifyVideoEdited()
                    } 
                })
                .catch(error => {
                    console.error(error);
                });
        }

        video.metadata.file_size_human = Utils.humanFileSize(video.metadata.file_size);
        video.version = 0;

        this.setState({
            selectedVideo: video,
            youTubeChecked: false,
            facebookChecked: false,
            instagramChecked: false,
        });
    }

    handleChangeTitle(e) {
        let video = {...this.state.selectedVideo};

        video.modified_date = Utils.formattedDate(); 
        video.title = e.target.value;
        video.version++;

        this.setState({
            status: 'Salvando...',
            selectedVideo: video,
        })
    }

    handleChangeDescription(e) {
        let video = {...this.state.selectedVideo};

        video.modified_date = Utils.formattedDate(); 
        video.description = e.target.value;
        video.version++;

        this.setState({
            status: 'Salvando...',
            selectedVideo: video,
        })
    }

    handleChangeTags(e, newValue) {
        let video = {...this.state.selectedVideo};

        video.modified_date = Utils.formattedDate(); 
        video.tags = newValue;
        video.version++;

        this.setState({
            status: 'Salvando...',
            selectedVideo: video,
        })
    }

    render() {
        
        return (
            <div style={{
                width: '100%',
                height: '100%',
                
            }}>
                {(this.state.waitFor) ? (<div style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            position: 'relative',
                                                        }}>
                    <CircularProgress style={{
                                                            color: 'white',
                                                            position: 'absolute',
                                                            width: 120,
                                                            height: 120,
                                                            left: '50%',
                                                            marginLeft: '-60px',
                                                            top: '50%',
                                                            marginTop: '-60px',
                                                        }}/>
                </div>
                ) : (
                <div>
                    <Grid
                        container
                        direction="column"
                        justify="flex-end"
                        alignItems="stretch"
                        spacing={1}>
                        <Grid item>
                            {(this.state.selectedVideo?.video_upload?.status === 'COMPLETE') ? (
                                <div style={{
                                    width: '100%',
                                    height: '100%',
                                    position: 'relative',
                                }}>
                                    <CircularProgress style={{
                                        color: 'white',
                                        position: 'absolute',
                                        width: 120,
                                        height: 120,
                                        left: '50%',
                                        marginLeft: '-60px',
                                        top: '50%',
                                        marginTop: '-60px',
                                        zIndex: '-99999',
                                    }}/>

                                    <div style={{
                                         width: '100%',
                                         height: '17vw',
                                    }} dangerouslySetInnerHTML={{__html: this.state.selectedVideo?.video_upload?.html.replace('width="400"', 'width="100%"').replace('height="300"', 'height="100%"').replace('allowfullscreen', '')}} />
                                </div>
                            ) : (
                                <div>
                                    <div className='video-details-thumb'>
                                        <img src={this.state.selectedVideo?.video_upload?.thumbnail ? this.state.selectedVideo.video_upload.thumbnail : AvatarCrabber} alt="thumb" 
                                        onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} 
                                        width="100%" height="auto" />
                                        <div className='video-details-thumb-duration'>{this.state.selectedVideo?.metadata?.duration ? this.state.selectedVideo.metadata.duration : ''}</div>
                                    </div>
                                    {(this.state.selectedVideo?.video_upload?.status.includes('ERROR')) && <div>
                                        <Typography variant="subtitle1" display="block" gutterBottom>Houve um erro durante o acervamento. <Link href="#" onClick={(e) => {this.handleResumeUpload(e);}} >
                                            Tentar novamente
                                            </Link>
                                        </Typography>
                                    </div>}
                                    {(this.state.selectedVideo?.video_upload?.status === 'UPLOADING') && <div>
                                        <Typography variant="subtitle1" display="block" gutterBottom>Carregando em seu acervo. <Link href="#" onClick={(e) => {this.handleOpenCancelUploadVideoDialog(e);}} >Clique aqui para cancelar.</Link>
                                        <LinearProgressWithLabel value={parseFloat(this.state.selectedVideo?.video_upload?.progress)} /> 
                                        </Typography>
                                    </div>}
                                    {(this.state.selectedVideo?.video_upload?.status === 'TRANSCODING') && <div>
                                        <Typography variant="subtitle1" display="block" gutterBottom>Este clipe está sendo otimizado. Esse processo pode levar alguns minutos. <BorderLinearProgress />
                                        </Typography>
                                    </div>}
                                    {(this.state.selectedVideo?.video_upload?.status === 'CREATED') && <div>
                                        <Typography variant="subtitle1" display="block" gutterBottom>Estamos quase lá. Aguardando o inicio do acervamento.  <BorderLinearProgress />
                                        </Typography>
                                    </div>}
                                </div>
                            )}
                        </Grid>
                        <Grid item>
                            <div className='video-details-block'>
                                <Typography variant="h5" gutterBottom display='block'>
                                        Metadados
                                </Typography>
                                <div className='video-details-metadata'>
                                    <Grid container direction="row" alignItems="flex-start" spacing={1}>
                                        <Grid item xs={6}>
                                            <Tooltip title="Dia do clipe" arrow placement="left">
                                                <Typography variant="subtitle1" gutterBottom display='block'>
                                                    <TodayRoundedIcon alt='Dia do clipe'/>
                                                    {this.state.selectedVideo?.metadata?.start_date}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title="Intervalo do clipe" arrow placement="left">
                                                <Typography variant="subtitle1" gutterBottom display='block'>
                                                    <ScheduleRoundedIcon alt='Intervalo do clipe' /> 
                                                    de {this.state.selectedVideo?.metadata?.start_time} até {this.state.selectedVideo?.metadata?.end_time}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title="Tamanho do clipe" arrow placement="left">
                                                <Typography variant="subtitle1" gutterBottom display='block'>
                                                    <DescriptionRoundedIcon alt='Tamanho do clipe' /> 
                                                    {this.state.selectedVideo?.metadata?.file_size_human}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title="Duração do clipe" arrow placement="left">
                                                <Typography variant="subtitle1" gutterBottom display='block'>
                                                    <OndemandVideoRoundedIcon alt='Duração do clipe' /> 
                                                    {this.state.selectedVideo?.metadata?.duration}
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className='video-details-block'>
                                <Typography variant="h5" gutterBottom display='block'>
                                        Canal de Origem
                                </Typography>
                                <Grid container direction="row" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={3}>
                                        <div className='video-details-origin-thumbnail'>
                                            <img src={this.state.selectedVideo.origin?.thumbnail ? this.state.selectedVideo.origin.thumbnail : AvatarCrabber}  alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="subtitle1" gutterBottom display='block'>
                                            Live publicada por {this.state.selectedVideo.origin?.uploader ? this.state.selectedVideo.origin.uploader : ''}
                                            {this.state.selectedVideo.origin?.uploader === 'YOUTUBE' &&  <Link href={this.state.selectedVideo.origin?.address} style={{
                                                    verticalAlign: 'top'
                                                }}>
                                                <img src={LogoYouTube} height='25px' alt="Logo YouTube"/>
                                            </Link>}
                                        </Typography> 
                                        <Typography variant="caption" gutterBottom display='block'>
                                            {this.state.selectedVideo.origin?.title ? this.state.selectedVideo.origin.title : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className='video-details-block'>
                                <Typography variant="h5" gutterBottom display='block'>
                                        Informações
                                </Typography>
                                <Grid container direction="row" alignItems="flex-start" spacing={1}>
                                    <Grid item xs={12}>
                                        <div className='video-details-metadata'>
                                            <Tooltip title="Última alteração salva" arrow placement="left">
                                                <Typography variant="subtitle1" gutterBottom display='block'>
                                                    <SaveOutlinedIcon alt='Última alteração salva' /> 
                                                    {this.state.selectedVideo?.modified_date} {this.state.status} {(this.state.status === 'Salvando...') ? (<BackupRoundedIcon />) : (this.state.status === 'Salvo!') && <CloudDoneRoundedIcon />}
                                                </Typography>
                                            </Tooltip>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined" 
                                            color="primary" 
                                            margin="dense" 
                                            placeholder=""
                                            label="Insira o título" 
                                            fullWidth
                                            autoFocus
                                            value={this.state.selectedVideo.title ? this.state.selectedVideo.title : 'Sem título'}
                                            onChange={(e) => { this.handleChangeTitle(e) }}
                                            inputProps={{ autoFocus: true }}
                                            InputLabelProps={{ shrink: true }} 
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined" 
                                            color="primary" 
                                            margin="dense" 
                                            placeholder=""
                                            label="Insira o texto para redes sociais" 
                                            fullWidth
                                            value={this.state.selectedVideo.description ? this.state.selectedVideo.description : ''}
                                            onChange={(e) => { this.handleChangeDescription(e)  }}
                                            multiline={true}
                                            rows="5"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.state.selectedVideo.tags && <Autocomplete
                                            multiple
                                            id="tags-filled"
                                            freeSolo
                                            fullWidth
                                            value={this.state.selectedVideo.tags}
                                            options={this.state.selectedVideo.tags.map((option) => option)}
                                            filterSelectedOptions={true}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Insira as palavras chaves" placeholder="" InputLabelProps={{ shrink: true }} />
                                            )}
                                            
                                            onChange={(event, newValue) => {
                                                this.handleChangeTags(event, newValue);
                                            }}
                                        />}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className='video-details-block'>
                                <FormGroup column="true">
                                    <FormControlLabel style={{width: 0}}
                                        control={
                                            <GetYouTubeTooltip/>
                                        }
                                        label="YouTube"
                                    />                                   
                                </FormGroup>
                                <br  />
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-end"
                                    >
                                    <Grid item>
                                        {(window.VideoLibraryContainerComponent.state.accounts.youtube && window.VideoLibraryContainerComponent.state.accounts.youtube[0]?.youTubeSelectedChannel && window.VideoLibraryContainerComponent.state.accounts.youtube?.length >= 0) && <Autocomplete
                                            id="combo-box-channel"
                                            autoSelect
                                            disableClearable
                                            disabled={this.state.downloadWaitFor || !this.state.youTubeChecked || this.state.youTubeChecked === false }
                                            value={window.VideoLibraryContainerComponent.state.accounts.youtube[0].youTubeSelectedChannel}
                                            options={window.VideoLibraryContainerComponent.state.accounts.youtube[0].youTubeChannels}
                                            getOptionLabel={(option) => option ? option.name : ''}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            onChange={(event, newValue) => {
                                                let accounts = window.VideoLibraryContainerComponent.state.accounts;
                                                accounts.youtube[0].youTubeSelectedChannel = newValue;
                                                window.VideoLibraryContainerComponent.setState({accounts: accounts});
                                            }}
                                            style={{ width: 300 }}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    <span>
                                                        <ComboBoxAvatar alt="option.name" src={option ? option.thumbnail : ''} />
                                                    </span>
                                                    {option ? option.name : ''}
                                                </React.Fragment>
                                            )}
                                            renderInput={(option) => (<TextField {...option} label="Canal" variant="outlined" />)}
                                        />
                                        }
                                    </Grid>
                                    <br  />
                                    <Grid item>
                                        {(window.VideoLibraryContainerComponent.state.accounts.youtube && window.VideoLibraryContainerComponent.state.accounts.youtube[0]?.youTubeSelectedChannel && window.VideoLibraryContainerComponent.state.accounts.youtube?.length >= 0) && <Autocomplete
                                            id="combo-box-privacy"
                                            autoSelect
                                            disableClearable
                                            disabled={this.state.downloadWaitFor || !this.state.youTubeChecked || this.state.youTubeChecked === false}
                                            value={window.VideoLibraryContainerComponent.state.accounts.youtube[0].youTubeSelectedPrivacy}
                                            options={window.VideoLibraryContainerComponent.state.accounts.youtube[0].youTubePrivacies}
                                            getOptionLabel={(option) => option ? option.label : ''}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            onChange={(event, newValue) => {
                                                let accounts = window.VideoLibraryContainerComponent.state.accounts;
                                                accounts.youtube[0].youTubeSelectedPrivacy = newValue;
                                                window.VideoLibraryContainerComponent.setState({accounts: accounts});
                                            }}
                                            style={{ width: 300 }}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    {option ? option.label : ''}
                                                </React.Fragment>
                                            )}
                                            renderInput={(option) => (<TextField {...option} label="Privacidade" variant="outlined" />)}
                                        />
                                        }
                                    </Grid>
                                    
                                </Grid>

                            </div>
                        </Grid>

                        <Grid item>
                            <div className='video-details-block'>
                                <FormGroup column="true">
                                    <FormControlLabel style={{width: 0}}
                                        control={<GetFacebookTooltip/>}
                                        label="Facebook"
                                    />       
                                </FormGroup>
                                <br  />
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-end"
                                    >
                                    <Grid item>
                                        {(window.VideoLibraryContainerComponent.state.accounts.facebook && window.VideoLibraryContainerComponent.state.accounts.facebook[0]?.facebookSelectedPage && window.VideoLibraryContainerComponent.state.accounts.facebook?.length >= 0) && <Autocomplete
                                            id="combo-box-page-facebook"
                                            autoSelect
                                            disableClearable
                                            disabled={this.state.downloadWaitFor || !this.state.facebookChecked || this.state.facebookChecked === false}
                                            value={window.VideoLibraryContainerComponent.state.accounts.facebook[0].facebookSelectedPage}
                                            options={window.VideoLibraryContainerComponent.state.accounts.facebook[0].facebookPages}
                                            getOptionLabel={(option) => option ? option.name : ''}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            onChange={(event, newValue) => {
                                                let accounts = window.VideoLibraryContainerComponent.state.accounts;
                                                accounts.facebook[0].facebookSelectedPage = newValue;
                                                window.VideoLibraryContainerComponent.setState({accounts: accounts});
                                            }}
                                            style={{ width: 300 }}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    {option ? option.name : ''}
                                                </React.Fragment>
                                            )}
                                            renderInput={(option) => (<TextField {...option} label="Página" variant="outlined" />)}
                                        />
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className='video-details-block'>
                                <FormGroup column="true">
                                    <FormControlLabel style={{width: 0}}
                                        control={<GetInstagramTooltip/>}
                                        label="Instagram"
                                    />  
                                    <br  />     
                                </FormGroup>
                                <br  />
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-end"
                                    >
                                    <Grid item>
                                        {(window.VideoLibraryContainerComponent.state.accounts.instagram && window.VideoLibraryContainerComponent.state.accounts.instagram[0]?.instagramSelectedPage && window.VideoLibraryContainerComponent.state.accounts.facebook?.length >= 0) && <Autocomplete
                                            id="combo-box-page-insta"
                                            autoSelect
                                            disableClearable
                                            disabled={this.state.downloadWaitFor || !this.state.instagramChecked || this.state.instagramChecked === false}
                                            value={window.VideoLibraryContainerComponent.state.accounts.instagram[0].instagramSelectedPage}
                                            options={window.VideoLibraryContainerComponent.state.accounts.instagram[0].instagramPages}
                                            getOptionLabel={(option) => option ? option.name : ''}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            onChange={(event, newValue) => {
                                                let accounts = window.VideoLibraryContainerComponent.state.accounts;
                                                accounts.instagram[0].instagramSelectedPage = newValue;
                                                window.VideoLibraryContainerComponent.setState({accounts: accounts});
                                            }}
                                            style={{ width: 300 }}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    {option ? option.name : ''}
                                                </React.Fragment>
                                            )}
                                            renderInput={(option) => (<TextField {...option} label="Página" variant="outlined" />)}
                                        />
                                        }
                                    </Grid>
                                    <br/>
                                    <Grid item>
                                        {(window.VideoLibraryContainerComponent.state.accounts.instagram && window.VideoLibraryContainerComponent.state.accounts.instagram[0]?.instagramCaption && window.VideoLibraryContainerComponent.state.accounts.facebook?.length) >= 0 && <TextField
                                            disabled={this.state.downloadWaitFor || !this.state.instagramChecked || this.state.instagramChecked === false}
                                            id="text-field-caption-insta"
                                            variant="outlined" 
                                            color="primary" 
                                            margin="dense"
                                            label="Legenda do vídeo" 
                                            fullWidth
                                            value={window.VideoLibraryContainerComponent.state.accounts.instagram ? window.VideoLibraryContainerComponent.state.accounts.instagram[0].instagramCaption : ''}
                                            onChange={(e) => {
                                                let accounts = window.VideoLibraryContainerComponent.state.accounts;
                                                if (!accounts.instagram) {
                                                    accounts.instagram = [];
                                                    let emptyInstagram = {}
                                                    emptyInstagram.instagramCaption = '';
                                                    accounts.instagram.push(emptyInstagram);
                                                }
                                                accounts.instagram[0].instagramCaption = e.target.value;
                                                window.VideoLibraryContainerComponent.setState({accounts: accounts});
                                            }}
                                            multiline
                                            rows={10}
                                            style={{ width: 300 }}
                                            helperText="Maximo 30 hastags e 20 menções"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="video-details-actions ">
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    disableRipple
                                    onClick={(e) => {this.handleOpenDownloadRenditions();}}
                                    startIcon={<AvatarButtonCrabber alt="thumb" src={downloadThumbIcon} className={ (this.state.downloadWaitFor) ? 'avatar-disabled' : ''}/>}
                                    disabled={!this.state.selectedVideo?.video_upload?.status === 'TRANSCODING' || !this.state.selectedVideo?.video_upload?.status === 'COMPLETE'}
                                    >
                                    DOWNLOAD
                                    {this.state.downloadWaitFor  && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 24,
                                                                height: 24
                                                            }}/>}
                                </Button>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    disableRipple
                                    onClick={(e) => {
                                        if (localStorage.getItem('@crabber/hideCheckedPublishConfirmation') === 'true') {
                                            this.handlePublishVideo(e);
                                        } else {
                                            this.setState({openPublishConfirmation: true});    
                                        }
                                        
                                    }}
                                    startIcon={<AvatarButtonCrabber alt="thumb" src={socialIcon} className={ (
                                        (!this.state.selectedVideo?.video_upload?.status === 'TRANSCODING' || !this.state.selectedVideo?.video_upload?.status === 'COMPLETE') ||
                                        this.state.publishWaitFor || 
                                        (!this.state.youTubeChecked && 
                                            !this.state.facebookChecked && 
                                            !this.state.instagramChecked) || 
                                        (this.state.youTubeChecked === false && 
                                            this.state.facebookChecked === false && 
                                            this.state.instagramChecked === false) || 
                                        (window.VideoLibraryContainerComponent.accounts?.youtube?.length === 0 && 
                                            window.VideoLibraryContainerComponent.accounts?.facebook?.length === 0 && 
                                            window.VideoLibraryContainerComponent.accounts?.instagram?.length === 0)
                                        ) ? 'avatar-disabled' : ''} />}
                                    disabled={
                                        (!this.state.selectedVideo?.video_upload?.status === 'TRANSCODING' || !this.state.selectedVideo?.video_upload?.status === 'COMPLETE') ||
                                        this.state.publishWaitFor || 
                                        (!this.state.youTubeChecked && 
                                            !this.state.facebookChecked &&
                                            !this.state.instagramChecked) || 
                                        (this.state.youTubeChecked === false && 
                                            this.state.facebookChecked === false && 
                                            this.state.instagramChecked === false) ||
                                        (window.VideoLibraryContainerComponent.accounts?.youtube?.length === 0 && 
                                            window.VideoLibraryContainerComponent.accounts?.facebook?.length === 0  && 
                                            window.VideoLibraryContainerComponent.accounts?.instagram?.length === 0)
                                    }
                                    >
                                    PUBLICAR
                                    {this.state.publishWaitFor && <CircularProgress style={{
                                                                            color: 'white',
                                                                            position: 'absolute',
                                                                            width: 24,
                                                                            height: 24
                                                                        }}/>}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>)}
                <div>
                    <Dialog
                        open={this.state.openDownloadRenditions}
                        onClose={this.handleCloseDownloadRenditions}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Download"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.selectedVideoRenditions.length === 0 ? 'Este clipe ainda está sendo carregado em seu acervo, aguarde mais um instante e tente novamente.' : 'Para iniciar o download, selecione abaixo a qualidade desejada.'}
                            </DialogContentText>
                            {this.state.selectedVideoRenditions.map((rendition, index) => (   
                                <div key={index} className="video-detils-download-renditions">     
                                   <ButtonBase href={rendition.link} style={{width: '100%', textAlign:'center'}} disableRipple>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom>
                                                    {rendition.quality.toLowerCase() === 'source' ? "Qualidade original" : rendition.quality}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    {Utils.humanFileSize(rendition.size) + ' | ' + rendition.width + 'x' + rendition.height}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </div>
                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDownloadRenditions} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                ANULAR
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openResumeUpload}
                        onClose={this.handleCloseResumeUpload}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Acervamento"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Por favor aguarde, logo o Crabber vai tentar subir novamente o clipe"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseResumeUpload} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openCancelUploadVideo}
                        onClose={this.handleCloseCancelUploadVideoDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-cancel"
                        aria-describedby="alert-dialog-description-cancel">
                        <DialogTitle id="alert-dialog-title-cancel">{"Cancelar carregamento"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-cancel">
                                {"Tem certeza que deseja cancelar?  Essa ação irá excluir permanentemente este clipe"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseCancelUploadVideoDialog} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                NÃO
                            </Button>
                            <Button onClick={this.handleDeleteVideo} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                SIM
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openDeleteVideoSuccess}
                        onClose={this.handleCloseDeleteVideoSuccess}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-delete-success"
                        aria-describedby="alert-dialog-description-delete-success">
                        <DialogTitle id="alert-dialog-title-delete-success">{"Excluir clipe"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description-delete-success">
                                {"Video excluido com sucesso!"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseDeleteVideoSuccess} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openPublishConfirmation}
                        onClose={this.handleClosePublishConfirmation}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Estamos quase lá!"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"É importante você saber que o tempo para finalizar a publicação pode variar conforme a rede social e o tamanho do clip. Clique em CONTINUAR e aguarde a publicação."}
                            </DialogContentText>
                            
                            <br />
                            <FormControlLabel
                                control={
                                <PublishConfirmationCheckbox
                                    checked={this.hideCheckedPublishConfirmation}
                                    onChange={(e) => {
                                        this.setState({
                                            hideCheckedPublishConfirmation: e.target.checked
                                        });
                                        localStorage.setItem('@crabber/hideCheckedPublishConfirmation', e.target.checked);
                                    }}
                                />
                                }
                                label="Não exibir essa mensagem novamente"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClosePublishConfirmation} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Anular
                            </Button>
                            <Button onClick={(e) => this.handlePublishVideo(e)} color="primary" variant="contained" disableRipple type="submit">
                                Continuar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openPublish}
                        onClose={this.handleClosePublish}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-pub"
                        aria-describedby="alert-dialog-description-pub">
                        <DialogTitle id="alert-dialog-title-pub">{"Publicar"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Clipe enviado para publicação"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClosePublish} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}


function VideoDetails(props) {
    return (
        <div>
            <div className='video-details-container'>
                <VideoDetailsContainer />
            </div>
        </div>
    )
}

export default VideoDetails