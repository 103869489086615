import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const CurrentLivePositionCrabberSlider = withStyles({
    root: {
        position: "absolute",
        cursor: "default",
    },
    thumb: {
      height: 85,
      width: 4,
      backgroundColor: "#EB5E50",
      border: "1px solid currentColor",
      borderRadius: 3,
      marginLeft: -2,
      marginTop: 15,
      zIndex: 0,
    },
    active: {},

    track: {
      height: 80,
      marginTop: 20,
      backgroundColor: "#00000000"
    },

    rail: {
      color: "#00000020",
      opacity: 1,
      height: 80,
      marginTop: 20,
      borderRadius: 5,
    },

})(Slider);

export default CurrentLivePositionCrabberSlider;