import React from 'react';
import { theme } from 'theme.js';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SigninForm from './components/signin/SigninForm'
import 'Login.css'


class LoginContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };

        
    }

    componentDidMount() {
        localStorage.setItem('@crabber/token', '');
        localStorage.setItem('@crabber/refresh', '');
    }

    componentDidUpdate() { 
    
    }

    

    render() {
        return (
            <div>
                <Grid container
                    direction="row"
                    justify="space-between"
                    alignItems="stretch" className='login-grid-container' >
                    <Grid item xs={3}>
                        
                    </Grid>
                    <Grid item xs={6}>
                        <SigninForm />
                    </Grid>
                    <Grid item xs={3} >
                        
                    </Grid>
                </Grid>
            </div>

        )
    }
}

function Login(props) {
    return (
        <div className='login-container'>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <LoginContainer />
            </ThemeProvider>
        </div>
    )

}

export default Login