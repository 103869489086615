import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';

const AddZoomButtonCrabber = withStyles((theme) => ({
    root: {
        position: "absolute",
        color: "#AAAAAA",
        marginLeft: -18,
        padding: 0,
    }
}))(IconButton); 

export default AddZoomButtonCrabber;