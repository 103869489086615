import React, { useState } from 'react';
import { styled } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { PresetsApi } from 'config/config';

import axios from 'interceptors';

const ListItemIconStyled = withStyles({
    root: {
        minWidth: '35px'
    }, 
})(ListItemIcon);

const MenuItemStyled = withStyles({
    root: {
        height: '35px'
    }, 
})(MenuItem);

const IconButtonStyled = withStyles({
    root: {
        color: '#FFFFFF90'
    }, 
})(IconButton);

const MenuStyled = withStyles((theme) => ({
    paper: {
        backgroundColor: '#262626',
        border: '1px solid #FFFFFF90',
    },
}))(Menu);

const CardHeaderStyled = withStyles({
    root: {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    action: {
        marginTop: 0,
    }, 
})(CardHeader);

export const PresetCard = ({ preset, handleDeletePreset, openEditProject }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
      setAnchorEl(null);
    }

    const handleEdit = () => {
        openEditProject(preset);
    }

    const handleDelete= () => {

        axios({
            url:  `${PresetsApi.uri}/${preset.id}`,
            method: 'DELETE'
        })
            .then(async response => {
                if (response.status === 200 || response.status === 201) {
                    handleDeletePreset(preset.id);                    
                }
            })
            .catch(error => {
                console.error(error);
            });
    }
    
    return (
        <Card style={{maxWidth: '40vh', marginLeft: 10, marginTop: 10}}>
            <CardHeaderStyled
                action={
                <IconButtonStyled size="small" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" 
                aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                    <MoreVertIcon />
                </IconButtonStyled>
                }
                titleTypographyProps={{ variant: 'subtitle1' }}
                title={preset.title}
            />
            <MenuStyled
                id="basic-menu"
                elevation={0}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItemStyled onClick={handleEdit}>
                    <ListItemIconStyled>
                        <Edit fontSize="small" />
                    </ListItemIconStyled>
                    <ListItemText>Editar</ListItemText>
                </MenuItemStyled>
                <MenuItemStyled onClick={handleDelete}>
                    <ListItemIconStyled>
                        <Delete fontSize="small" />
                    </ListItemIconStyled>
                    <ListItemText>Excluir</ListItemText>
                </MenuItemStyled>
            </MenuStyled>
            <CardContent style={{ paddingTop: 0 }}>
                {
                    preset.type == 'Tags' ? 
                    (
                        <>{preset.value.map((value) => (<Chip label={value} style={{ marginRight: 5, marginTop: 5 }} />))}</>
                    ) 
                    : ( <>{preset.value}</> )
                }
            </CardContent>
        </Card>
    );
};