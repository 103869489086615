import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const ZoomCrabberSlider = withStyles({
    root: {
        position: "absolute",
        '&$vertical': {
            height: 115,
        }
    },
    thumb: {
      backgroundColor: "#AAAAAA",
      marginTop: 0,
      zIndex: 0,
      borderRadius: 25,
    },
    active: {},

    track: {
      height: 80,
      marginTop: 0,
      width: 20,
      backgroundColor: "#00000080",
      borderRadius: 5,
    },
    
    vertical: {
        backgroundColor: "#00000080",
        marginLeft: -20,
        borderRadius: 25,
        
        '& $rail': {
            width: 8,
            backgroundColor: "#313131",
            marginLeft: -3,
            height: 65,
            marginTop: 25,
            borderRadius: 5,
        },
        '& $thumb': {
            marginLeft: -11,
            marginBottom: -4,
            width: 24,
            height: 8,
        }

    },
    rail: {
        '& $rail': {
            backgroundColor: "#313131",
        }
      
    },

})(Slider);

export default ZoomCrabberSlider;