import React from 'react';
import Sidebar from './components/commons/Sidebar'
import Grid from '@material-ui/core/Grid';
import UsageLibrary from './components/library/UsageLibrary'
import VideoLibrary from './components/library/VideoLibrary'
import VideoDetails from './components/library/VideoDetails'

import { theme } from 'theme.js';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import 'Library.css'


class LibraryContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
        
    }

   
    
    componentDidMount() {
   
    }

   
    componentWillUnmount() {
   
    }

   



    render() {
        return (
            <div>
                <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="center" className='library-grid-container' >
                        <Grid item xs={12}>
                            <UsageLibrary />
                        </Grid>
                        <Grid item xs={8}>
                            <VideoLibrary />
                        </Grid>
                        <Grid item xs={4} >
                            <VideoDetails />
                        </Grid>
                </Grid>
            </div>

        )
    }
}

function Library(props) {
    
    return (
        <div className='library-container'>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Sidebar />
                <LibraryContainer />
            </ThemeProvider>
        </div>
    )

}

export default Library