import React from 'react';
import { theme } from 'theme.js';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Sidebar from './components/commons/Sidebar'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {UsersApi, UserUsageApi, crabberVideoEditingURI} from 'config/config';
import axios from 'interceptors';
import 'Person.css'

const PersonDivider = withStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF10',
        width: '100%',
    },
    
}))(Divider);

class PersonContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            videoEditingBetaEnabled: false,
        };
        
    }
    
    componentDidMount() {
        let username = localStorage.getItem('@crabber/username');
        let url = UsersApi.uri + '/' + username
        const requestOptionsFacebook = {
            url: url,
            method: 'GET',
        };
        axios(requestOptionsFacebook)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        user: data,
                    });
                }
            })
            .catch(error => {
                console.error(error);
            });

        axios({
            url: UserUsageApi.uri + '/' + username, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                let overview = response.data;
                let config = overview.config;

                this.setState({
                    videoEditingBetaEnabled: config.video_editing_beta_enabled
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

   
    componentWillUnmount() {
   
    }

   
    handleSubmitVideoEditing (event){
        var obj = {username: localStorage.getItem('@crabber/username'), token: localStorage.getItem('@crabber/token'), refresh: localStorage.getItem('@crabber/refresh')};
        var encoded = btoa(JSON.stringify(obj))
        window.location.href = crabberVideoEditingURI + "?crab-control=" + encoded;
    }


    render() {
        return (
            <div>
                <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="center" 
                    >
                    <div className='person-grid-container'>

                        <Grid item xs={12}>
                            <Typography variant="h3" gutterBottom>
                                Conta
                            </Typography>
                            <br />
                            <br />
                            <Grid
                                container
                                justify="space-around" 
                                spacing={2}
                                style={{
                                    borderRadius: 5,
                                    backgroundColor: '#ffffff10',
                                    padding: 10,
                                }}
                                >
                                <Grid item xs={12}>
                                    <Typography variant="h5" gutterBottom>
                                        Dados pessoais
                                    </Typography>
                                </Grid>
                                <PersonDivider />
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom display="inline">
                                        <b>Nome: </b> {this.state.user.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom align="right">
                                        
                                    </Typography>
                                </Grid>
                                <PersonDivider />
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom display="inline">
                                        <b>Sobrenome: </b> {this.state.user.last_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom align="right">
                                        
                                    </Typography>
                                </Grid>
                                <PersonDivider />
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom display="inline">
                                        <b>Data de nascimento: </b> {this.state.user.birthdate}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom align="right">
                                        
                                    </Typography>
                                </Grid>
                                <PersonDivider />
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom display="inline">
                                        <b>CPF: </b> {this.state.user.cpf}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom align="right">
                                        
                                    </Typography>
                                </Grid>
                                <PersonDivider />
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom display="inline">
                                        <b>Celular: </b> {this.state.user.mobile_number}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom align="right">
                                        
                                    </Typography>
                                </Grid>
                                <PersonDivider />
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom display="inline">
                                        <b>Email: </b> {this.state.user.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" gutterBottom align="right">
                                        
                                    </Typography>
                                </Grid>
                                <PersonDivider />
                                <Grid item xs={12}></Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='video-editing-container'>
                        {this.state.videoEditingBetaEnabled && (<Button 
                            variant="contained" 
                            type="submit"
                            color="primary"
                            style={{ marginLeft: 'auto' }}
                            autoFocus
                            disableRipple
                            disabled={!this.state.videoEditingBetaEnabled}
                            onClick={this.handleSubmitVideoEditing}>
                            Acessar Crabber Video Editing BETA
                        </Button>)}
                    </div>
                </Grid>
            </div>

        )
    }
}

function Person(props) {
    
    return (
        <div className='person-container'>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Sidebar />
                <PersonContainer />
            </ThemeProvider>
        </div>
    )

}

export default Person