import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const CrabberSlider = withStyles({
    root: {
      position: "absolute"
    },
    thumb: {
      
    },
    active: {},
    valueLabel: {
      display: "none"
    },
    track: {
      height: 80,
      marginTop: 20,
      backgroundColor: "#FF966600"
    },
    rail: {
      color: "#00000025",
      opacity: 1,
      height: 80,
      marginTop: 20,
      borderRadius: 5,
    },
    mark: {
      color: "#FFFFFF",
      height: 5,
      marginTop: 15,
      width: 1
    },
    markActive: {
      color: "#FF9666",
      height: 5,
      marginTop: 15,
      width: 1,
      backgroundColor: "#FFFFFF",
    },
    markLabel: {
      marginTop: -20,
      color: "#FFFFFF"
    },
    markLabelActive: {
      marginTop: -20,
      color: "#FFFFFF"
    }
})(Slider);

export default CrabberSlider;