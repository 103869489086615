import React from 'react';
import Sidebar from './components/commons/Sidebar'
import Grid from '@material-ui/core/Grid';
import { theme } from 'theme.js';
import { CssBaseline, DialogContentText, ThemeProvider } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import {UsersApi, YouTubeOauth2Api, FacebookOauth2Api, YouTubeApi, FacebookApi, PresetsApi} from 'config/config'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useLocation } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';

import axios from 'interceptors';

import 'Social.css'
import { PresetCard } from 'components/social/PresetCard';

const ComboBoxAvatar = withStyles((theme) => ({
    root: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: 10
    },
    
}))(Avatar);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class SocialContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            presets: [],
            youTube: [],
            youTubeMax: 1,
            youTubeChannels: [],
            youTubePrivacies: [],
            youTubeCategories: [],
            youTubeSelectedChannel: {
                name: '',
            },
            youTubeSelectedPrivacy: {
                label: ''
            },
            youTubeSelectedCategory: {
                title: ''
            },
            madeForKidsChecked: true,
            openYouTube: false,
            
            facebook: [],
            facebookMax: 1,
            facebookPages: [],
            facebookGroups: [],
            facebookSelectedGroup: {
                name: '',
            },
            facebookSelectedPage: {
                name: '',
            },
            openFacebook: false,
            instagram: [],
            instagramPages: [],
            instagramSelectedPage: {
                name: '',
            },
            instagramCaption: '',
            openInstagram: false,
            youTubeWaitFor: false,
            facebookWaitFor: false,
            instagramWaitFor: false,
            openAddAccountError: false,
            openPresetDialog: false,
            openPresetError: false,
            presetMessageError: '',
            selectedPreset: {},
            presetTitle: '',
            presetType: 'Texto',
            presetValue: '',
            presetTypeOptions: ['Texto', 'Tags']
        };
        
        this.handleSubmitAddYoutubeAccount = this.handleSubmitAddYoutubeAccount.bind(this);
        this.handleSubmitRemoveYoutubeAccount = this.handleSubmitRemoveYoutubeAccount.bind(this);
        this.handleSubmitAddFacebookAccount = this.handleSubmitAddFacebookAccount.bind(this);
        this.handleSubmitRemoveFacebookAccount = this.handleSubmitRemoveFacebookAccount.bind(this);
        this.handleYouTubeConfigDefaults = this.handleYouTubeConfigDefaults.bind(this);
        this.handleFacebookConfigDefaults = this.handleFacebookConfigDefaults.bind(this);
        this.handleInstagramConfigDefaults = this.handleInstagramConfigDefaults.bind(this);
        this.handleCloseYouTube = this.handleCloseYouTube.bind(this);
        this.handleCloseFacebook = this.handleCloseFacebook.bind(this);
        this.handleCloseInstagram = this.handleCloseInstagram.bind(this);
        this.handleCloseAddAccountError = this.handleCloseAddAccountError.bind(this);
        this.handleMadeForKidsChange = this.handleMadeForKidsChange.bind(this);
        this.handleChangeInstagramCaption = this.handleChangeInstagramCaption(this);
        this.resetCheckedInClips = this.resetCheckedInClips.bind(this);
        this.handleChangePresetTitle = this.handleChangePresetTitle.bind(this);
        this.handleChangePresetType = this.handleChangePresetType.bind(this);
        this.handleChangePresetValue = this.handleChangePresetValue.bind(this); 
        this.handleSubmitPreset = this.handleSubmitPreset.bind(this);
        this.handleDeletePreset = this.handleDeletePreset.bind(this);
        this.openEditProject = this.openEditProject.bind(this);
        this.handleClosePresetError = this.handleClosePresetError.bind(this);
    }
      
    
    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search)
        if (queryParams.has('error')) {
            queryParams.delete('error');
            
            this.setState({
                openAddAccountError: true,
            });
        }
        

        let email = localStorage.getItem('@crabber/username');
        let url = UsersApi.uri + '/' + email + '/accounts';
        const requestOptions = {
            url: url,
            method: 'GET',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        youTube: data.youtube,
                        facebook: data.facebook,
                        instagram: data.instagram,
                    });
                    localStorage.setItem('@crabber/accounts','');
                    localStorage.setItem('@crabber/accounts',JSON.stringify(data));

                    let email = localStorage.getItem('@crabber/username');

                    if (data.youtube.length > 0) {
                        let youTubeKey = data.youtube[0].key;
                        let url = YouTubeApi.uri + '/' + email + '/youtube/' + youTubeKey;
                        
                        const requestOptions = {
                            url: url,
                            method: 'GET',
                        };
                        axios(requestOptions)
                            .then(async response => {
                                const youTubeData = response.data;
                                if (response.status === 200) {
                                    
                                    data.youtube[0].youTubeChannels = youTubeData.option.channels;
                                    data.youtube[0].youTubePrivacies = youTubeData.option.privacies;
                                    data.youtube[0].youTubeSelectedChannel = youTubeData.option.channels.find(function( item ) {
                                        return youTubeData.selected.channel_id === item.id;
                                    });
                                    data.youtube[0].youTubeSelectedPrivacy= youTubeData.option.privacies.find(function( item ) {
                                        return youTubeData.selected.privacy_id === item.id;
                                    });

                                    localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                
                            });
                    }

                    if (data.facebook.length > 0) {
                        let facebookKey = this.state.facebook[0].key;
                        
                        let urlFacebook = FacebookApi.uri + '/' + email + '/facebook/' + facebookKey;
                
                        const requestOptionsFacebook = {
                            url: urlFacebook,
                            method: 'GET',
                        };
                        axios(requestOptionsFacebook)
                            .then(async responseFacebook => {
                                const facebookData = responseFacebook.data;
                                if (responseFacebook.status === 200) {
                                    
                                    data.facebook[0].facebookGroups = facebookData.option.groups;
                                    data.facebook[0].facebookPages = facebookData.option.pages;
                                    data.facebook[0].facebookSelectedGroup = facebookData.option.groups.find(function( item ) {
                                        return facebookData.selected.group_id === item.id;
                                    });
                                    data.facebook[0].facebookSelectedPage = facebookData.option.pages.find(function( item ) {
                                        return facebookData.selected.page_id === item.id;
                                    });
                                    
                                    localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                
                            });

                            let urlInstagram = FacebookApi.uri + '/' + email + '/instagram/' + facebookKey;
                    
                            const requestOptionsInstagram = {
                                url: urlInstagram,
                                method: 'GET',
                            };
                            axios(requestOptionsInstagram)
                                .then(async responseInstagram => {
                                    const instagramData = responseInstagram.data;
                                    if (responseInstagram.status === 200) {
                                        data.instagram[0].instagramPages = instagramData.option.pages;
                                        data.instagram[0].instagramSelectedPage = instagramData.option.pages.find(function( item ) {
                                            return instagramData.selected.page_id === item.id;
                                        });
                                        data.instagram[0].instagramCaption = instagramData.selected.caption;
                                        localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                    }
                                })
                                .catch(error => {
                                    console.error(error);
                                    
                                });
                            
                        
                    }
                        
                        
                }
                
            })
            .catch(error => {
                console.error(error);
                
            });
            
            axios({
                url: PresetsApi.uri + '?crabberKey=' + email, 
                method: 'GET',
            })
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        presets: data.map((preset) => {
                            if(preset.type == 'Tags'){
                                preset.value = preset.value.split(',');
                            }
                            return preset;
                        })
                    });
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

   
    componentWillUnmount() {
   
    }

    handleMadeForKidsChange(event) {
        this.setState({
            madeForKidsChecked: event.target.checked,
        });
    }

    handleCloseAddAccountError() {
        this.setState({
            openAddAccountError: false,
        });
    }

    handleClosePresetError(){
        this.setState({
            openPresetError: false,
        });
    }

    handleCloseFacebook() {
        let email = localStorage.getItem('@crabber/username');
        let facebookKey = this.state.facebook[0].key;
        let url = FacebookApi.uri + '/' + email + '/facebook/' + facebookKey + '/default';

        
        //if (!this.state.youTubeSelectedChannel || !this.state.youTubeSelectedCategory || !this.state.youTubeSelectedPrivacy) {
        //    return;
        //}

        const requestOptions = {
            url: url,
            method: 'PUT',
            data: {
                page_id: this.state.facebookSelectedPage?.id,
                group_id: this.state.facebookSelectedGroup?.id,
            }
        };
        axios(requestOptions)
            .then(async response => {
            })
            .catch(error => {
                console.error(error);
                
            });

        this.setState({
            openFacebook: false,
        });
    }

    handleCloseInstagram() {
        let email = localStorage.getItem('@crabber/username');
        let facebookKey = this.state.facebook[0].key;
        let url = FacebookApi.uri + '/' + email + '/instagram/' + facebookKey + '/default';
        
        //if (!this.state.youTubeSelectedChannel || !this.state.youTubeSelectedCategory || !this.state.youTubeSelectedPrivacy) {
        //    return;
        //}

        const requestOptions = {
            url: url,
            method: 'PUT',
            data: {
                page_id: this.state.instagramSelectedPage?.id,
                caption: this.state.instagramCaption,
            }
        };
        axios(requestOptions)
            .then(async response => {
            })
            .catch(error => {
                console.error(error);
                
            });

        this.setState({
            openInstagram: false,
        });
    }

    handleCloseYouTube() {
        let email = localStorage.getItem('@crabber/username');
        let youTubeKey = this.state.youTube[0].key;
        let url = YouTubeApi.uri + '/' + email + '/youtube/' + youTubeKey + '/default';
        
        if (!this.state.youTubeSelectedChannel || !this.state.youTubeSelectedCategory || !this.state.youTubeSelectedPrivacy) {
            return;
        }

        const requestOptions = {
            url: url,
            method: 'PUT',
            data: {
                channel_id: this.state.youTubeSelectedChannel.id,
                category_id: this.state.youTubeSelectedCategory.id,
                privacy_id: this.state.youTubeSelectedPrivacy.id,
                is_made_for_kids: this.state.madeForKidsChecked,
            }
        };
        axios(requestOptions)
            .then(async response => {
            })
            .catch(error => {
                console.error(error);
                
            });

        this.setState({
            openYouTube: false,
        });
    }

    handleInstagramConfigDefaults(event) {
        event.preventDefault();

        if (this.state.instagramWaitFor) {
            return;
        }

        this.setState({instagramWaitFor: true});
        let email = localStorage.getItem('@crabber/username');
        let facebookKey = this.state.facebook[0].key;
        let url = FacebookApi.uri + '/' + email + '/instagram/' + facebookKey;
        
        const requestOptions = {
            url: url,
            method: 'GET',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        instagramWaitFor: false,
                        instagramPages: data.option.pages,
                        instagramCaption: data.selected.caption,
                        instagramSelectedPage: data.option.pages.find(function( item ) {
                            return data.selected.page_id === item.id;
                        }),
                        openInstagram: true,
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({instagramWaitFor: false});
            });
    }

    handleFacebookConfigDefaults(event) {
        event.preventDefault();

        if (this.state.facebookWaitFor) {
            return;
        }

        this.setState({facebookWaitFor: true});
        let email = localStorage.getItem('@crabber/username');
        let facebookKey = this.state.facebook[0].key;
        let url = FacebookApi.uri + '/' + email + '/facebook/' + facebookKey;
        
        const requestOptions = {
            url: url,
            method: 'GET',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {

                    this.setState({
                        facebookWaitFor: false,
                        openFacebook: true,
                        facebookPages: data.option.pages,
                        facebookGroups: data.option.groups,
                        facebookSelectedPage: data.option.pages.find(function( item ) {
                            return data.selected.page_id === item.id;
                        }),
                        facebookSelectedGroup: data.option.groups.find(function( item ) {
                            return data.selected.group_id === item.id;
                        }),
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({facebookWaitFor: false});
            });
        }
        
        
    handleYouTubeConfigDefaults(event) {
        event.preventDefault();

        if (this.state.youTubeWaitFor) {
            return;
        }

        this.setState({youTubeWaitFor: true});
        let email = localStorage.getItem('@crabber/username');
        let youTubeKey = this.state.youTube[0].key;
        let url = YouTubeApi.uri + '/' + email + '/youtube/' + youTubeKey;

        const requestOptions = {
            url: url,
            method: 'GET',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        youTubeWaitFor: false,
                        openYouTube: true,
                        youTubeChannels: data.option.channels,
                        youTubeCategories: data.option.categories.filter(function( item ) {
                            return item.assignable;
                        }),
                        youTubePrivacies: data.option.privacies,
                        youTubeSelectedChannel: data.option.channels.find(function( item ) {
                            return data.selected.channel_id === item.id;
                        }),
                        youTubeSelectedCategory: data.option.categories.find(function( item ) {
                            return data.selected.category_id === item.id;
                        }),
                        youTubeSelectedPrivacy: data.option.privacies.find(function( item ) {
                            return data.selected.privacy_id === item.id;
                        }),
                        madeForKidsChecked: data.selected.is_made_for_kids,
                    });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({youTubeWaitFor: false});

            });
        

    }
   
    handleSubmitAddYoutubeAccount (event) {
        window.open(YouTubeOauth2Api.uri, '_self', 'noopener,noreferrer');
    }

    handleSubmitAddFacebookAccount (event) {
        window.open(FacebookOauth2Api.uri, '_self', 'noopener,noreferrer');
    }

    handleSubmitRemoveYoutubeAccount(event) {
        let email = localStorage.getItem('@crabber/username');
        let youTubeKey = this.state.youTube[0].key;

        let url = UsersApi.uri + '/' + email + '/youtube/' + youTubeKey;
        const requestOptions = {
            url: url,
            method: 'DELETE',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        youTube: data.youtube,
                    });

                    this.resetCheckedInClips();
                }
                
            })
            .catch(error => {
                console.error(error);
                
            });
    }

    handleSubmitRemoveFacebookAccount(event) {
        let email = localStorage.getItem('@crabber/username');
        let facebookKey = this.state.facebook[0].key;
        
        let url = UsersApi.uri + '/' + email + '/facebook/' + facebookKey;
        const requestOptions = {
            url: url,
            method: 'DELETE',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        facebook: data.facebook,
                    });

                    this.resetCheckedInClips();
                }
                
            })
            .catch(error => {
                console.error(error);
                
            });
    }

    handleChangeInstagramCaption(event) {
        if (event.target) {
            this.setState({
                instagramCaption: event.target.value,
            });
        }
    }

    handleChangePresetTitle(event) {
        if (event.target) {
            this.setState({
                presetTitle: event.target.value,
            });
        }
    }
    
    handleChangePresetType(event, newValue) {
        if (newValue) {
            this.setState({
                presetType: newValue,
                presetValue: ''
            });
        }
    }

    handleChangePresetValue(event, newValue) {
        this.setState({
            presetValue: newValue ? newValue : event.target.value
        });
    }

    handleSubmitPreset(){
        let email = localStorage.getItem('@crabber/username');

        if(!this.state.presetValue || !this.state.presetTitle){
            this.setState({
                openPresetDialog: false,
                openPresetError: true,
                presetMessageError: !this.state.presetTitle ? 'Nome do preset não preenchido!' : 'Campo de texto/tags não preenchido!',
            });

            return;
        }

        const requestBody = {
            title: this.state.presetTitle,
            type: this.state.presetType,
            value: this.state.presetType == 'Tags' ? this.state.presetValue.join(',') : this.state.presetValue,
            crabberKey: email,
        };

        const requestOptions = {
            url: PresetsApi.uri,
            method: 'POST',
            data: requestBody
        };

        if(this.state.selectedPreset?.id){
            requestOptions.url += `/${this.state.selectedPreset.id}`;
            requestOptions.method = 'PATCH'
        }

        axios(requestOptions)
            .then(async response => {
                if (response.status === 200 || response.status === 201) {
                    this.setState({
                        openPresetDialog: false
                    });

                    let newPreset = response.data;
                    if(newPreset.type == 'Tags'){
                        newPreset.value = newPreset.value.split(',');
                    }

                    const presetExists = this.state.presets.find(preset => preset.id === newPreset.id);
                    if(presetExists){
                        this.setState({ 
                            presets: this.state.presets.map((preset) => preset.id === newPreset.id ? newPreset : preset)
                        })
                    }else{
                        this.setState({ 
                            presets: this.state.presets.concat([newPreset])
                        })
                    }
                }
            })
            .catch(error => {
                if(error.response.data.message){
                    this.setState({
                        openPresetDialog: false,
                        openPresetError: true,
                        presetMessageError: error.response.data.message
                    });
                }
            }).finally(() => {
                this.setState({
                    presetTitle: '',
                    presetType: 'Texto',
                    presetValue: '',
                });
            });
    }

    handleDeletePreset(presetId){
        this.setState({ 
            presets: this.state.presets.filter((p) => p.id != presetId)
        })
    }
    
    openEditProject(preset){
        this.setState({
            presetTitle: preset.title,
            presetType: preset.type,
            presetValue: preset.value,
            selectedPreset: preset,
            openPresetDialog: true,
        });
    }

    resetCheckedInClips() {
        let clips = JSON.parse(localStorage.getItem('@crabber/Clips.data'));
        for (let i=0; i<clips.length; i++) {
            clips[i].youTubeChecked = false;
            clips[i].facebookChecked = false;
            clips[i].instagramChecked = false;
        }
        localStorage.setItem('@crabber/Clips.data', JSON.stringify(clips));
    }

    render() {
        
        return (
            <div className='social-grid-container' >
                <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="center" spacing={2}>
                    
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            Redes Sociais
                        </Typography>
                    </Grid>
                    
                    <div className="social-block">
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    YouTube
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item xs={3} sm={2}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    autoFocus
                                    fullWidth
                                    disableRipple
                                    disabled={this.state.youTube.length === this.state.youTubeMax}
                                    onClick={this.handleSubmitAddYoutubeAccount}>
                                    Adicionar Conta
                                </Button>           
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    autoFocus
                                    fullWidth
                                    disableRipple
                                    disabled={this.state.youTube.length !== this.state.youTubeMax}
                                    onClick={this.handleSubmitRemoveYoutubeAccount}>
                                    Remover Conta
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={8}/>
                            <br />
                        
                            
                            <Grid item xs={12}><Divider /></Grid>
                                <Grid item xs={9}>
                                    <Typography variant="body1" display="inline">
                                        <b>Nome da conta: </b> {this.state.youTube.length === this.state.youTubeMax ? this.state.youTube[0].key : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    {this.state.youTube.length === this.state.youTubeMax &&
                                    
                                        
                                        <Typography variant="body1" align="right">
                                            <Link href="" onClick={this.handleYouTubeConfigDefaults} className="account-config-link">
                                            {this.state.youTubeWaitFor && <CircularProgress style={{
                                                                    color: 'white',
                                                                    position: 'absolute',
                                                                    width: 20,
                                                                    height: 20,
                                                                    marginLeft: -30
                                                                }}/>}Configurar padrões YouTube
                                                
                                            </Link>
                                        </Typography>
                                            
                                    
                                    }
                                    </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            
                        </Grid>
                        <br />
                    </div>
                    
                    <br />
                    <br />
                    

                    <div className="social-block">
                        <br />
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Facebook e Instagram
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item xs={4} sm={2}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    autoFocus
                                    fullWidth
                                    disableRipple
                                    disabled={this.state.facebook.length === this.state.facebookMax}
                                    onClick={this.handleSubmitAddFacebookAccount}>
                                    Adicionar Conta
                                </Button>           
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    autoFocus
                                    fullWidth
                                    disableRipple
                                    disabled={this.state.facebook.length !== this.state.facebookMax}
                                    onClick={this.handleSubmitRemoveFacebookAccount}>
                                    Remover Conta
                                </Button>
                            </Grid>
                            <Grid item xs={8} />
                            <br />
                            
                            <Grid item xs={12}><Divider variant='fullWidth' /></Grid>
                            
                                <Grid item xs={9}>
                                    <Typography variant="body1" display="inline">
                                        <b>Nome da conta: </b> {this.state.facebook.length === this.state.facebookMax ? this.state.facebook[0].key : ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    {this.state.facebook.length === this.state.facebookMax &&
                                    <Typography variant="body1" align="right">
                                        <Link href="" onClick={this.handleFacebookConfigDefaults} className="account-config-link">
                                        {this.state.facebookWaitFor && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 20,
                                                                height: 20,
                                                                marginLeft: -30
                                                            }}/>}Configurar padrões Facebook
                                            
                                        </Link>
                                    </Typography>
                                    
                                    
                                    }
                                </Grid>
                                <Grid item xs={9}></Grid>
                                <Grid item xs={3}>
                                    {this.state.facebook.length === this.state.facebookMax &&
                                    <Typography variant="body1" align="right">
                                        <Link href="" onClick={this.handleInstagramConfigDefaults} className="account-config-link">
                                        {this.state.instagramWaitFor && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 20,
                                                                height: 20,
                                                                marginLeft: -30
                                                            }}/>}Configurar padrões Instagram
                                            
                                        </Link>
                                    </Typography>
                                    
                                    }
                                </Grid>
                            
                            <Grid item xs={12}><Divider /></Grid>
                            <br />
                            <br />
                        </Grid>
                    </div>
                    <div className="social-block">
                        <br />
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    Presets
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item xs={4} sm={2}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                    autoFocus
                                    fullWidth
                                    disableRipple
                                    disabled={this.state.presets.length >= 10}
                                    onClick={() => { 
                                        this.setState({
                                            openPresetDialog: true,
                                        }); 
                                    }}>
                                    Adicionar preset
                                </Button>           
                            </Grid>
                            <Grid item xs={4} sm={2}>
                            </Grid>
                            <Grid item xs={8} />
                            <br />
                            
                            <Grid item xs={12}><Divider variant='fullWidth' /></Grid>     

                            <Grid container>                    
                                {this.state.presets.map((preset) => (
                                    <Grid item xs={3}>
                                        <PresetCard 
                                            preset={preset}
                                            handleDeletePreset={this.handleDeletePreset} 
                                            openEditProject={this.openEditProject}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            
                            <Grid item xs={12}><Divider /></Grid>
                            <br />
                            <br />
                        </Grid>
                    </div>
                </Grid>
                <div>
                    <Dialog
                        open={this.state.openYouTube}
                        onClose={this.handleCloseYouTube}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-yt"
                        aria-describedby="alert-dialog-description-yt"
                        >
                            <DialogTitle>
                                Configurar padroẽs YouTube
                            </DialogTitle>
                            <DialogContent>
                                
                                <Autocomplete
                                    id="combo-box-channel"
                                    autoSelect
                                    disableClearable
                                    value={this.state.youTubeSelectedChannel}
                                    options={this.state.youTubeChannels}
                                    defaultValue={{}}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        this.setState({youTubeSelectedChannel: newValue});
                                    }}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>
                                                <ComboBoxAvatar alt="option.name" src={option.thumbnail} />
                                            </span>
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Canal" variant="outlined" />)}
                                />
                                <br/>
                                <Autocomplete
                                    id="combo-box-privacy"
                                    autoSelect
                                    disableClearable
                                    value={this.state.youTubeSelectedPrivacy}
                                    options={this.state.youTubePrivacies}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        this.setState({youTubeSelectedPrivacy: newValue});
                                    }}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.label}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Privacidade" variant="outlined" />)}
                                />
                                <br/>
                                <Autocomplete
                                    id="combo-box-category"
                                    autoSelect
                                    disableClearable
                                    value={this.state.youTubeSelectedCategory}
                                    onChange={(event, newValue) => {
                                        this.setState({youTubeSelectedCategory: newValue});
                                    }}
                                    options={this.state.youTubeCategories}
                                    getOptionLabel={(option) =>option.title}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.title}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Categoria" variant="outlined" />)}
                                />
                                <br/>
                                <Checkbox 
                                    checked={this.state.madeForKidsChecked}
                                    onChange={this.handleMadeForKidsChange}
                                />Conteudo para crianças
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleCloseYouTube} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        open={this.state.openFacebook}
                        onClose={this.handleCloseFacebook}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-fb"
                        aria-describedby="alert-dialog-description-fb"
                        >
                            <DialogTitle>
                                Configurar padroẽs Facebook
                            </DialogTitle>
                            <DialogContent>
                                
                                <Autocomplete
                                    id="combo-box-page-fb"
                                    disabled={this.state.facebookPages.length === 0}
                                    autoSelect
                                    disableClearable
                                    value={this.state.facebookSelectedPage}
                                    options={this.state.facebookPages}
                                    defaultValue={{}}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        this.setState({facebookSelectedPage: newValue});
                                    }}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Página" variant="outlined" />)}
                                />
                                
                                
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleCloseFacebook} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openInstagram}
                        onClose={this.handleCloseInstagram}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-insta"
                        aria-describedby="alert-dialog-description-insta"
                        >
                            <DialogTitle>
                                Configurar padroẽs Instagram
                            </DialogTitle>
                            <DialogContent>
                                
                                <Autocomplete
                                    id="combo-box-page-insta"
                                    disabled={this.state.instagramPages.length === 0}
                                    autoSelect
                                    disableClearable
                                    value={this.state.instagramSelectedPage}
                                    options={this.state.instagramPages}
                                    defaultValue={{}}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        this.setState({instagramSelectedPage: newValue});
                                    }}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Página" variant="outlined" />)}
                                />
                                <br />    
                                <TextField
                                        id="text-field-caption-insta"
                                        variant="outlined" 
                                        color="primary" 
                                        margin="dense"
                                        label="Legenda do vídeo" 
                                        fullWidth
                                        value={this.state.instagramCaption}
                                        onChange={(e) => {
                                            this.setState({
                                                instagramCaption: e.target.value,
                                            });
                                        }}
                                        multiline
                                        rows={10}
                                        helperText="Maximo 30 hastags e 20 menções"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleCloseInstagram} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        open={this.state.openAddAccountError}
                        onClose={this.handleCloseAddAccountError}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-error"
                        aria-describedby="alert-dialog-description-error"
                        >
                            <DialogContent>
                                <DialogContentText>
                                    Nenhuma conta foi adicionada. Tentar novamente.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleCloseAddAccountError} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        open={this.state.openPresetError}
                        onClose={this.handleClosePresetError}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-error"
                        aria-describedby="alert-dialog-description-error"
                        >
                            <DialogContent>
                                <DialogContentText>
                                    {this.state.presetMessageError}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleClosePresetError} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        open={this.state.openPresetDialog}
                        onClose={this.handleClosePresetDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-yt"
                        aria-describedby="alert-dialog-description-yt"
                        fullWidth
                        maxWidth="sm"
                        >
                            <DialogTitle>
                                {this.state.selectedPreset.title ? `Editar ${this.state.selectedPreset.title}` : 'Novo Preset'}
                            </DialogTitle>
                            <DialogContent>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined" 
                                        margin="dense" 
                                        placeholder=""
                                        label="Nome do preset" 
                                        fullWidth
                                        autoFocus
                                        value={this.state.presetTitle}
                                        onChange={(e) => { this.handleChangePresetTitle(e) }}
                                        inputProps={{ autoFocus: true }}
                                        InputLabelProps={{ shrink: true }} 
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    <Autocomplete
                                        value={this.state.presetType}
                                        options={this.state.presetTypeOptions}
                                        onChange={this.handleChangePresetType}
                                        renderInput={(option) => (<TextField {...option} label="Tipo" variant="outlined" />)}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    {(this.state.presetType) == 'Texto' && <TextField
                                        variant="outlined" 
                                        color="primary" 
                                        margin="dense" 
                                        placeholder=""
                                        label="Insira o texto para redes sociais" 
                                        fullWidth
                                        value={this.state.presetValue}
                                        onChange={(e) => { this.handleChangePresetValue(e)  }}
                                        multiline={true}
                                        rows="5"
                                        InputLabelProps={{ shrink: true }}
                                    />}
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    {(this.state.presetType) == 'Tags' && <Autocomplete
                                        multiple
                                        id="tags-filled"
                                        freeSolo
                                        fullWidth
                                        value={this.state.presetValue}
                                        options={[]}
                                        filterSelectedOptions={true}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Insira as palavras chaves" placeholder="" InputLabelProps={{ shrink: true }} />
                                        )}
                                        
                                        onChange={(event, newValue) => {
                                            this.handleChangePresetValue(event, newValue);
                                        }}
                                    />}
                                </Grid>              
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleSubmitPreset} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    Salvar
                                </Button>
                            </DialogActions>
                    </Dialog>
                </div>
            </div>

        )
    }
}

function Social(props) {
    const location = useLocation();
    return (
        <div className='social-container'>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Sidebar />
                <SocialContainer location={location}/>
            </ThemeProvider>
        </div>
    )

}

export default Social