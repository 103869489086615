import React from 'react'

import PlayIcon from 'static/images/icons/play_branco.svg'
import PauseIcon from 'static/images/icons/pause_branco.svg'
import VolumeOnIcon from 'static/images/icons/volume_on_branco.svg'
import VolumeOffIcon from 'static/images/icons/volume_off_branco.svg'
import IconButton from '@material-ui/core/IconButton';
import { grey } from '@material-ui/core/colors';
import {StopIngestApi} from 'config/config'
import { red } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles} from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Player from './Player';
import axios from 'interceptors';

import './PlayerController.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const PlayerButton = withStyles((theme) => ({
    root: {
        padding: 0,
        width: 58,
        height: 58,
        marginLeft: 10,
        marginBottom: 10,
        marginTop: 10,
        bottom: 0,
        left: 0,
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900] + '99',
        '&:hover': {
            backgroundColor: grey[900],
        },
    },
  }))(IconButton);

  const LiveTiming = withStyles((theme) => ({
    root: {
        fontSize: "1.5vmin",
        color: "#fff",
        marginLeft: 5,
        fontFamily: "GalanoGrotesqueBold",
        cursor: "default",
        backgroundColor: grey[900] + '90',
        '&:hover': {
            backgroundColor: grey[900]+ '90',
        },
        
    },
  }))(Button);

  const StopButton = withStyles((theme) => ({
    root: {
        fontSize: "1.5vmin",
        color: "#fff",
        fontFamily: "GalanoGrotesqueBold",
        marginLeft: 5,
        backgroundColor: grey[900] + '90',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: grey[900] + '99',
        },
    },
  }))(Button);

  const SessionClosedButton = withStyles((theme) => ({
    root: {
        fontSize: "1.5vmin",
        color: "#fff",
        fontFamily: "GalanoGrotesqueBold",
        marginLeft: 5,
        backgroundColor: grey[900] + '90',
        cursor: "default",
        '&:hover': {
            backgroundColor: grey[900] + '99',
        },
    },
  }))(Button);

  const LiveButtonOn = withStyles((theme) => ({
    root: {
        fontSize: "1.5vmin",
        color: "#fff",
        fontFamily: "GalanoGrotesqueBold",
        cursor: "default",
        backgroundColor: red[900] + '90',
        '&:hover': {
            backgroundColor: red[900]+ '90',
        },
    },
  }))(Button);

  const LiveButtonOff = withStyles((theme) => ({
    root: {
        fontSize: "1.5vmin",
        color: "#fff",
        fontFamily: "GalanoGrotesqueBold",
        backgroundColor: red[900] + '30',
        cursor: "pointer",
        '&:hover': {
            backgroundColor: red[900] + '50',
        },
    },
  }))(Button);

class PlayerControllerContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            play: false,
            mute: true,
            live: true,
            liveAction: true,
            timeRef: '00:00:00',
            open: false,
            openResponse: false,
            backToLiveDisabled: false,
            redirect: null,
            response: 'Sessão encerrada com sucesso!',
        };

        
        this.handleTogglePlay = this.handleTogglePlay.bind(this);
        this.handleToggleMute = this.handleToggleMute.bind(this);
        this.handleToggleLive = this.handleToggleLive.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleStopIngest = this.handleStopIngest.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseResponse = this.handleCloseResponse.bind(this);
        this.notifyUpdateTimeRef = this.notifyUpdateTimeRef.bind(this);
        
        window.playerControllerComponent = this;
    }

    notifyUpdateTimeRef(timeRef) {
        this.setState({timeRef: timeRef});
    }

    handleCloseResponse() {
        //TODO fazer o set localStorage.getItem('@crabber/Player.liveStatus')
        this.setState({
            openResponse: false,
        });
        clearInterval(this.state.intervalId);
        this.setState({ 
            //redirect: "/studio",
            intervalId: null, 
        });
        
        if (!Player.hls) {
            return;
        }

        if (!Player.hls.levels) {
            return;
        }

        //let duration = parseInt(Player.hls.levels[0].details.totalduration);
        //let currentTime = duration - Math.min(300, duration);

        //Player.hls.media.currentTime = currentTime;
        //Player.hls.media.play();
    }

    handleStopIngest() {
        if(Player.hls && Player.hls.streamController && Player.hls.streamController.fragCurrent) {
            //console.log(Player.hls.streamController.fragCurrent.endPTS);
            //console.log(Player.hls.streamController.fragCurrent);
            //console.log(Player.hls.streamController);
        }
        
        // TODO enviar duraçaõ (quantos tempo foi usado)
        // ou ultimo nome de segmento carregado
        const requestOptions = {
            url: StopIngestApi.uri, 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data:   {
                        id: JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber')).id,
                    }
        };
        axios(requestOptions)
            .then(async response => {
                if (response.status !== 201) {
                    // get error message from body or default to response status
                    const data = response.data;
                    const error = (data && data) || response.status;
                    this.setState({
                        open: false,
                        response: error,
                        openResponse: true,
                    });
                    
                    return Promise.reject(error);
                } else {
                    this.setState({
                        open: false,
                        openResponse: true,
                        redirect: '/',
                    })
                    
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    handleOpenDialog() {
        this.setState({
            open: true,
        })
    }

    handleClose() {
        this.setState({
            open: false,
        })
    }

    handleToggleLive() {
        window.playerComponent.setState({buffer: true});

        this.setState({
            liveAction: true,
            live: true,
            backToLiveDisabled: true,
        }, () => {
            Player.live = true;
            window.controllerComponent.notifyChangeLiveStatus(true);
            
        });
        
        
        const timer = setTimeout((obj) => {    
            
            Player.hls.media.currentTime = isNaN(Player.hls.media.duration) ? -1 : Player.hls.media.duration - 12;
            let playPromise = Player.hls.media.play();
            playPromise.then(function() {
                obj.setState({
                    liveAction: true,
                    backToLiveDisabled: false,
                }, () => {
                    clearTimeout(timer);
                    Player.live = true;
                    
                    window.controllerComponent.notifyChangeLiveStatus(true);
                });
            }).catch(function(error) {
                console.error(error);
                obj.setState({
                    backToLiveDisabled: false,
                });
            });
            
        }, 1000, this);
        
        
        
        
    }

    handleTogglePlay(event) {
        this.state.play ? Player.hls.media.play() : Player.hls.media.pause();
    }

    handleToggleMute(event) {
        Player.hls.media.muted = !this.state.mute;
        this.setState({
            mute: !this.state.mute,
        })
    }

    componentDidMount() {

        const obj = this;

        Player.hls.media.addEventListener("pause", function(event) {
            obj.setState({
                play: true,
            });
        });
        
        Player.hls.media.addEventListener("play", function(event) { 
            obj.setState({
                play: false,
            });
        });
        
        Player.live = true;
        window.controllerComponent.notifyChangeLiveStatus(true);
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div>
                <div className="player-control-controls">
                    <div className="player-control-video-sub-controls">
                        <PlayerButton onClick={this.handleTogglePlay}>
                            {this.state.play &&  <Tooltip title="Tocar" arrow placement="top"><img src={PlayIcon} alt='play' className='player-control-player-icon'/></Tooltip>}
                            {!this.state.play &&  <Tooltip title="Pausar" arrow placement="top"><img src={PauseIcon} alt='pause' className='player-control-player-icon'/></Tooltip>}
                        </PlayerButton>
                        <PlayerButton onClick={this.handleToggleMute}>
                            {this.state.mute && <Tooltip title="Ligar volume" arrow placement="top"><img src={VolumeOffIcon} alt='volume off' className='player-control-player-icon'/></Tooltip>}
                            {!this.state.mute && <Tooltip title="Desligar volume" arrow placement="top"><img src={VolumeOnIcon} alt='volume on' className='player-control-player-icon'/></Tooltip>}
                        </PlayerButton>
                        
                    </div>
                    <div className="player-control-video-sub-controls">
                        
                        {(localStorage.getItem('@crabber/Player.liveStatus') !== 'Sessão encerrada' && !Player.live && Player.hls &&  Player.hls.liveSyncPosition) ? (<LiveButtonOff variant="contained" color="primary" className='player-control-player-live-icon' onClick={this.handleToggleLive} disabled={this.state.backToLiveDisabled}>VO<u>L</u>TAR AO VIVO</LiveButtonOff>) : (<div></div>)}
                        {(localStorage.getItem('@crabber/Player.liveStatus') !== 'Sessão encerrada' && Player.live && Player.hls && Player.hls.liveSyncPosition) ? (<LiveButtonOn variant="contained" color="primary" disableRipple className='player-control-player-live-icon'>AO VIVO</LiveButtonOn>) : (<div></div>)}
                        <Tooltip title="Horário gravação" arrow placement="top"><LiveTiming variant="contained" color="primary" className='player-control-player-live-icon'>{this.state.timeRef}</LiveTiming></Tooltip>
                        {(localStorage.getItem('@crabber/Player.liveStatus') !== 'Sessão encerrada') ?
                            (
                                <StopButton 
                                    onClick= { this.handleOpenDialog }
                                    variant="contained" 
                                    color="primary"
                                    className='player-control-player-live-icon'
                                    >
                                    <u>E</u>ncerrar Sessão
                                </StopButton>
                            ) : (
                                <SessionClosedButton 
                                    variant="contained" 
                                    color="primary"
                                    className='player-control-player-live-icon'
                                    >
                                    Sessão encerrada
                                </SessionClosedButton>
                            )
                        }
                    </div>
                    
                    
                </div>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Deseja parar a gravação?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Você perderá todos os clipes que não forem acervados. Deseja continuar?"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Cancelar
                            </Button>
                            <Button onClick={this.handleStopIngest} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Sim
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openResponse}
                        onClose={this.handleCloseResponse}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Retorno"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.response}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseResponse} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

function PlayerController() {
    
    return (   
        <PlayerControllerContainer />
    )
}
  

export default PlayerController;