import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Redirect } from 'react-router-dom';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {UserUsageApi} from 'config/config';
import {StopIngestApi} from 'config/config'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Utils from 'functions/Utils'
import axios from 'interceptors';
import './SessionHistory.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function extimateToExpiring(to) {
    let diff = Math.abs(new Date() - new Date(to));
    return Math.floor((diff/1000)/60);
}

function GetSessionCard(props) {
    const session = props.session;
    return <div>
        <Card>
            <CardContent>
                <Typography variant="body1" gutterBottom>
                {session.session_end ? (<span></span>) : (<FiberManualRecordIcon className="blink-history"/>)} {session.session_title.split("|")[2]}
                </Typography>
                {session.session_end ? (
                    <div>    
                        <Typography variant="subtitle1" gutterBottom>
                            Sessão encerrada, começou o dia {Utils.formattedFromDate(session.session_begin)}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Acesso disponível por {Utils.humanMinutes(extimateToExpiring(session.session_expiring))}
                        </Typography>
                    </div>
                ) : (
                    <div>
                        
                        <Typography variant="subtitle1" gutterBottom>
                            Atualmente ao vivo, começou o dia {Utils.formattedFromDate(session.session_begin)}
                        </Typography>
                    </div>
                )}   
            </CardContent>
            <CardActions>
                <Button 
                    variant="contained" 
                    color="primary" 
                    type="submit"
                    autoFocus
                    disableRipple
                    onClick={(e) => {
                            e.preventDefault();
                            window.sessionHistoryComponent.handleBackToIngest(session);
                        }
                    }>
                    {session.session_end ? 'Ir para sessão' : 'Voltar para sessão'}
                </Button>
                {!session.session_end &&
                <Button 
                    variant="contained" 
                    color="primary" 
                    type="submit"
                    autoFocus
                    disableRipple
                    onClick={(e) => {
                            e.preventDefault();
                            window.sessionHistoryComponent.handleOpenDialog();
                        }
                    }>
                    ENCERRAR A SESSÃO
                </Button>
                }
            </CardActions>
        </Card>
    </div>;
}

function GetSessionCards(props) { 
    const sessions = props.sessions;

    if (!sessions || sessions.length === 0) {
        return <div>
            <Card>
                <CardContent>
                    <Typography variant="body1" gutterBottom>
                        Nenhuma sessão disponivel
                    </Typography>   
                </CardContent>
        </Card>
        </div>;
    }

    const data = sessions.map((session) => (
        <Grid key={session.id} item xs={12}>
            <GetSessionCard session={session}/>
        </Grid>
    ));

    return <div>
                <Grid
                    ref={window.sessionHistoryComponent.state.sessionsRef}
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="stretch"
                    spacing={1}>
                    {data}
                </Grid>            
            </div>; 
}



class SessionHistoryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: null,
            sessions: [],
            redirect: null,
            open: false,
            sessionsRef: React.createRef(),
        };
        
        
        this.timer = this.timer.bind(this);
        this.handleBackToIngest = this.handleBackToIngest.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleStopIngest = this.handleStopIngest.bind(this);

        window.sessionHistoryComponent = this;
    }

    handleStopIngest() {
        const requestOptions = {
            url: StopIngestApi.uri, 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data:   {
                        id: JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber')).id,
                    }
        };
        axios(requestOptions)
            .then(async response => {
                if (response.status !== 201) {
                    // get error message from body or default to response status
                    const data = response.data;
                    const error = (data && data) || response.status;
                    this.setState({
                        open: false,
                    });
                    
                    return Promise.reject(error);
                } else {
                    this.setState({
                        open: false,
                        redirect: '/',
                    })
                    
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    handleOpenDialog() {
        this.setState({
            open: true,
        })
    }

    handleClose() {
        this.setState({
            open: false,
        })
    }

    handleBackToIngest(session) {
        console.log(session);
        let subscriber = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber'));
        let ingestData = JSON.parse(localStorage.getItem('@crabber/Ingest.ingestData'));
        
        if (!subscriber) {
            subscriber = {}
        }

        if (!ingestData) {
            ingestData = {}
        }

        subscriber.id = session.id;
        subscriber.hls = session.hls;
        subscriber.self = session.self;
        subscriber.session_begin = session.session_begin;
        subscriber.session_title = session.session_title;
        subscriber.stream_key = session.stream_key;

        ingestData.uploader = session.uploader;
        ingestData.thumbnail = session.thumbnail;
        ingestData.title = session.session_title.split('|')[2];

        localStorage.setItem('@crabber/Ingest.subscriber', JSON.stringify(subscriber)); 
        localStorage.setItem('@crabber/Ingest.ingestData', JSON.stringify(ingestData)); 
        localStorage.setItem('@crabber/Clips.data', '[]'); 

        this.setState({ redirect: "/studio" });
    }

    componentDidMount() {
        var intervalId = setInterval(this.timer, 60000);
        this.setState({
            intervalId: intervalId,
            sessions: [],
        });

        let id =localStorage.getItem('@crabber/username');
        axios({
            url: UserUsageApi.uri + '/' + id + `/sessions`, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                console.log(response.data);
                this.setState({
                    sessions: response.data,
                });
                
                this.state.sessionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.setState({
            intervalId: null,
        });
    }

    
    timer() {   
        let sessionLength = this.state.sessions.length;
        let id =localStorage.getItem('@crabber/username');
        axios({
            url: UserUsageApi.uri + '/' + id + `/sessions`, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                if (sessionLength !== response.data.length) {
                    this.setState({
                        sessions: response.data,
                    });
                   
                } 
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div>
                <GetSessionCards sessions={this.state.sessions}/>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Deseja parar a gravação?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Você perderá todos os clipes que não forem acervados. Deseja continuar?"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Cancelar
                            </Button>
                            <Button onClick={this.handleStopIngest} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Sim
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}


function SessionHistory(props) {
    return (
        <div className='session-history-container'>
            <SessionHistoryContainer />
        </div>
    )
}

export default SessionHistory