import { AuthRefreshApi } from './config';

export const doVerifyToken = async () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:   JSON.stringify({ 
                    username: localStorage.getItem('@crabber/username'),
                    token: localStorage.getItem('@crabber/token'),
                    refresh: localStorage.getItem('@crabber/refresh'),
                }),    
    };

    try {
        const res = await fetch(AuthRefreshApi.uri, requestOptions);

        if (res.status === 401 || res.status === 403) {
            window.location.href = '/login';
            return;
        }
        const data = await res.json();
        localStorage.setItem('@crabber/token', data.token);
        localStorage.setItem('@crabber/refresh', data.refresh);
    } catch (e) {
        console.warn('Something was wrong during call refresh token endpoint');
    }
}