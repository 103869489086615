import axios from 'axios';
import history from './history';


axios.interceptors.request.use(function (config) {
  
    if (config.url.includes("/auth") || config.url.includes("/crab-user/users")) {
        return config;    
    }

    config.headers.Authorization =  'Bearer ' + localStorage.getItem('@crabber/token');
    
    return config;
    
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if ( (error.response.status === 401 || error.response.status === 403) && !error.response.config.url.include('/auth/token') ) {
        console.error(error.response);
        history.push("/login");
    }
    

    return Promise.reject(error);
});

export default axios