const crab = {
    proto: 'https',
    host: 'app.crabber.com.br',
    port: 443,
}

const context = {
    command: '/api/crab-command',
    user: '/api/crab-user',
    youtube: '/api/crab-youtube',
    facebook: '/api/crab-facebook',
    onlineStorage: '/api/crab-online-storage',
    usage: '/api/crab-usage',
    auth: '/api/auth',
}

const thumbs = {
    proto: 'https',
    host: 'app.crabber.com.br',
    port: 443,
    context: '/thumbs',
}

const paths = {
    commands: '/commands',
    youtubedl: '/youtube-dl',
    rtmp: '/rtmp',
    rtmpPull: '/rtmp-pull',
    rtmpPush: '/rtmp-push',
    info: '/info',
    start: '/start_ingest',
    stop: '/stop_ingest',
    thumbs: '/thumbs',
    subscribers: '/subscribers',
    token: '/token',
    refresh: '/refresh',
    users: '/users',
    presets: '/presets',
    userSpaces: '/user_spaces',
    videos: '/videos',
    password: '/password',
    enable: '/enable',
    googleOauth2: '/oauth2/authorization/google',
    facebookOauth2: '/oauth2/authorization/facebook',
};

export const YouTubedlInfoApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.commands + paths.youtubedl + paths.info,
}

export const StartRtmpIngestApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.commands + paths.rtmp + paths.start,
}

export const RtmpThumbsApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.commands + paths.rtmp + paths.thumbs,
}

export const RtmpPullInfoApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.commands + paths.rtmpPull + paths.info,
}

export const RtmpPushApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.commands + paths.rtmpPush,
}

export const RtmpPushInfoApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.commands + paths.rtmpPush + paths.info,
}

export const StartIngestApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.commands + paths.youtubedl + paths.start,
}

export const StopIngestApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.commands + paths.youtubedl + paths.stop,
}

export const FeedingSubscriberApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.command + paths.subscribers + '/',
}

export const AuthTokenApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.auth + paths.token,
}

export const AuthRefreshApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.auth + paths.refresh,
}

export const UsersApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.user + paths.users,
}

export const PresetsApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.user + paths.presets,
}

export const YouTubeOauth2Api = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.youtube + paths.googleOauth2,
}

export const FacebookOauth2Api = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.facebook + paths.facebookOauth2,
}

export const YouTubeApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.youtube + paths.users,
}

export const FacebookApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.facebook + paths.users,
}

export const UserSpacesApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.onlineStorage + paths.userSpaces,
}

export const UserUsageApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.usage + paths.users,
}

export const VideosApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.onlineStorage + paths.videos,
}

export const ChangePasswordApi = {
    uri : crab.proto + '://' + crab.host + ':' + crab.port + context.auth,
}

export const ThumbsBase = {
    uri : thumbs.proto + '://' + thumbs.host + ':' + thumbs.port + thumbs.context,
}

export const crabberVideoEditingURI = "https://editor.crabber.com.br";

