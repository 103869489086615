import React from 'react';
import { theme } from 'theme.js';
import { Redirect } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { UsersApi } from 'config/config'

import axios from 'interceptors';


class YouTubeAccountContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            youtubeKey: '',
            redirect: null,
        };

        
    }

    
    componentDidMount() {
        this.setState({
            youtubeKey: this.props.youtubeKey,
        });


        let email = localStorage.getItem('@crabber/username');
        let url = UsersApi.uri + '/' + email + '/youtube/' + this.props.youtubeKey + '/bind';
        const requestOptions = {
            url: url,
            method: 'POST',
        };
        axios(requestOptions)
            .then(async response => {
                


                    this.setState({
                        redirect: "/social", 
                    });

                
            })
            .catch(error => {
                console.error(error);
                this.setState({ 
                    redirect: "/social",
                });
            });

    }

    componentDidUpdate() { 
    
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
           
            <div>
                
            </div>
            
        )
    }
}

function YouTubeAccount(props) {
    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <YouTubeAccountContainer youtubeKey={props.match.params.youtubeKey} />
            </ThemeProvider>
        </div>
    )

}

export default YouTubeAccount