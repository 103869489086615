import { withStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';

const AvatarButtonCrabber = withStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
      
      width: '30px',
      height: '30px',
    },
    img: {
        margin: '0px',
    },
    
    }))(Avatar);

export default AvatarButtonCrabber;