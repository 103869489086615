import React from 'react';
import { theme } from 'theme.js';
import { CssBaseline, Grid, ThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import logo from 'static/images/logo/LOGOS_TRANSPARENTE-08.png'
import CircularProgress from '@material-ui/core/CircularProgress';
import {UsersApi} from 'config/config'
import { Redirect } from 'react-router-dom';
import axios from 'interceptors';
import {Translation, initReactI18next } from 'react-i18next';
import i18n from 'i18n'
import 'ConfirmRegistration.css'

i18n
  .use(initReactI18next)


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class ConfirmRegistrationContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            confirmationId: '',
            open: false,
            errorMessage: '',
        };

        this.handleClose = this.handleClose.bind(this);
        this.getErrorNumber = this.getErrorNumber.bind(this);
    }

    getErrorNumber() {
        if (!this.state.errorMessage) {
            return '#000000';
        }
        return this.state.errorMessage.substring(0,7);
    }

    componentDidMount() {
        this.setState({
            email: this.props.email,
            confirmationId: this.props.confirmationId,
        });
        let url = UsersApi.uri + '/' + this.props.email + '/enable';
        const requestOptions = {
            url: url,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            data:   { 
                        confirmation_id: this.props.confirmationId,
                    },
            
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                
                if (response.status === 200 || response.status === 201) {
                    this.setState({ redirect: "/login" });
                } else {
                    const error = (data && data) || response.status;
                    
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ 
                    open: true,
                    errorMessage: error.response.data?.reason,
                });
            });

    }

    componentDidUpdate() { 
    
    }

    handleClose() {
        this.setState({
            open: false,
            redirect: "/signup"
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <Translation>
            {
            t =>
            <div>
                <div className="confirm-registration-container">
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                            <Grid item xs={6}>
                                <img alt="logo" src={logo} className='logo' />
                                <CircularProgress style={{
                                                            color: 'white',
                                                            //position: 'absolute',
                                                            marginTop: -45,
                                                            width: 60,
                                                            height: 60,
                                                        }}/>
                            </Grid>
                    </Grid>
                </div>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                            
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t(this.getErrorNumber())}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    FECHAR
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>
            </div>
            }
            </Translation>
        )
    }
}

function ConfirmRegistration(props) {
    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ConfirmRegistrationContainer email={props.match.params.email} confirmationId={props.match.params.confirmationId} />
            </ThemeProvider>
        </div>
    )

}

export default ConfirmRegistration