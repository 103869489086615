import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Badge from '@material-ui/core/Badge';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CrabberDropzoneArea from 'components/commons/DropZone';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SessionHistory from 'components/commons/SessionHistory';
import { Redirect } from 'react-router-dom';
import {YouTubedlInfoApi, StartIngestApi, RtmpPullInfoApi, RtmpPushApi, StartRtmpIngestApi, RtmpPushInfoApi, RtmpThumbsApi, UserUsageApi} from 'config/config'
import {Translation, initReactI18next } from 'react-i18next';
import i18n from 'i18n'
import logo from 'static/images/logo/LOGOS_TRANSPARENTE-08.png'
import axios from 'interceptors';
import './Ingest.css'

i18n
  .use(initReactI18next)

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const CrabberTab = withStyles((theme) => ({
    textColorInherit: {
        opacity: '0.3'
    },
}))(Tab);

const CrabberBadge = withStyles((theme) => ({
    badge: {
      right: 25,
      top: 15,
      border: `1px solid #EB5E50`,
      padding: '0 4px',
      backgroundColor: '#EB5E50',
    },
}))(Badge);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
            <Typography component="div">{children}</Typography>
            </Box>
        )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CloneProps(props) {
    const { children, ...other } = props;
    return children(other);
}

function warningMessage(enabled, service) {
    return enabled ? "Ops, o serviço de " + service + " está desativado para o seu plano, para ativa-lo contatar o comercial do Crabber através do WhatsApp 48 98808-5900" : ""
}

class FormIngest extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
                        youtubeAddress: '',
                        rtmpAddress: '',
                        streamInfo: {
                            title: '',
                            uploader: ''
                        },
                        waitFor: false,
                        open: false,
                        page: 1,
                        redirect: null,
                        tabPage: 0,
                        rtmpPullServer: '',
                        rtmpPullStreamKey: '',
                        rtmpPushServer: '',
                        rtmpPushStreamKey: '',
                        rtmpCreatePushConfig: {},
                        waitForRtmpCreatePushConfig: false,
                        rtmpPushExpiringDate: '',
                        rtmpPushExpiringTime: '',
                        rtmpPullTooltip: true,
                        enabledIngest: true,
                        openDisabledIngest: false,
                        forbiddenIngest: false,
                        openForbiddenIngest: false,
                        warningIngest: false,
                        openWarningIngest: false,
                        ingest: {},
                        ingestYouTubeEnabled: false,
                        ingestRtmpPullEnabled: false,
                        ingestRtmpPushEnabled: false,
                        historySessionsLength: 0,
                        historySessionsInvisible: true,
                    };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseDisabledIngest = this.handleCloseDisabledIngest.bind(this);
        this.handleCloseWarningIngest = this.handleCloseWarningIngest.bind(this);
        this.handleCloseForbiddenIngest = this.handleCloseForbiddenIngest.bind(this);
        this.handleStartIngest = this.handleStartIngest.bind(this);
        this.handleStartRtmpIngest = this.handleStartRtmpIngest.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.getErrorNumber = this.getErrorNumber.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleChangeRtmpPullServer = this.handleChangeRtmpPullServer.bind(this);
        this.handleChangeRtmpPullStreamKey = this.handleChangeRtmpPullStreamKey.bind(this);
        this.handleSubmitRtmpPull = this.handleSubmitRtmpPull.bind(this);
        this.handleSubmitRtmpPush = this.handleSubmitRtmpPush.bind(this);
        this.handleChangeStreamInfoTitle = this.handleChangeStreamInfoTitle.bind(this);
        this.handleChangeStreamInfoUploader = this.handleChangeStreamInfoUploader.bind(this);
        this.handleRtmpCreatePushConfig = this.handleRtmpCreatePushConfig.bind(this);
        this.handleGoToStudio = this.handleGoToStudio.bind(this);

        window.FormIngestComponent = this;
    }

    componentDidMount() {
        let youtubeAddress = localStorage.getItem('@crabber/Ingest.youtubeAddress');
        let rtmpPullServer = localStorage.getItem('@crabber/Ingest.rtmpPullServer');
        let rtmpPullStreamKey = localStorage.getItem('@crabber/Ingest.rtmpPullStreamKey');

        if (youtubeAddress?.length > 0) {
            this.setState({
                youtubeAddress: youtubeAddress,
            });
        }

        if (rtmpPullServer?.length > 0) {
            this.setState({
                rtmpPullServer: rtmpPullServer,
            });
        }

        if (rtmpPullStreamKey?.length > 0) {
            this.setState({
                rtmpPullStreamKey: rtmpPullStreamKey,
            });
        }
        
        this.setState({rtmpPullTooltip: true});
        
        let id =localStorage.getItem('@crabber/username');
        axios({
            url: UserUsageApi.uri + '/' + id, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                let overview = response.data;
                let config = overview.config;
                let ingest = overview.usage.ingest;
                let periodEnd = new Date(ingest.period_end);
                let newPeriodBegin = periodEnd.setDate(periodEnd.getDate());
                let ingestExpiringDate = new Date(newPeriodBegin).toLocaleDateString("pt-BR");
                
                this.setState({
                    enabledIngest: ingest.limit <= 0 || ingest.current_amount <= ingest.limit,
                    warningIngest: ingest.limit > 0 && ingest.current_amount + 3600 >= ingest.limit,
                    ingest: ingest,
                    ingestExpiringDate: ingestExpiringDate,
                    ingestYouTubeEnabled: config.ingest_youtube_enabled,
                    tabPage: config.ingest_youtube_enabled ? this.state.tabPage : 1,
                    ingestRtmpPullEnabled: config.ingest_rtmp_pull_enabled,
                    ingestRtmpPushEnabled: config.ingest_rtmp_push_enabled,
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
        
        axios({
            url: UserUsageApi.uri + '/' + id + `/sessions`, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                console.log(response.data);
                console.log(response.data.some(session => session.session_end === null))
                this.setState({
                    historySessionsLength: response.data.length,
                    historySessionsInvisible: response.data.length === 0,
                    forbiddenIngest: response.data.some(session => session.session_end === null)
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
    }
    

    getErrorNumber() {
        if (!this.state.streamInfo?.reason) {
            return '#000000';
        }
        return this.state.streamInfo?.reason.substring(0,7);
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }

    handleCloseDisabledIngest() {
        this.setState({
            openDisabledIngest: false,
        });
    }

    handleCloseWarningIngest() {
        this.setState({
            openWarningIngest: false,
        });
    }

    handleCloseForbiddenIngest() {
        this.setState({
            openForbiddenIngest: false,
        });
    }

    handleChange(event) {
        this.setState({
            youtubeAddress: event.target.value,
        });
    }

    handleChangeStreamInfoTitle(event){
        let streamInfo = this.state.streamInfo;
        streamInfo.title = event.target.value;
        this.setState({
            streamInfo: streamInfo,
        });
    }

    handleChangeStreamInfoUploader(event){
        let streamInfo = this.state.streamInfo;
        streamInfo.uploader = event.target.value;
        this.setState({
            streamInfo: streamInfo,
        });
    }

    handleChangeRtmpPullServer(event) {
        this.setState({
            rtmpPullServer: event.target.value,
        });
    }


    handleChangeRtmpPullStreamKey(event) {
        this.setState({
            rtmpPullStreamKey: event.target.value,
        });
    }

    handleChangeTab(event, newValue) {
        this.setState({
            tabPage: newValue,
            rtmpPullTooltip: true,
        })
    }

    handleGoToStudio() {
        this.setState({
            redirect: '/studio'
        });
    }

    handlePreviousPage(event) {
        this.setState({
            page: 1,
        });
    }


    handleRtmpCreatePushConfig(event) {
        event.preventDefault();
        this.setState({waitForRtmpCreatePushConfig: true});
        const requestOptions = {
            url: RtmpPushApi.uri,
            method: 'POST',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitForRtmpCreatePushConfig: false });
                this.setState({
                    rtmpPushServer: data.server,
                    rtmpPushStreamKey: data.stream_key,
                    rtmpPushExpiringDate: data.expiring_datetime,
                    rtmpPushExpiringTime: data.expiring_time,
                })
            })
            .catch(error => {
                console.error(error);
                this.setState({ waitForRtmpCreatePushConfig: false });
            });
    }

    handleStartIngest(event) {
        localStorage.setItem('@crabber/Ingest.ingestData', JSON.stringify(this.state.streamInfo));
        localStorage.setItem('@crabber/Ingest.currentIn', -1);
        localStorage.setItem('@crabber/Ingest.currentOut', -1);
        localStorage.setItem('@crabber/Ingest.fragNameIn','');

        this.setState({ waitFor: true });
        const requestOptions = {
            url: StartIngestApi.uri,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data:   { 
                        uri: this.state.youtubeAddress,
                        video_title: this.state.streamInfo.title,
                        uploader: this.state.streamInfo.uploader,
                        thumbnail: this.state.streamInfo.thumbnail
                    }
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                const clips = [];
                // check for error response
                localStorage.setItem('@crabber/Ingest.subscriber', JSON.stringify(data));
                localStorage.setItem('@crabber/Clips.data', JSON.stringify(clips));
                if (!response.status === 201) {
                    // get error message from body or default to response status
                    const error = (data && data) || response.status;
                    this.setState({ 
                        open: true,
                    })
                    return Promise.reject(error);
                } else {
                    this.setState({ redirect: "/studio" });
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ waitFor: false });
            });


        
    }

    handleStartRtmpIngest(event) {
        localStorage.setItem('@crabber/Ingest.ingestData', JSON.stringify(this.state.streamInfo));
        this.setState({ waitFor: true });
        let thumbnail = this.state.streamInfo.thumbnail;

        const requestOptions = {
            url: StartRtmpIngestApi.uri,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data:   { 
                        uri: this.state.rtmpAddress,
                        video_title: this.state.streamInfo.title,
                        uploader: this.state.streamInfo.uploader,
                        thumbnail: this.state.streamInfo.thumbnail
                    }
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                const clips = [];
                // check for error response
                localStorage.setItem('@crabber/Ingest.subscriber', JSON.stringify(data));
                localStorage.setItem('@crabber/Clips.data', JSON.stringify(clips));
                if (!response.status === 201) {
                    // get error message from body or default to response status
                    const error = (data && data) || response.status;
                    this.setState({ 
                        open: true,
                    })
                    return Promise.reject(error);
                } else {
                    if (thumbnail.startsWith("data")) {
                        const requestOptions = {
                            url: RtmpThumbsApi.uri,
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            data:   { 
                                        data: this.state.streamInfo.thumbnail
                                    }
                        };
                         axios(requestOptions)
                        .then(async response => {
                            const data = response.data;
                            if (response.status === 201) {
                                this.state.streamInfo.thumbnail = data;
                                localStorage.setItem('@crabber/Ingest.ingestData', JSON.stringify(this.state.streamInfo));
                            }
                            this.setState({ redirect: "/studio" });
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    } else {
                        this.setState({ redirect: "/studio" });
                    }
                    
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ waitFor: false });
            });

        
        

        
    }

    handleSubmitRtmpPull(event) {
        event.preventDefault();
        
        localStorage.setItem('@crabber/Ingest.rtmpPullServer', this.state.rtmpPullServer);
        localStorage.setItem('@crabber/Ingest.rtmpPullStreamKey', this.state.rtmpPullStreamKey);

        if (!this.state.enabledIngest){
            this.setState({
                openDisabledIngest: true,
            });
            return;
        }

        if(this.state.forbiddenIngest) {
            this.setState({
                openForbiddenIngest: true,
            });
            return;
        }
        if(this.state.warningIngest) {
            this.setState({
                openWarningIngest: true,
            });
        }
        
        this.setState({ waitFor: true })

        let rtmpAddress = this.state.rtmpPullServer + (this.state.rtmpPullServer.endsWith("/") ? '' : '/') + this.state.rtmpPullStreamKey;

        const requestOptions = {
            url: RtmpPullInfoApi.uri,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { uri: rtmpAddress}
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                if (response.status === 201) {
                    let streamInfo = {};
                    streamInfo.resolution = data.height + 'p';
                    streamInfo.thumbnail = '';
                    this.setState({ 
                        page: 3,
                        streamInfo: streamInfo,
                        rtmpAddress: rtmpAddress,
                    });
                } else {
                    const error = (data && data) || response.status;
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ 
                    open: true,
                    waitFor: false,
                    streamInfo: error.response?.data ? error.response?.data : '',
                });
            });

        
        
    }
    
    handleSubmitRtmpPush(event) {
        event.preventDefault();
        
        
        localStorage.setItem('@crabber/Ingest.rtmpPushServer', this.state.rtmpPushServer);
        localStorage.setItem('@crabber/Ingest.rtmpPushStreamKey', this.state.rtmpPushStreamKey);
        
        if (!this.state.enabledIngest){
            this.setState({
                openDisabledIngest: true,
            });
            return;
        }

        if(this.state.forbiddenIngest) {
            this.setState({
                openForbiddenIngest: true,
            });
            return;
        }

        if(this.state.warningIngest) {
            this.setState({
                openWarningIngest: true,
            });
        }

        this.setState({ waitFor: true })
        let rtmpAddress = this.state.rtmpPushServer + "/" + this.state.rtmpPushStreamKey;

        const requestOptions = {
            url: RtmpPushInfoApi.uri,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { uri: rtmpAddress}
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                if (response.status === 201) {
                    let streamInfo = {};
                    streamInfo.resolution = data.height + 'p';
                    streamInfo.thumbnail = '';
                    this.setState({ 
                        page: 3,
                        streamInfo: streamInfo,
                        rtmpAddress: rtmpAddress,
                    });
                } else {
                    const error = (data && data) || response.status;
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ 
                    open: true,
                    waitFor: false,
                });
            });

        
    }

    handleSubmit(event) {
        event.preventDefault();
        localStorage.setItem('@crabber/Ingest.youtubeAddress', this.state.youtubeAddress);


        if (!this.state.enabledIngest) {
            this.setState({
                openDisabledIngest: true,
            });
            return;
        }

        if(this.state.forbiddenIngest) {
            this.setState({
                openForbiddenIngest: true,
            });
            return;
        }

        if(this.state.warningIngest) {
            this.setState({
                openWarningIngest: true,
            });
        }

        this.setState({ waitFor: true })
        const requestOptions = {
            url: YouTubedlInfoApi.uri,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { uri: this.state.youtubeAddress }
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                if (response.status === 201) {
                    this.setState({ 
                        page: 2,
                        streamInfo: data,
                    });
                } else {
                    const error = (data && data) || response.status;
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ 
                    open: true,
                    waitFor: false,
                });
            });

    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <Translation>
            {
            t => 
            <div>
                <div className='ingest'>
                {
                    (this.state.page === 1) && 
                   
                        
                            <form noValidate autoComplete="off">
                                <div>
                                    <Grid container alignItems='baseline' direction='row' justify='center' className='grid-container'>
                                        
                                        <Grid item xs={3} />
                                        <Grid item xs={6}>
                                            <img alt="logo" src={logo} className='logo' />
                                            <AppBar position="static">
                                                <Tabs value={this.state.tabPage} onChange={this.handleChangeTab} aria-label="tabs ingest">
                                                    <CloneProps style={!this.state.ingestYouTubeEnabled ? { display: 'none' } : {}}>
                                                        {tabProps => (
                                                            <Tooltip title={warningMessage(!this.state.ingestYouTubeEnabled, "YOUTUBE")} arrow placement="top">
                                                                <div>
                                                                    <CrabberTab {...tabProps} label="YouTube" {...a11yProps(0)} disabled={!this.state.ingestYouTubeEnabled}/>
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                    </CloneProps>
                                                    <CloneProps>
                                                        {tabProps => (
                                                            <Tooltip title={warningMessage(!this.state.ingestRtmpPullEnabled, "RTMP PULL")} arrow placement="top">
                                                                <div>
                                                                    <CrabberTab {...tabProps} label="RTMP pull" {...a11yProps(1)} disabled={!this.state.ingestRtmpPullEnabled}/>
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                    </CloneProps>
                                                    <CloneProps>
                                                        {tabProps => (
                                                            <Tooltip title={warningMessage(!this.state.ingestRtmpPushEnabled, "RTMP PUSH")} arrow placement="top">
                                                                <div>
                                                                    <CrabberTab {...tabProps} label="RTMP push" {...a11yProps(2)} disabled={!this.state.ingestRtmpPushEnabled}/>
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                    </CloneProps>
                                                    <CloneProps>
                                                        {tabProps => (
                                                            <CrabberBadge badgeContent={this.state.historySessionsLength} color="error" invisible={this.state.historySessionsInvisible}>
                                                                <CrabberTab {...tabProps} label="Histórico" {...a11yProps(3)} />
                                                            </CrabberBadge>
                                                        )}
                                                    </CloneProps>

                                                    
                                                </Tabs>
                                                <TabPanel style={!this.state.ingestYouTubeEnabled ? { display: 'none' } : {}} value={this.state.tabPage} index={0}>
                                                    <TextField
                                                        variant="outlined" 
                                                        color="primary" 
                                                        margin="dense" 
                                                        placeholder="https://www.youtube.com/watch?v=a1ytvfKcdL0"
                                                        label="Insira a URL do YouTube:" 
                                                        required
                                                        fullWidth
                                                        disabled={this.state.waitFor}
                                                        value={this.state.youtubeAddress}
                                                        onChange={this.handleChange}
                                                    />
                                                    <div className='height20'></div>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        type="submit"
                                                        autoFocus
                                                        disableRipple
                                                        disabled={!this.state.youtubeAddress || this.state.waitFor}
                                                        onClick={this.handleSubmit}>
                                                        Avançar
                                                        {this.state.waitFor && <CircularProgress style={{
                                                                                        color: 'white',
                                                                                        position: 'absolute',
                                                                                        width: 24,
                                                                                        height: 24
                                                                                    }}/>}
                                                    </Button>
                                                </TabPanel>
                                                <TabPanel value={this.state.tabPage} index={1}>
                                                    <TextField
                                                        variant="outlined" 
                                                        color="primary" 
                                                        margin="dense" 
                                                        placeholder="rtmp://x.rtmp.youtube.com/live2"
                                                        label="Insira a URL do Stream:" 
                                                        required
                                                        fullWidth
                                                        disabled={this.state.waitFor}
                                                        value={this.state.rtmpPullServer}
                                                        onChange={this.handleChangeRtmpPullServer}
                                                    />
                                                    <div className='height20'></div>
                                                    <TextField
                                                        variant="outlined" 
                                                        color="primary" 
                                                        margin="dense" 
                                                        placeholder="dqpm-x0ga-97t3-57d8-fb2e"
                                                        label="Insira a chave do Stream:" 
                                                        required
                                                        fullWidth
                                                        disabled={this.state.waitFor}
                                                        value={this.state.rtmpPullStreamKey}
                                                        onChange={this.handleChangeRtmpPullStreamKey}
                                                    />
                                                    <div className='height20'></div>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        type="submit"
                                                        autoFocus
                                                        disableRipple
                                                        disabled={(!this.state.rtmpPullServer || !this.state.rtmpPullStreamKey) || this.state.waitFor}
                                                        onClick={this.handleSubmitRtmpPull}>
                                                        Avançar
                                                        {this.state.waitFor && <CircularProgress style={{
                                                                                        color: 'white',
                                                                                        position: 'absolute',
                                                                                        width: 24,
                                                                                        height: 24
                                                                                    }}/>}
                                                    </Button>
                                                    <Tooltip 
                                                        title={
                                                            <React.Fragment>
                                                                <Typography variant="h5" color="inherit" component="span"  display="block" gutterBottom>
                                                                    Atenção:
                                                                </Typography>
                                                                <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                    A transmissão deve obedecer as seguintes especificações de vídeo e áudio:
                                                                </Typography>
                                                                <br />
                                                                <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                    <b>Codec de vídeo:</b> 
                                                                    <ul>
                                                                        <li>H.264</li>
                                                                    </ul>
                                                                </Typography>
                                                                
                                                                <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                    <b>Resoluções:</b>
                                                                    <ul>
                                                                        <li><b>360p</b>  nHD</li>
                                                                        <li><b>480p</b>  FWVGA</li>
                                                                        <li><b>720p</b>  HD</li>
                                                                        <li><b>1080p</b> Full HD</li>
                                                                    </ul>
                                                                </Typography>
                                                                
                                                                <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                    <b>Aspecto:</b> 
                                                                    <ul>
                                                                        <li>16:9</li>
                                                                    </ul>
                                                                </Typography>
                                                                <br />
                                                                <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                    <b>Codec de áudio:</b> 
                                                                    <ul>
                                                                        <li>AAC</li>
                                                                    </ul>
                                                                </Typography>
                                                            </React.Fragment>
                                                        } 
                                                        arrow
                                                        placement="right"
                                                        open={this.state.rtmpPullTooltip}
                                                        onOpen={(e) => this.setState({rtmpPullTooltip: true})}
                                                        onClose={(e) => this.setState({rtmpPullTooltip: false})}
                                                        interactive
                                                        >
                                                        <IconButton arial-label="info">
                                                            <HelpRoundedIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TabPanel>
                                                <TabPanel value={this.state.tabPage} index={2}>
                                                    <Button 
                                                        variant="contained" 
                                                        color="primary" 
                                                        type="submit"
                                                        autoFocus
                                                        disableRipple
                                                        disabled={this.state.waitForRtmpCreatePushConfig}
                                                        onClick={this.handleRtmpCreatePushConfig}>
                                                            Criar Endereço
                                                            {this.state.waitForRtmpCreatePushConfig && <CircularProgress style={{
                                                                                        color: 'white',
                                                                                        position: 'absolute',
                                                                                        width: 24,
                                                                                        height: 24
                                                                                    }}/>}
                                                    </Button>
                                                    { 
                                                        this.state.rtmpPushExpiringDate &&
                                                        <div>
                                                            <div className='height20'></div>
                                                            <TextField
                                                                variant="outlined" 
                                                                color="primary" 
                                                                margin="dense" 
                                                                placeholder="rtmp://rtmp.crabber.com.br/streaming"
                                                                label="URL do Stream" 
                                                                fullWidth
                                                                disabled={true}
                                                                value={this.state.rtmpPushServer}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                      <InputAdornment position="end">
                                                                            <Tooltip 
                                                                                title="Copiar"
                                                                                arrow
                                                                                placement="right">   
                                                                                <IconButton
                                                                                    onClick={(e) => {navigator.clipboard.writeText(this.state.rtmpPushServer)}}
                                                                                    edge="end"
                                                                                    disableRipple={true}
                                                                                    disableFocusRipple={true}
                                                                                    >
                                                                                    <FileCopyRoundedIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                      </InputAdornment>
                                                                    ),
                                                                  }}
                                                            />
                                                            
                                                            <div className='height20'></div>
                                                            <TextField
                                                                variant="outlined" 
                                                                color="primary" 
                                                                margin="dense" 
                                                                placeholder="c75b-02a7"
                                                                label="Chave do Stream" 
                                                                fullWidth
                                                                disabled={true}
                                                                value={this.state.rtmpPushStreamKey}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                      <InputAdornment position="end">
                                                                            <Tooltip 
                                                                                title="Copiar"
                                                                                arrow
                                                                                placement="right">            
                                                                                <IconButton
                                                                                    onClick={(e) => {navigator.clipboard.writeText(this.state.rtmpPushStreamKey)}}
                                                                                    edge="end"
                                                                                    disableRipple={true}
                                                                                    disableFocusRipple={true}
                                                                                    >
                                                                                    <FileCopyRoundedIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                      </InputAdornment>
                                                                    ),
                                                                  }}
                                                            />
                                                            
                                                            <div className='height20'></div>
                                                            <Typography>
                                                                Este endereço ficará disponível por 1h, inicie a transmissão até as {this.state.rtmpPushExpiringTime}
                                                            </Typography>
                                                            <div className='height20'></div>
                                                            <Button 
                                                                variant="contained" 
                                                                color="primary" 
                                                                type="submit"
                                                                autoFocus
                                                                disableRipple
                                                                disabled={(!this.state.rtmpPushServer && !this.state.rtmpPushStreamKey) || this.state.waitFor}
                                                                onClick={this.handleSubmitRtmpPush}>
                                                                Avançar
                                                                {this.state.waitFor && <CircularProgress style={{
                                                                                                color: 'white',
                                                                                                position: 'absolute',
                                                                                                width: 24,
                                                                                                height: 24
                                                                                            }}/>}
                                                            </Button>
                                                            <Tooltip 
                                                                title={
                                                                    <React.Fragment>
                                                                        <Typography variant="h5" color="inherit" component="span"  display="block" gutterBottom>
                                                                            Atenção:
                                                                        </Typography>
                                                                        <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                            Configurar o encoder com as seguintes especificações de vídeo e áudio:
                                                                        </Typography>
                                                                        <br />
                                                                        <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                            <b>Codec de vídeo:</b> 
                                                                            <ul>
                                                                                <li>H.264</li>
                                                                            </ul>
                                                                        </Typography>
                                                                        
                                                                        <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                            <b>Resoluções:</b>
                                                                            <ul>
                                                                                <li><b>360p</b>  nHD</li>
                                                                                <li><b>480p</b>  FWVGA</li>
                                                                                <li><b>720p</b>  HD</li>
                                                                                <li><b>1080p</b> Full HD</li>
                                                                            </ul>
                                                                        </Typography>
                                                                        
                                                                        <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                            <b>Aspecto:</b> 
                                                                            <ul>
                                                                                <li>16:9</li>
                                                                            </ul>
                                                                        </Typography>
                                                                        <br />
                                                                        <Typography variant="body1" color="inherit" component="span"  display="block" gutterBottom>
                                                                            <b>Codec de áudio:</b> 
                                                                            <ul>
                                                                                <li>AAC</li>
                                                                            </ul>
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                } 
                                                                arrow
                                                                placement="right"
                                                                open={this.state.rtmpPullTooltip}
                                                                onOpen={(e) => this.setState({rtmpPullTooltip: true})}
                                                                onClose={(e) => this.setState({rtmpPullTooltip: false})}
                                                                interactive
                                                                >
                                                                <IconButton arial-label="info">
                                                                    <HelpRoundedIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div> 
                                                    }
                                                </TabPanel>
                                                <TabPanel 
                                                    value={this.state.tabPage} 
                                                    index={3}
                                                    style={{
                                                        overflowY: "scroll",
                                                        maxHeight: "50vh"
                                                    }}
                                                >
                                                    <SessionHistory />
                                                </TabPanel>
                                            </AppBar>
                                        </Grid>
                                        <Grid item xs={3} />
                                    </Grid>
                                </div>
                            </form>
                        
                }
                {
                    (this.state.page === 2) && 
                    <form noValidate autoComplete="off">
                        <div className='flex-grow1 centered margin-top-10vh'>
                            <Grid container alignItems='flex-start' direction='row' justify='center' className='grid-container'>
                                <Grid item xs={3} />
                                <Grid item xs={6}>
                                    <Grid container direction='row' justify='center'>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                    Agora ao Vivo
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <img alt="Thumbanail" src={this.state.streamInfo?.thumbnail} className='thumbnail border-radius-5'/>
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        variant="outlined" 
                                        color="primary" 
                                        margin="dense" 
                                        label="Nome do Canal" 
                                        fullWidth
                                        disabled
                                        value={this.state.streamInfo?.title}
                                    />
                                    <Grid container direction='row' spacing={0}>
                                        <Grid item xs={9}>
                                            <TextField
                                                variant="outlined" 
                                                color="primary" 
                                                margin="dense" 
                                                label="Autor" 
                                                fullWidth
                                                disabled
                                                value={this.state.streamInfo?.uploader}
                                            />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2} >
                                            <TextField
                                                variant="outlined" 
                                                color="primary" 
                                                margin="dense" 
                                                label="Resolução" 
                                                
                                                disabled
                                                value="720p"
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className='height20'></div>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                type="submit"
                                                disableRipple
                                                onClick={this.handlePreviousPage}>Voltar
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                type="submit"
                                                disableRipple
                                                autoFocus
                                                disabled={this.state.waitFor}
                                                onClick={this.handleStartIngest}>
                                                    Começar
                                                    {this.state.waitFor && <CircularProgress style={{
                                                                        color: 'white',
                                                                        position: 'absolute',
                                                                        width: 24,
                                                                        height: 24
                                                                    }}/>}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} />
                            </Grid>
                        </div>
                    </form>
                }
                {
                    (this.state.page === 3) && 
                    <form noValidate autoComplete="off">
                        <div className='flex-grow1 centered margin-top-10vh'>
                            <Grid container alignItems='flex-start' direction='row' justify='center' className='grid-container'>
                                <Grid item xs={3} />
                                <Grid item xs={6}>
                                    <Grid container direction='row' justify='center'>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Insira as informações da sessão ao vivo
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CrabberDropzoneArea />
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        variant="outlined" 
                                        color="primary" 
                                        margin="dense" 
                                        label="Nome" 
                                        fullWidth
                                        required
                                        value={this.state.streamInfo.title}
                                        onChange={this.handleChangeStreamInfoTitle}
                                    />
                                    <Grid container direction='row' spacing={0}>
                                        <Grid item xs={9}>
                                            <TextField
                                                variant="outlined" 
                                                color="primary" 
                                                margin="dense" 
                                                label="Autor" 
                                                fullWidth
                                                required
                                                value={this.state.streamInfo.uploader}
                                                onChange={this.handleChangeStreamInfoUploader}
                                            />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2} >
                                            <TextField
                                                variant="outlined" 
                                                color="primary" 
                                                margin="dense" 
                                                label="Resolução" 
                                                disabled
                                                value={this.state.streamInfo.resolution}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className='height20'></div>
                                    <Grid container direction='row' spacing={2}>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                type="submit"
                                                disableRipple
                                                onClick={this.handlePreviousPage}>Voltar
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                type="submit"
                                                disableRipple
                                                autoFocus
                                                disabled={this.state.waitFor || !this.state.streamInfo.title || !this.state.streamInfo.uploader}
                                                onClick={this.handleStartRtmpIngest}>
                                                    Começar
                                                    {this.state.waitFor && <CircularProgress style={{
                                                                        color: 'white',
                                                                        position: 'absolute',
                                                                        width: 24,
                                                                        height: 24
                                                                    }}/>}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} />
                            </Grid>
                        </div>
                    </form>
                }
                </div>
                <div>
                
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"URL inválida, verifique e tente novamente."}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t(this.getErrorNumber())}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openDisabledIngest}
                        onClose={this.handleCloseDisabledIngest}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-ingest"
                        aria-describedby="alert-dialog-description-ingest"
                        >
                            <DialogTitle id="alert-dialog-title-ingest">Atenção</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-ingest">
                                Olá, você utilizou 100% do tempo de captura contratado. Seu pacote renovará em {this.state.ingestExpiringDate}.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDisabledIngest} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                    OK
                                </Button>
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openWarningIngest}
                        onClose={this.handleCloseWarningIngest}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-warn"
                        aria-describedby="alert-dialog-description-warn"
                        >
                            <DialogTitle id="alert-dialog-title-warn">Atenção</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-warn">
                                    Você tem apenas {Math.max(0, Math.floor((this.state.ingest.limit)/60) - Math.floor(this.state.ingest.current_amount/60))} minutos de captura.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseWarningIngest} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                    OK
                                </Button>
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openForbiddenIngest}
                        onClose={this.handleCloseForbiddenIngest}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-forbidden"
                        aria-describedby="alert-dialog-description-forbidden"
                        >
                            <DialogTitle id="alert-dialog-title-warn">Atenção</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-forbidden">
                                    Ops, você já tem uma sessão de captura aberta. Só é possivel iniciar uma nova sessão após encerrar a atual.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseForbiddenIngest} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                    CANCELAR
                                </Button>
                                <Button onClick={this.handleGoToStudio} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                    IR PARA SESSÃO
                                </Button>
                            </DialogActions>

                    </Dialog>
                </div>
            </div>
            }
            </Translation>
        );
    }
}
  
  
function Ingest() {
    return (
        <FormIngest />
    )
}

export default Ingest