import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import thumbIcon from 'static/images/icons/thumb_slider.png';
import markerInThumbIcon from 'static/images/icons/icones_crabber-07.png';
import markerOutThumbIcon from 'static/images/icons/icones_crabber-08.png';
import AvatarCrabber from 'static/images/icons/CRAB-02.png';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import CrabberSlider from './CrabberSlider'
import SelectionCrabberSlider from './SelectionCrabberSlider'
import MarkerCrabberSlider from './MarkerCrabberSlider'
import CurrentPositionCrabberSlider from './CurrentPositionCrabberSlider'
import CurrentLivePositionCrabberSlider from './CurrentLivePositionCrabberSlider'
import ZoomCrabberSlider from './ZoomCrabberSlider'
import ControllerCircularProgress from './ControllerCircularProgress'
import AvatarButtonCrabber from './AvatarButtonCrabber'
import AddZoomButtonCrabber from './AddZoomButtonCrabber'
import RemoveZoomButtonCrabber from './RemoveZoomButtonCrabber'
import { withStyles} from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Player from './Player';
import Typography from '@material-ui/core/Typography';
import Utils from 'functions/Utils'
import { ThumbsBase } from 'config/config'
import { FeedingSubscriberApi} from 'config/config'
import throttle from 'lodash/throttle';
import Hls from 'hls.js';
import PlayerController from './PlayerController'

import axios from 'interceptors';
import './Controller.css'



const ControllerButton = withStyles((theme) => ({
    root: {
        marginTop: 8,
        width: '96%',
        
    }

}))(Button);

const ControllerTypography = withStyles((theme) => ({
    root: {
        textAlign: 'center',
        background: '#70707020',
        borderRadius: '5px',
        marginTop: '5px',
        width: '96%',
        
    },

}))(Typography);

let marks = [];
let controllerMark = {};

class ControllerContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            marks: [],
            min: 0,
            max: 300,
            scale: 1,
            factor: 1,
            value: [0, 300],
            first: 0,
            step: 1,
            currentTime : 296,
            currentFragDuration: '4',
            currentFragName: '',
            fragNameIn: '',
            fragDurationIn: '4',
            offsetSexagesimal: '00:00:00',
            currentIn: -1,
            currentOut: -1,
            currentInPosition: 99999,
            currentOutPosition: 99999,
            duration: 0,
            isPlaying: true,
            controllerMark: {},
            intervalId: null,
            streamKey: '',
            thumbs: [],
            live: true,
            cutClipButtonDisabled: true,
            shifting: false,
            shiftCount: 0,
            shiftSide: false,
            shift: 0,
            playerLive: true,
            zooming: false,
            zoomPosition: 7,
            title: '',
            waitFor: false,
            canSetInOrOut: true,
        };

        this.crabberSlider = null;
        
        this.handleCurrentTime = this.handleCurrentTime.bind(this);
        this.handleCurrentIn = this.handleCurrentIn.bind(this);
        this.handleCurrentOut = this.handleCurrentOut.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCommittedCurrentTime = this.handleChangeCommittedCurrentTime.bind(this);
        this.handleChangeCurrentTime = this.handleChangeCurrentTime.bind(this);
        this.handleChangeCommittedZoom = this.handleChangeCommittedZoom.bind(this);
        this.handleChangeZoom = this.handleChangeZoom.bind(this);
        this.handleZoomIn = this.handleZoomIn.bind(this);
        this.handleZoomOut = this.handleZoomOut.bind(this);
        this.crabberThumbComponent = this.ThumbComponent.bind(this);
        this.handleUpdateInOutPosition = this.handleUpdateInOutPosition.bind(this);
        //this.updateController = this.updateController;
        this.handleChangeZoom = this.handleChangeZoom.bind(this);
        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.notifyChangeLiveStatus = this.notifyChangeLiveStatus.bind(this); 
        this.updateCrabberSliderComponent = this.updateCrabberSliderComponent.bind(this);
        this.shiftController = throttle(this.shiftController, 800);
        
        window.controllerComponent = this;
    }


    updateCrabberSliderComponent(crabberSlider) {
        this.crabberSlider = crabberSlider;
    }

    notifyChangeLiveStatus(live) {
        if (live) {
            this.setState({
                currentFragDuration: '4',
                currentFragName: '',
                fragNameIn: '',
                fragDurationIn: '4',
                offsetSexagesimal: '00:00:00',
                currentIn: -1,
                currentOut: -1,
                duration: 0,
                shiftSide: false,
                playerLive: live,
                shift: 0,
                currentInPosition: 99999,
                currentOutPosition: 99999,
            }, () => {
                //console.log("update 5");
                //this.updateController();
            });
            
        } else {
            if (!this.state.isPlaying) {
                let first = this.state.first;
                let max = this.state.max;
                let value = [];
                value[0] = first;
                value[1] = max;
                this.setState({
                    shiftSide: false,
                    currentInPosition: first,
                    currentOutPosition: max,
                    value: value,
                });
            } else {
                this.setState({
                    shiftSide: false,
                    playerLive: live,
                    
                }, () => {
                    //console.log("update 6");
                    //this.updateController();
                });
            }
        }

        
    }

    handleChangeTitle(event) {
        this.setState({
            title: event.target.value,
        });
    }

    handleChangeFactor() {
        
        let factor = this.state.factor;
        let zoomFactor = this.state.zoomPosition;
        
        switch (zoomFactor) {
            case 6:
                factor = 2;
                break;
            case 5: 
                factor = 4;
                break;
            case 4: 
                factor = 8;
                break;
            case 3: 
                factor = 12;
                break;
            case 2: 
                factor = 60;
                break;
            case 7:
            default:
                factor = 1;
        }
                
        let scale = 4 * factor;
        let max = 300 * factor;
        
        let currentTime = max - (controllerMark.controller.duration - this.state.currentTime);
        let currentInPosition = (this.state.currentInPosition === 99999) ? this.state.currentInPosition : max - (controllerMark.controller.duration - this.state.currentInPosition) ;
        let currentOutPosition = (this.state.currentOutPosition === 99999) ? this.state.currentOutPosition : max - (controllerMark.controller.duration - this.state.currentOutPosition) ;
        let first = currentTime;
        let amount = -20 * factor;
        let shift = 0;
        while (currentTime < 0) {
            shift = shift + (amount);
            currentTime = currentTime - amount;
            currentInPosition = currentInPosition - amount;
            currentOutPosition = currentOutPosition - amount;
        }

        shift = shift + this.state.shift;

        //console.log(first - this.state.currentTime, shift);
        let value = [currentInPosition, currentOutPosition];
        //console.log(value);
        
       
        
        this.setState({
            factor: factor,
            zooming: true,
            max: max,
            currentTime: currentTime,
            scale: scale,
            first: first,
            currentInPosition: currentInPosition,
            currentOutPosition: currentOutPosition,
            value: value,
            shift: shift,
        }, () => {
            this.updateController();
            this.handleUpdateInOutPosition();
        });
    }

    handleCurrentIn(event) {
        let offset = parseInt(this.state.currentTime % 4);
        //let offsetAdjusted = offset == 0 ? 4 : offset;
        let offsetAdjusted = 4 - offset;
        let currentIn =  parseInt(this.state.currentFragName) - (offsetAdjusted * 1000);
        let currentInPosition = this.state.currentTime;
        let fragNameIn =  parseInt(this.state.currentFragName);
        let fragDurationIn = this.state.currentFragDuration;
        let offsetSexagesimal = '00:00:00';
        //console.log(parseInt(fragDurationIn))
        if (parseInt(fragDurationIn) === 7 || parseInt(fragDurationIn) === 8) {
            offset = offset + 4;
            switch(offset) {
                case 1:
                    offsetSexagesimal = '00:00:01';
                    break;
                case 2:
                    offsetSexagesimal = '00:00:02';
                    break;
                case 3:
                    offsetSexagesimal = '00:00:03';
                    break;
                case 4:
                    offsetSexagesimal = '00:00:04';
                    break;
                case 5:
                    offsetSexagesimal = '00:00:05';
                    break;
                case 6:
                    offsetSexagesimal = '00:00:06';
                    break;                                    
                case 7:
                    offsetSexagesimal = '00:00:07';
                    break;                        
                case 0:
                    offsetSexagesimal = '00:00:00';
                    break;
                default:
                    offsetSexagesimal = '00:00:00';
            }
        } else {
            switch(offset) {
                case 1:
                    offsetSexagesimal = '00:00:01';
                    break;
                case 2:
                    offsetSexagesimal = '00:00:02';
                    break;
                case 3:
                    offsetSexagesimal = '00:00:03';
                    break;                  
                case 0:
                    offsetSexagesimal = '00:00:00';
                    break;
                default:
                    offsetSexagesimal = '00:00:00';
            }
        }

        //console.log(offset, currentIn, currentInPosition, fragNameIn);
        
        
        

        let value = this.state.value;
        value[0] = currentInPosition;
        localStorage.setItem('@crabber/Ingest.currentIn', currentIn);
        localStorage.setItem('@crabber/Ingest.fragNameIn', fragNameIn);
        //console.log(value);

        this.setState({
            currentIn: currentIn,
            fragNameIn: fragNameIn,
            offsetSexagesimal: offsetSexagesimal,
            currentInPosition: currentInPosition,
            fragDurationIn: fragDurationIn,
        });
    }

    handleCurrentOut(event) {
        let offset = parseInt(this.state.currentTime % 4);
        //let offsetAdjusted = offset == 0 ? 4 : offset;
        let offsetAdjusted = 4 - offset;
        let currentOut =  parseInt(this.state.currentFragName) - (offsetAdjusted * 1000);
        //let duration = (currentOut - this.state.currentIn) / 1000;
        let currentOutPosition = this.state.currentTime;

        let value = this.state.value;
        value[1] = currentOutPosition;

        //console.log(value);
        localStorage.setItem('@crabber/Ingest.currentOut', currentOut);
        this.setState({
            currentOut: currentOut,
            //duration: duration,
            currentOutPosition: currentOutPosition,
        });

        
    }

    handleSubmit(event) {
        
        this.setState({
            waitFor: true,
        });
        
        let duration = (this.state.currentOut - this.state.currentIn) / 1000;
        
        if (duration > (60 * 60)) {
            window.studioComponent.openDialogClipLimit();
            this.setState({
                duration: duration,
                waitFor: false,
            });
            return;
        }

        let fragName = this.state.fragNameIn;
        let offsetSexagesimal = this.state.offsetSexagesimal;
        let fragDurationIn = this.state.fragDurationIn;

        let subscriber = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber'));
        let clips = localStorage.getItem('@crabber/Clips.data') ? JSON.parse(localStorage.getItem('@crabber/Clips.data')) : [] ;
        
        let id = subscriber.id;


        //console.log(FeedingSubscriberApi.uri + id + '/clips?frag_name=' + fragName + '&offset=' + offsetSexagesimal + '&duration=' + duration  + '&frag_duration=' + fragDurationIn);
        axios.get(FeedingSubscriberApi.uri + id + `/clips?frag_name=${encodeURIComponent(fragName)}&offset=${encodeURIComponent(offsetSexagesimal)}&duration=${encodeURIComponent(duration)}&frag_duration=${encodeURIComponent(fragDurationIn)}`)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    //console.log(data);
                    data.title = this.state.title === '' ? '' : this.state.title;
                    data.message = "";
                    data.keywords = [];
                    data.modified_date = Utils.formattedDate();
                    clips.unshift(data);
                    this.setState({
                        title: '',
                    });
                    window.clipsComponent.notifyChangeClips(clips);
                    
                    localStorage.setItem('@crabber/Ingest.currentIn', -1);
                    localStorage.setItem('@crabber/Ingest.currentOut', -1);
                    localStorage.setItem('@crabber/Ingest.fragNameIn','');
                    this.handleChangeFactor();
                    
                    //console.log(clips);
                }
                this.setState({
                    waitFor: false,
                    
                });
                window.clipsComponent.notifyCloseDetails();
            })
            .catch(error => {
                // TODO mensagem de erro
                this.setState({
                    waitFor: false,
                });
                console.error(error);
            });
        this.setState({
            duration: duration,
        });
    }

    updateController() {
        
        if (!this.state.playerLive && !this.state.shiftSide && !this.state.zooming) {
            return;
        }

        if(!Player.hls) {
            return;
        }

        if(!Player.hls.levels) {
            return;
        }

        if (this.state.used) {
            return;
        }

        let manifest = Player.hls.levels[0].details;
        let playerDuration = Player.hls.media.duration;

        if(!playerDuration || !manifest) {
            return;
        }

        this.setState({used: true});

        marks = [];
        
        let fragments = manifest.fragments.slice().reverse();
        let manifestDuration = !this.state.shiftSide && !this.state.zooming ? manifest.totalduration : Controller.controllerMark.manifest.duration;
        let controllerDuration = this.state.max;
        
        //console.log(this.state.zooming);
        let maxManifestFragName = !this.state.shiftSide && !this.state.zooming ? parseInt(fragments[0].relurl.split('.').slice(0, -1).join('.')) : Controller.controllerMark.manifest.maxFragName;
        let maxManifestTimestamp = !this.state.shiftSide && !this.state.zooming ? maxManifestFragName + 4000 : Controller.controllerMark.manifest.maxTimestamp;
        let maxManifestTime = !this.state.shiftSide && !this.state.zooming ? parseInt(playerDuration) : Controller.controllerMark.manifest.maxTime;
        
        let shiftMilliseconds = this.state.shift * 1000;
        
        
        //let maxControllerTime = this.state.shiftSide === '' ? (maxManifestTime - this.state.shift) : Controller.controllerMark.controller.maxTime;    
        let maxControllerTime = maxManifestTime + this.state.shift;
        let maxControllerTimestamp = maxManifestTimestamp + shiftMilliseconds;
        let maxControllerFragName = maxManifestFragName + shiftMilliseconds;

        controllerMark = {};
        controllerMark.manifest = {};
        controllerMark.controller = {};
        controllerMark.player = {};

        controllerMark.manifest.maxTime = maxManifestTime;
        controllerMark.manifest.maxTimestamp = maxManifestTimestamp;
        controllerMark.manifest.maxFragName = maxManifestFragName;
        controllerMark.manifest.duration = manifestDuration;

        controllerMark.controller.maxTime = maxControllerTime;
        controllerMark.controller.maxTimestamp = maxControllerTimestamp;
        controllerMark.controller.maxFragName = maxControllerFragName;
        controllerMark.controller.duration = controllerDuration;

        controllerMark.player.duration = playerDuration;
        
        Controller.controllerMark = controllerMark;

        //console.log(controllerMark);
        //console.log(fragments);

        let mark = {};
        mark.value = controllerDuration;
        mark.label = new Date(maxControllerTimestamp).toLocaleTimeString("pt-BR");
        marks.push(mark);

        let thumbsTemp = [];
        thumbsTemp.push(ThumbsBase.uri + "/" + this.state.streamKey + "-" + maxManifestTimestamp + ".jpeg");
        
        let iterations = Math.min(controllerDuration, (manifestDuration + this.state.shift));
        
        //console.log(this.state.currentTime, Player.hls.media.currentTime);
        // se Player.hls.media.currentTime
        for (let i = 1; i <= iterations; i++) {
            let controllerPosition = controllerDuration - i; 
            let carry = controllerPosition % (4 * this.state.factor);
            if (carry === 0) {
                let mark = {};
                mark.value = controllerPosition;
                
                marks.push(mark);
                if (controllerPosition  % 5 === 0) {
                    if (controllerPosition  % (5 * this.state.scale) === 0) {
                        let timestamp = maxControllerTimestamp - (i * 1000);
                        thumbsTemp.push(ThumbsBase.uri + "/" + this.state.streamKey + "-" + timestamp + ".jpeg");
                        if (thumbsTemp.lenght > 15) {
                            thumbsTemp.pop();
                        }
                        mark.label = new Date(timestamp).toLocaleTimeString("pt-BR");
                    } else {
                        mark.label = '';
                    }
                }
            }
            
        }

        this.setState({
            marks: marks,
            controllerMark: controllerMark,
            used: false,
            first: Math.max(0, controllerDuration - manifestDuration),
            thumbs:  thumbsTemp,
            shiftSide: false,
            zooming: false,
        });

    }



    componentDidMount() {
        let factor = 1;
        let scale = 4 * factor;
        let max = 300 * factor;
        //let value = [max - 1, max];
        let currentTime = max - 12;
        let first = currentTime;
        let subscriber = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber'));
        this.setState({
            factor: factor,
            max: max,
            //value: value,
            scale: scale,
            used: false,
            currentTime: currentTime,
            currentIn: -1,
            currentOut: -1,
            currentFragment: '',
            first: first,
            streamKey: subscriber.stream_key,
            cutClipButtonDisabled: Player.hls.live,
        });
        
        if (!Player.hls.liveSyncPosition) {
            this.setState({live: false});
        }

        Controller.max = this.state.max;
        Controller.controllerMark = {};

        const obj = this;

        Player.hls.on(Hls.Events.LEVEL_LOADED, function (event, data) {
            if (Player.hls.liveSyncPosition) {
                //console.log("update 1");
                obj.updateController();
            }
        });

        Player.hls.on(Hls.Events.FRAG_LOADED, function (event, data) {
            if (!Player.hls.liveSyncPosition) {
                //console.log("update 2");
                obj.updateController();
            }
        });

        Player.hls.on(Hls.Events.FRAG_CHANGED, function (event, data) {
            
            obj.setState({
                currentFragDuration: data.frag.duration,
                currentFragName: data.frag.relurl.split('.').slice(0, -1).join('.'),
            });
        });

        Player.hls.media.addEventListener("pause", function(event) {
            obj.setState({
                isPlaying: false,
            });
        });
        
        Player.hls.media.addEventListener("play", function(event) { 
            obj.setState({
                isPlaying: true,
            });
        });
        
        Player.hls.media.addEventListener("playing", function(event) { 
            obj.setState({
                isPlaying: true,
            });

            if (obj.state.playerLive) {
                //console.log("update 3");
                obj.updateController();
            }

            /*let value = [max - 1, max];
            if (this.state.playerLive) {
                obj.setState({
                    value: value,
                });
            }*/
        });
        
        Player.hls.media.addEventListener("timeupdate", function(event) {
            obj.handleCurrentTime();
        });

        Player.hls.media.addEventListener("seeked", function(event) {
            obj.setState({
                canSetInOrOut: true,
            });
        });
    }

    handleCurrentTime() {
        
        if (!Controller.controllerMark.controller ) {
            return;
        }

        let offset = Controller.controllerMark.controller.maxTime  - Math.round(Player.hls.media.currentTime);
        
        let controllerCurrentTime = Controller.controllerMark.controller.duration - offset;
        
        this.setState({
            currentTime: controllerCurrentTime,
        });
        
        if (!this.state.live) {
            if (!this.state.playerLive ) {
                if (controllerCurrentTime >= this.state.max) {
                    this.shiftController('right');
                }
                if (Player.hls.media.currentTime > Controller.controllerMark.controller.maxTime) {
                    Player.hls.media.currentTime = Controller.controllerMark.controller.maxTime - 4;
                    Player.hls.media.pause();
                }
            }
        }
    }

    handleChangeCommittedZoom(event, newValue) {
        let value = Math.min(7, newValue);
        value = Math.max(2, value);
        
        this.setState({
            zoomPosition: value,
        }, () => {
            this.handleChangeFactor();
        });
    }

    handleChangeZoom(event, newValue) {
        let value = Math.min(7, newValue);
        value = Math.max(2, value);
        
        if (value === this.state.zoomPosition) {
            return;
        }
        
        this.setState({
            zoomPosition: value,
        });
    }

    handleZoomIn() {
        let newValue = this.state.zoomPosition + 1;
        let value = Math.min(7, newValue);
        value = Math.max(2, value);

        if (value === this.state.zoomPosition) {
            return;
        }

        this.setState({
            zoomPosition: value,
        }, () => {
            this.handleChangeFactor();
        });
    }

    handleZoomOut() {
        let newValue = this.state.zoomPosition - 1;
        let value = Math.min(7, newValue);
        value = Math.max(2, value);

        if (value === this.state.zoomPosition) {
            return;
        }


        this.setState({
            zoomPosition: value,
        }, () => {
            this.handleChangeFactor();
        });
    }

    handleUpdateInOutPosition() {
        if (!Player.hls.liveSyncPosition) {
            return;
        }
        let currentIn = parseInt(localStorage.getItem('@crabber/Ingest.currentIn') ? localStorage.getItem('@crabber/Ingest.currentIn') : -1);
        let currentOut = parseInt(localStorage.getItem('@crabber/Ingest.currentOut') ? localStorage.getItem('@crabber/Ingest.currentOut') : -1);
        
        let maxTimestamp =  Controller.controllerMark.controller.maxTimestamp - 4000;
        let controllerDuration = Controller.controllerMark.controller.duration;

        let currentInPosition = controllerDuration - (maxTimestamp - currentIn) / 1000 > 0 ? controllerDuration - (maxTimestamp - currentIn) / 1000 : -1;
        let currentOutPosition = controllerDuration - (maxTimestamp - currentOut) / 1000 > 0 ? controllerDuration - (maxTimestamp - currentOut) / 1000 : -1;
        
        let fragNameIn = localStorage.getItem('@crabber/Ingest.fragNameIn');

        this.setState({
            currentIn: currentIn,
            currentOut: currentOut,
            currentInPosition: currentInPosition,
            currentOutPosition: currentOutPosition,
            fragNameIn: fragNameIn,
        }, () => {
        });
    }

    handleChangeCommittedCurrentTime(event, newValue) {

        if (this.state.isPlaying) {
            Player.hls.media.pause();
        };
        this.handleChangeFactor();

        let controllerCurrentTime = Math.max(this.state.first, newValue);
        this.setState({
            currentTime: controllerCurrentTime,
        });

        let offset = Controller.controllerMark.controller.duration - controllerCurrentTime;
        
        this.setState({
            canSetInOrOut: false,
        });
        Player.hls.media.currentTime = Controller.controllerMark.controller.maxTime - offset;
        //console.log(Player.hls.media.currentTime, Controller.controllerMark.edgeTime);

        this.setState({
            shifting: false,
            shiftCount: 0,
        });

        if (event.type === "keydown" && controllerCurrentTime === 0 && this.state.currentTime === 0) {
            this.shiftController('left');
        }
        if  (event.type === "keydown" && controllerCurrentTime === this.state.max && this.state.currentTime === this.state.max) {
            this.shiftController('right');
        }
       
    }

    handleChangeCurrentTime(event, newValue) {
        
        if (this.state.isPlaying) {
            Player.hls.media.pause();
        };

        let controllerCurrentTime = Math.max(this.state.first, newValue);
        this.setState({
            currentTime: controllerCurrentTime,
        });

        let offset = Controller.controllerMark.controller.duration - controllerCurrentTime;
        Player.hls.media.currentTime = Controller.controllerMark.controller.maxTime - offset;
        //console.log(Player.hls.media.currentTime, Controller.controllerMark.edgeTime);

        if (!this.state.live) {
            if (!this.state.playerLive ) {
                if (controllerCurrentTime <= 0 || controllerCurrentTime >= this.state.max) {
                    if (!this.state.shifting) {
                        this.setState({
                            shifting: true,
                        });
                        const timer = setTimeout(() => {
                            if (this.state.shifting) {
                                if (this.state.shiftCount > 2) {
                                    if (event.type === "mousemove" && controllerCurrentTime === 0 && this.state.currentTime === 0) {
                                        this.shiftController('left');
                                    }
                                    if  (event.type === "mousemove" && controllerCurrentTime === this.state.max && this.state.currentTime === this.state.max) {
                                        this.shiftController('right');
                                    }
                                    
                                }
                                this.setState({
                                    shifting: false,
                                    shiftCount: 0,
                                });
                            }
                            clearTimeout(timer);
                        }, 200);
                    } else {
                        let shiftCount = this.state.shiftCount + 1;
                        this.setState({shiftCount: shiftCount});
                    }
                } else {
                    if (this.state.shifting) {
                        this.setState({
                            shifting: false,
                            shiftCount: 0,
                        });
                    }
                }
            }
        }
        
    }

    shiftController(side) {
        let amount = 0;
        if(side === 'left') {
            amount = -20 * this.state.factor;
        }
        if(side === 'right') {
            amount = +20 * this.state.factor;
        }

        let shift = this.state.shift + amount;
        
        let nextPosition = Controller.controllerMark.controller.maxTime + shift;
        
        if (nextPosition <= (-2 * this.state.max) || nextPosition >= Controller.controllerMark.manifest.maxTime) {
            return;
        }
        
        
        let currentInPosition = (this.state.currentInPosition === 99999 && side === 'left') ? 99999 : this.state.currentInPosition - amount;
        let currentOutPosition = (this.state.currentOutPosition === 99999 && side === 'right') ? 99999 :this.state.currentOutPosition - amount;
        let value = [currentInPosition, currentOutPosition];

        this.setState({
            shift: shift,
            shiftSide: true,
            currentInPosition: currentInPosition,
            currentOutPosition: currentOutPosition,
            value: value,
        }, () => {
            //console.log("update 4");
            this.updateController();
            
            let offset = 0;
            if(side === 'left') {
                offset = Controller.controllerMark.controller.duration + amount;
            }
            if(side === 'right') {
                offset = amount;
            }
            Player.hls.media.currentTime = Controller.controllerMark.controller.maxTime - offset;
        });

    }

    ThumbComponent(props) {
        // add garra crabber no lugar de 3 barrinhas verticais
        return (
          <span {...props}>
              
          </span>
        );   
    }

    CurrentPositionThumbComponent(props) {
        // add garra crabber no lugar de 3 barrinhas verticais
        return (
          <span {...props}>
            
          </span>
        );   
    }

    MarkerInThumbComponent(props) {
        return (
            <span {...props}>
              <Avatar alt="thumb" src={markerInThumbIcon} className="avatar" />
            </span>
          ); 
    }

    MarkerOutThumbComponent(props) {
        return (
            <span {...props}>
              <Avatar alt="thumb" src={markerOutThumbIcon} className="avatar" />
            </span>
          ); 
    }

    ZoomThumbComponent(props) {
        return (
            <span {...props}>
              
            </span>
          ); 
    }

    render() {
        return (
            <div className='controller-container'>
                {((Controller.controllerMark && Controller.controllerMark.controller && Controller.controllerMark.controller.maxTime && !Player.buffering)) ? (
                    <Grid container alignContent='center' alignItems='flex-start' direction='row' justify='space-evenly' className='controller-grid-container' >
                    <Grid item xs={1} >
                        <div className="zoom-container">
                            <ZoomCrabberSlider 
                                min={0}
                                max={9}
                                value={this.state.zoomPosition}
                                defaultValue={this.state.zoomPosition}
                                valueLabelDisplay="off"
                                ThumbComponent={this.ZoomThumbComponent}
                                step={this.state.step}
                                track={false}
                                onChangeCommitted={this.handleChangeCommittedZoom}
                                onChange={this.handleChangeZoom}
                                orientation="vertical"
                                />
                            <Tooltip title="Aumentar zoom" arrow placement="top">
                                <AddZoomButtonCrabber
                                    onClick={this.handleZoomIn}
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    >
                                    <AddCircleIcon />
                                </AddZoomButtonCrabber>
                            </Tooltip>
                            <Tooltip title="Diminuir zoom" arrow placement="bottom">
                                <RemoveZoomButtonCrabber
                                    onClick={this.handleZoomOut}
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    >
                                    <RemoveCircleIcon />
                                </RemoveZoomButtonCrabber>
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="slider-container">
                            <div className="thumbs-container">
                                <div className="thumbs-item">
                                    {this.state.thumbs[0] && <img src={this.state.thumbs[0]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[0] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[1] && <img src={this.state.thumbs[1]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[1] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[2] && <img src={this.state.thumbs[2]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}                        
                                    {!this.state.thumbs[2] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[3] && <img src={this.state.thumbs[3]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[3] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[4] && <img src={this.state.thumbs[4]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[4] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[5] && <img src={this.state.thumbs[5]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[5] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[6] && <img src={this.state.thumbs[6]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[6] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[7] && <img src={this.state.thumbs[7]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[7] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[8] && <img src={this.state.thumbs[8]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[8] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[9] && <img src={this.state.thumbs[9]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[9] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[10] && <img src={this.state.thumbs[10]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[10] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[11] && <img src={this.state.thumbs[11]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[11] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[12] && <img src={this.state.thumbs[12]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[12] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[13] && <img src={this.state.thumbs[13]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}
                                    {!this.state.thumbs[13] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                                <div className="thumbs-item">
                                    {this.state.thumbs[14] && <img src={this.state.thumbs[14]} className="thumb" alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} />}                                    
                                    {!this.state.thumbs[14] && <img src={AvatarCrabber} className="thumb" alt="thumb"  />}
                                </div>
                            </div>
                            <CrabberSlider
                                min={this.state.min}
                                max={this.state.max}
                                value={this.state.value}
                                defaultValue={this.state.value}
                                valueLabelDisplay="on"
                                ThumbComponent={this.ThumbComponent}
                                step={this.state.step}
                                marks={marks}
                                track={false}
                                
                                />
                            {!this.state.playerLive && 
                            this.state.currentInPosition >= this.state.first && 
                            this.state.currentInPosition <= this.state.max && 
                            <MarkerCrabberSlider
                                min={this.state.min}
                                max={this.state.max}
                                value={this.state.currentInPosition}
                                defaultValue={0}
                                valueLabelDisplay="off"
                                ThumbComponent={this.MarkerInThumbComponent}
                                step={this.state.step}
                                track={false}
                                
                                />
                            }
                            {!this.state.playerLive && 
                            this.state.currentOutPosition >= this.state.first && 
                            this.state.currentOutPosition <= this.state.max && 
                            <MarkerCrabberSlider
                                min={this.state.min}
                                max={this.state.max}
                                value={this.state.currentOutPosition}
                                defaultValue={0}
                                valueLabelDisplay="off"
                                ThumbComponent={this.MarkerOutThumbComponent}
                                step={this.state.step}
                                track={false}
                                
                                />
                            }
                            <CurrentPositionCrabberSlider
                                min={this.state.min}
                                max={this.state.max}
                                value={this.state.currentTime}
                                valueLabelDisplay="off"
                                step={this.state.step}
                                onChangeCommitted={this.handleChangeCommittedCurrentTime}
                                onChange={this.handleChangeCurrentTime}
                                ThumbComponent={this.CurrentPositionThumbComponent}
                                ref={(crabberSlider) => {this.updateCrabberSliderComponent(crabberSlider)}}
                                />
                            {!(this.state.playerLive || this.state.currentIn < 0 || this.state.currentOut < 0 || this.state.currentIn >  this.state.currentOut) &&
                            <SelectionCrabberSlider 
                                min={this.state.min}
                                max={this.state.max}
                                value={this.state.value}
                                defaultValue={this.state.value}
                                valueLabelDisplay="off"
                                step={this.state.step}
                                track="normal"
                                />
                            }
                        </div>   
                    </Grid>
                    <Grid item xs={1} >
                        <div className="slider-ref-container">
                            { controllerMark.controller.maxTime === controllerMark.manifest.maxTime && Player.live &&
                            <CurrentLivePositionCrabberSlider className='slider-ref'
                                min={0}
                                max={1}
                                value={1}
                                valueLabelDisplay="off"
                                step={0}
                                
                                />
                            }
                        </div>
                    </Grid>
                    
                    <Grid item xs={1} ></Grid>
                    <Grid item xs={6}>
                    
                        <PlayerController></PlayerController>
                    </Grid>
                    <Grid item xs={1} >
                        
                        <ControllerButton 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            disableRipple
                            disabled={this.state.playerLive || this.state.cutClipButtonDisabled || !this.state.canSetInOrOut}
                            onClick={this.handleCurrentIn}
                            fullWidth
                            startIcon={<AvatarButtonCrabber alt="thumb" src={markerInThumbIcon} className={ (this.state.playerLive || this.state.cutClipButtonDisabled) ? 'avatar-disabled' : ''}/>}
                            >
                                <u>I</u>n
                                {!this.state.canSetInOrOut && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 24,
                                                                height: 24
                                                            }}/>}
                        </ControllerButton>
                        
                        {(this.state.currentIn >= 0) ? (
                            <ControllerTypography
                                    variant="overline" 
                                    display="block" 
                                    gutterBottom>
                                    {new Date((this.state.currentIn + 4000)).toLocaleTimeString("pt-BR")}
                            </ControllerTypography>
                        ) : (
                            <ControllerTypography
                                    variant="overline" 
                                    display="block" 
                                    gutterBottom>
                                    00:00:00
                            </ControllerTypography>
                        )}
                    </Grid>
                    <Grid item xs={1} >
                        
                        <ControllerButton 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            disableRipple
                            disabled={ this.state.playerLive || this.state.cutClipButtonDisabled || !this.state.canSetInOrOut}
                            onClick={this.handleCurrentOut}
                            fullWidth
                            startIcon={<AvatarButtonCrabber alt="thumb" src={markerOutThumbIcon} className={ (this.state.playerLive || this.state.cutClipButtonDisabled) ? 'avatar-disabled' : ''} />}
                            >
                                <u>O</u>ut
                                {!this.state.canSetInOrOut && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 24,
                                                                height: 24
                                                            }}/>}
                        </ControllerButton>
                        
                        {(this.state.currentOut >= 0) ? (
                            <ControllerTypography
                                variant="overline" 
                                display="block" 
                                gutterBottom>
                                {new Date((this.state.currentOut + 4000)).toLocaleTimeString("pt-BR")}
                            </ControllerTypography>
                            ) : (
                            <ControllerTypography
                                variant="overline" 
                                display="block" 
                                gutterBottom>
                                00:00:00
                            </ControllerTypography>
                            )}
                    </Grid>
                        
                    <Grid item xs={2} >
                       
                        <ControllerButton 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            disableRipple
                            disabled={this.state.waitFor || this.state.playerLive || this.state.currentIn < 0 || this.state.currentOut < 0 || this.state.currentIn >  this.state.currentOut}
                            onClick={this.handleSubmit}
                            fullWidth
                            startIcon={<AvatarButtonCrabber alt="thumb" src={thumbIcon} className={ (this.state.waitFor || this.state.playerLive || this.state.currentIn < 0 || this.state.currentOut < 0 || this.state.currentIn >  this.state.currentOut) ? 'avatar-disabled' : ''}/>}
                            >
                                <u>C</u>ortar Clip
                                {this.state.waitFor && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 24,
                                                                height: 24
                                                            }}/>}
                        </ControllerButton>
                        {(this.state.currentIn >= 0 && this.state.currentOut >= 0 && (this.state.currentOut - this.state.currentIn) / 1000 > 0) ? ( 
                            <ControllerTypography
                                variant="overline" 
                                display="block" 
                                gutterBottom>
                                {(Math.floor((this.state.currentOut - this.state.currentIn) / 1000 / 60)).toString().padStart(2, '0')}' {(((this.state.currentOut - this.state.currentIn) / 1000 ) - (Math.floor((this.state.currentOut - this.state.currentIn) / 1000 / 60) * 60)).toString().padStart(2, '0')}''
                            </ControllerTypography>
                            ) : (
                            <ControllerTypography
                                variant="overline" 
                                display="block" 
                                gutterBottom>
                                0 segundos
                            </ControllerTypography>
                            )}
                        
                    </Grid>
                    
                    <Grid item xs={1} ></Grid>
                </Grid>
                ) : (
                <div className="circular-progress-container">
                    <ControllerCircularProgress />
                </div>
                )}
            </div>
        )
    }
}

function Controller() {
    return (
        <div>
            <ControllerContainer />
        </div>
    )
}

export default Controller