import { createMuiTheme } from "@material-ui/core/styles";
import GalanoWoff2 from 'static/fonts/GalanoGrotesque.woff2';
import GalanoWoffLight2 from 'static/fonts/GalanoGrotesqueLight.woff2';
import GalanoWoffBold2 from 'static/fonts/GalanoGrotesqueBold.woff2';

const galano = {
  fontFamily: 'GalanoGrotesque',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('GalanoGrotesque'),
    url(${GalanoWoff2}) format('woff2')
  `,
};

const galanoLight = {
  fontFamily: 'GalanoGrotesqueLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('GalanoGrotesqueLight'),
    url(${GalanoWoffLight2}) format('woff2')
  `,
};

const galanoBold = {
  fontFamily: 'GalanoGrotesqueBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('GalanoGrotesqueBold'),
    url(${GalanoWoffBold2}) format('woff2')
  `,
};

// Create a theme instance.
export const theme = createMuiTheme({
  
  palette: {
    primary: {
      main: "#0F0F0F10",
      '&:hover': {
        backgroundColor: "#0F0F0F90",
      },
    },
    secondary: {
      main: "#0F0F0F10",
      '&:hover': {
        backgroundColor: "#0F0F0F90",
      },
    },
    error: {
      main: "#FF000010",
      '&:hover': {
        backgroundColor: "#FF000090",
      },
    },
    type: 'dark',
  },
  typography: {
    fontFamily: "GalanoGrotesque, Roboto, sans-serif",
    
    body1: {
        fontFamily: "GalanoGrotesque, Roboto, sans-serif",  
    },
    body2: {
      fontFamily: "GalanoGrotesque, Roboto, sans-serif",
    },
    subtitle1: {
      fontFamily: "GalanoGrotesqueLight, GalanoGrotesque, Roboto, sans-serif",
      color: '#FFFFFF90',
    }
  },
  shape: {
    borderRadius: 5
  },
  spacing: 8,
  overrides: {
    MuiCssBaseline: {
      
      '@global': {
        '@font-face': [galano, galanoLight, galanoBold],
        '*::-webkit-scrollbar': {
          width: 8,
        },
        '*::-webkit-scrollbar-track ': {
          backgroundColor: '#30303070',
          borderRadius: 10,
          WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)'
        },
      
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#FFFFFF90',
          borderRadius: 10,
          WebkitBoxShadow: 'inset 0 0 4px rgba(0,0,0,0.9)'
          
        }
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #303030 inset',
          WebkitTextFillColor: '#fff',
          fontFamily: "GalanoGrotesque, Roboto, sans-serif",
        },
      },
    },
    MuiFilledInput: {
      root: {}
    },
    MuiInputLabel: {
      root: {
        color: '#FFFFFF70',
      },
      
    },
    MuiTextField: {
      root: {
        background: "#70707020",
      },
      
    },
    MuiButton: {
      root: {},
      fullWidth: {},
    },
    MuiInputBase: {
      root: {
        
      },
      input: {
        "&::placeholder": {
          color: "#FFFFFF30"
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        
      }
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#FFFFFF70",
        },
      },
      
    },
    MuiAvatar: {
      root: {
        width: 60,
        height: 60,
      }
    },
    MuiPaper: {
      root: {
        
      }
    },
    MuiDrawer: {
      paper: {
        overflowY: 'hidden',
        width: 92,
      }
    },
    MuiLink: {
      root: {
        color: "#FFFFFF"
      }
    },
    MuiCheckbox: {
      root: {
        '&:hover': {
          backgroundColor: '#70707020',
        },
        color: '#ffffff23',
    },
    colorSecondary: {
        '&:hover': {
            backgroundColor: '#70707020',
        },
        '&$checked': {
            color: '#ffffff',
        },
        
    },
    checked: {},
    }
  },
  props: {
    MuiButton: {},
    MuiCheckbox: {},
    MuiTextField: {},
    MuiPaper: {},
    MuiCard: {}
  }
});
