import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const SelectionCrabberSlider = withStyles({
    root: {
      position: "absolute"
    },
    valueLabel: {
      display: "none"
    },
    track: {
      height: 80,
      marginTop: 20,
      backgroundColor: "#FFFFFF25"
    },
    rail: {
      color: "#00000000",
      opacity: 1,
      height: 80,
      marginTop: 20,
      borderRadius: 5,
    },
    mark: {
      color: "#FFFFFF",
      height: 5,
      marginTop: 15,
      width: 1
    },
})(Slider);

export default SelectionCrabberSlider;