import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { withStyles} from '@material-ui/core/styles';
import {UserUsageApi} from 'config/config';
import axios from 'interceptors';
import './UsageIngest.css'

const IngestCircularProgress = withStyles((theme) => ({
    root: {
        color: "#FF9666",
        height: "8vh !important",
        width: "8vh !important",
        zIndex: "999999"
    }

}))(CircularProgress);

const BackgroundCircularProgress = withStyles((theme) => ({
    root: {
        color: "#3C3C3B",
        height: "8vh !important",
        width: "8vh !important",
        marginLeft: "-8vh"
    }

}))(CircularProgress);

function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <IngestCircularProgress variant="determinate" {...props} />
        <BackgroundCircularProgress  variant="determinate" value="100" />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
            
            <Typography variant="caption" component="div" color="textPrimary">
              {props.value}%
            </Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

class UsageIngestContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: null,
            ingest: {},
            progress: 0,
            warningTooltip: false,
        };
        
        this.timer = this.timer.bind(this);

        window.usageIngestContainerComponent = this;
    }

    componentDidMount() {
        var intervalId = setInterval(this.timer, 45000);
        this.setState({
            intervalId: intervalId,
            ingest: {},
            progress: 0,
        });

        let id =localStorage.getItem('@crabber/username');
        axios({
            url: UserUsageApi.uri + '/' + id + `/ingest`, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                let progress = response.data.progress;
                this.setState({
                    ingest: response.data,
                    progress: progress,
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this.setState({
            intervalId: null,
        });
    }

    
    timer() {   
        let id =localStorage.getItem('@crabber/username');
        let liveStatus =localStorage.getItem('@crabber/Player.liveStatus');
        
        axios({
            url: UserUsageApi.uri + '/' + id + `/ingest`, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                console.log(response.data);
                let progress = response.data.progress;
                let warningTooltip = (progress === 75 || progress === 80 || progress === 85 || progress === 90 || progress === 95 || progress === 100) 
                && liveStatus !== 'Sessão encerrada';
                this.setState({
                    ingest: response.data,
                    progress: progress,
                    warningTooltip: warningTooltip,
                });


            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    render() {
        
        return (
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{
                        height: "100%",
                        marginTop: "auto",
                        marginBottom: "auto",
                    }}>
                        <Grid 
                            item
                            style={{
                                marginRight: 10
                            }}
                        >
                        <Typography variant="body1" component="div" color="textPrimary">
                            <b>Tempo de captura</b>
                        </Typography>
                        </Grid>
                            <Tooltip 
                                title={
                                    <React.Fragment>
                                        <Typography variant="caption" component="div">
                                            <b>Tempo de captura no mês: </b> {Math.floor(this.state.ingest.current_amount/60)} minutos
                                        </Typography>
                                            {
                                                (this.state.ingest.limit > 0) &&
                                                <div>
                                                    <Typography variant="caption" component="div">
                                                        <b>Tempo restante no mês: </b> {Math.max(0, Math.floor((this.state.ingest.limit)/60) - Math.floor(this.state.ingest.current_amount/60))} minutos
                                                    </Typography>
                                                    <Typography variant="caption" component="div">
                                                        <b>Tempo excedente no mês: </b> {Math.floor(this.state.ingest.excedeed/60)} minutos
                                                    </Typography>
                                                </div>
                                            } 
                                    </React.Fragment>
                                } 
                                arrow 
                                placement="bottom"
                                open={this.state.warningTooltip}
                                onOpen={(e) => this.setState({warningTooltip: true})}
                                onClose={(e) => this.setState({warningTooltip: false})}
                                interactive>
                                <Grid item>
                                    {
                                        (this.state.ingest && this.state.ingest.limit > 0) ? (
                                            <div>
                                                <CircularProgressWithLabel value={this.state.progress} />
                                            </div>
                                        ) : (
                                            <div>
                                                <Chip label="Ilimitado" />
                                            </div>

                                        )
                                    }
                                </Grid>
                            </Tooltip>
                </Grid>
            
        )
    }
}


function UsageIngest(props) {
    return (
        <div className='usage-ingest-container'>
            <UsageIngestContainer />
        </div>
    )
}

export default UsageIngest