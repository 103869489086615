import React from 'react';
import Hls from 'hls.js';
import AvatarCrabber from 'static/images/icons/CRAB-02.png';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AvatarButtonCrabber from './AvatarButtonCrabber'
import downloadThumbIcon from 'static/images/icons/icones_crabber-05.png';
import onlineStorageThumbIcon from 'static/images/icons/icones_crabber-04.png';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import Link from '@material-ui/core/Link';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import socialIcon from 'static/images/icons/social.png';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Avatar from '@material-ui/core/Avatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { FeedingSubscriberApi, UsersApi, YouTubeApi, FacebookApi, crabberVideoEditingURI, UserUsageApi } from 'config/config'
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import axios from 'interceptors';
import Utils from 'functions/Utils'
import './Clips.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ComboBoxAvatar = withStyles((theme) => ({
    root: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: 10
    },
    
}))(Avatar);

const CrabberSwitch = withStyles({
    switchBase: {
      color: '#3C3C3B',
      
      '&$checked': {
        color: '#FF9666',
      },
      '&$checked + $track': {
        backgroundColor: '#EB5E50',
      },
    },
    checked: {},
    track: {},
  })(Switch);

function ClipContainer(props) {
    const details = props.details;
    const selected = props.selected;
    const clips = props.clips;
    if (!details) {
        return <GetClips clips={clips}/>
    } else {
        return <GetClip selected={selected} clips={clips}/>
    }
}

function GetClips(props) {
    const clips = props.clips;

    const data = clips.map((clip, index) => (   
        
            <div key={index} className="clip-container">
                <div className="clip-thumb">
                    <img src={clip.thumbnail}  alt="thumb" onError={(e)=>{e.target.onerror = null; e.target.src=AvatarCrabber}} onClick={() => window.clipsComponent.handleDetails(index)}/>
                    <div className="clip-thumb-duration">{clip.duration}</div>
                </div>
                <div className="clip-data-container">
                    <div className="clip-data-title">
                        <div className="clip-data-title-text">
                            <TextField
                                variant="outlined" 
                                color="primary" 
                                margin="dense" 
                                placeholder=""
                                label="Insira o título" 
                                fullWidth
                                value={clip.title}
                                onChange={(e) => { clip.modified_date = Utils.formattedDate(); clip.title = e.target.value; window.clipsComponent.notifyChangeClips(clips);  }}
                                onKeyPress={(e) => {
                                    e.persist(); 
                                    if ("Enter" === e.key) {
                                        window.controllerComponent.crabberSlider?.querySelector('.MuiSlider-thumb')?.focus();
                                    } 
                                    e.stopPropagation();
                                    
                                }}
                            />
                        </div> 
                        <div className="clip-data-title-icons">
                            <Tooltip title="Excluir clipe" arrow placement="top">
                                <DeleteOutlinedIcon onClick={() => window.clipsComponent.handleOpenDialog(index)}/>
                            </Tooltip>
                            <Tooltip title="Editar clipe" arrow placement="top">
                                <EditOutlinedIcon onClick={() => window.clipsComponent.handleDetails(index)}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="clip-data-content">
                        <SaveOutlinedIcon />
                        <span>Última alteração: {clip.modified_date}</span>
                    </div>
                    <div className="clip-data-content">
                        <ScheduleRoundedIcon/> 
                        <span>{clip.start_date} de {clip.start_time} até {clip.end_time}</span>
                    </div>
                </div>
            </div>
        
        
        ));

        return <div>
                    <h2 className='clips-title'>Clipes temporários</h2> 
                    {data}
                </div>; 
}

function GetYouTubeTooltip(props) {
    const selected = props.selected;
    const clips = props.clips;
    const clip = clips[selected];
        return <div>
            {(!window.clipsComponent.state.accounts.youtube?.length || window.clipsComponent.state.accounts.youtube?.length === 0 || window.clipsComponent.state.accounts.youtube[0]?.youTubeChannels?.length === 0) ? (
                <Tooltip arrow interactive 
                    title={
                        <React.Fragment>
                            <Typography color="inherit" component="span" display="block">
                                Ops! Publicação não permitida pelo <b>YouTube</b>, é necessário adicionar uma conta de administrador do canal.
                            </Typography>
                            <Typography color="inherit" component="span" display="block">
                                <Link href="/social">
                                    Clique aqui para adicioná-la.
                                </Link>
                            </Typography>
                        </React.Fragment>
                    } >
                    <div>
                        <CrabberSwitch 
                            disabled = {true}
                        />
                    </div>
                </Tooltip>
            ):(
                <CrabberSwitch 
                    disabled = {false}
                    checked = {clip.youTubeChecked ? clip.youTubeChecked : false}
                    onChange={(e) => { clip.youTubeChecked = e.target.checked; window.clipsComponent.notifyChangeClips(clips);  }}
                />
            )}
        </div>
}

function GetFacebookTooltip(props) {
    const selected = props.selected;
    const clips = props.clips;
    const clip = clips[selected];
        return <div>
            {(!window.clipsComponent.state.accounts.facebook?.length || window.clipsComponent.state.accounts.facebook.length === 0 || window.clipsComponent.state.accounts.facebook[0]?.facebookPages?.length === 0) ? (
                <Tooltip arrow interactive 
                    title={
                        <React.Fragment>
                            <Typography color="inherit" component="span" display="block">
                                Ops! Publicação não permitida pelo <b>Facebook</b>, é necessário adicionar uma conta de administrador da página.
                            </Typography>
                            <Typography color="inherit" component="span" display="block">
                                <Link href="/social">
                                    Clique aqui para adicioná-la.
                                </Link>
                            </Typography>
                        </React.Fragment>
                    } >
                    <div>
                        <CrabberSwitch 
                            disabled = {true}
                        />
                    </div>
                </Tooltip>
            ):(
                <CrabberSwitch 
                    disabled = {false}
                    checked = {clip.facebookChecked ? clip.facebookChecked : false}
                    onChange={(e) => { clip.facebookChecked = e.target.checked; window.clipsComponent.notifyChangeClips(clips);  }}
                />
            )}
        </div>
}

function GetInstagramTooltip(props) {
    const selected = props.selected;
    const clips = props.clips;
    const clip = clips[selected];
    const duration = clip.duration.split(':');
    const seconds = (duration.length === 2) ? (+duration[0]) * 60 + (+duration[1]) : 120;
        return <div>
            {(!window.clipsComponent.state.accounts.instagram?.length || window.clipsComponent.state.accounts.instagram.length === 0 
            || window.clipsComponent.state.accounts.instagram[0]?.instagramPages?.length === 0
            || seconds < 3 || seconds > 60) ? (
                <Tooltip arrow interactive 
                    title={
                        <React.Fragment>
                            <Typography color="inherit" component="span" display="block">
                                Ops! Publicação não permitida pelo <b>Instagram</b>. Verifique as seguintes regras: 
                                <ul>
                                    <li>A publicação de conteúdo só está disponível para usuários do <b>Instagram Business</b> 
                                        <Typography color="inherit" component="span"  display="block">
                                            <Link href="/social">
                                                Clique aqui para adicioná-la.
                                            </Link>
                                        </Typography>
                                    </li>
                                    <li>Máximo de <b>25 postagens</b> dentro de um período contínuo de 24 horas</li>
                                    <li>Duração do vídeo - <b>Máximo 60 segundos, mínimo 3 segundos</b></li>
                                </ul>
                            </Typography>
                            
                        </React.Fragment>
                    } >
                    <div>
                        <CrabberSwitch 
                            disabled = {true}
                        />
                    </div>
                </Tooltip>
            ):(
                <CrabberSwitch 
                    disabled = {false}
                    checked = {clip.instagramChecked ? clip.instagramChecked : false}
                    onChange={(e) => { clip.instagramChecked = e.target.checked; window.clipsComponent.notifyChangeClips(clips);  }}
                />
            )}
        </div>
}

function GetClip(props) {
    const selected = props.selected;
    const clips = props.clips;
    const clip = clips[selected];
    const hlsConfig = {
        manifestLoadingMaxRetry: 60,
        enableWorker: false,
        autoStartLoad: false,
    };

    let playerRef;
    if (!window.clipsComponent.hls) {
    //const playerRef = React.createRef();
        playerRef = node => {
            const ref = node;
            const newHls = new Hls(hlsConfig);
            newHls.attachMedia(ref);
            newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
                newHls.loadSource(clip.hls);

                newHls.on(Hls.Events.MANIFEST_PARSED, () => {
                    console.log("PARSED");
                });

                newHls.on(Hls.Events.BUFFER_CREATED, function (event, data) {
                    console.log("BUFFER_CREATED");
                    window.clipsComponent.notifyChangeClips(clips);
                });
            });

            newHls.on(Hls.Events.BUFFER_RESET, function (event, data) {
                console.log("BUFFER_RESET");
                newHls.startLoad();
            });

            window.clipsComponent.hls = newHls;
        };
    }
    
    
    return <div>
                <div className="clip-data-header">
                    
                    <div className="clip-data-title-icons">
                        <Tooltip title="Fechar" arrow placement="top"><CloseOutlinedIcon onClick={() =>{ window.clipsComponent.notifyCloseDetails(); }}/></Tooltip>
                    </div>
                </div>
                
                <div className="clip-data-player">
                    <div className="">
                            <video
                                className=""
                                ref={playerRef}
                                autoPlay={false}
                                controls={true}
                            />
                            
                    </div>
                </div>

                <div className="clip-data-block">
                    <div className="clip-data-content">
                        <SaveOutlinedIcon />
                        <span>Última alteração: {clip.modified_date}</span>
                    </div>
                    <div className="clip-data-content">
                        <ScheduleRoundedIcon/> 
                        <span>{clip.start_date} de {clip.start_time} até {clip.end_time}</span>
                    </div>
                </div>

                <div className="clip-data-block">
                    <div className="clip-data-block-item">
                        <TextField
                            variant="outlined" 
                            color="primary" 
                            margin="dense" 
                            placeholder=""
                            label="Insira o título" 
                            fullWidth
                            value={clip.title}
                            onChange={(e) => { clip.modified_date = Utils.formattedDate(); clip.title = e.target.value; window.clipsComponent.notifyChangeClips(clips);  }}
                        />
                    </div>
                    <div className="clip-data-block-item">
                        <TextField
                            variant="outlined" 
                            color="primary" 
                            margin="dense" 
                            placeholder=""
                            label="Insira o texto para redes sociais" 
                            fullWidth
                            value={clip.message}
                            onChange={(e) => { clip.modified_date = Utils.formattedDate(); clip.message = e.target.value; window.clipsComponent.notifyChangeClips(clips);  }}
                            multiline={true}
                            rows="5"
                        />
                    </div>
                    <div className="clip-data-block-item">
                        {clip?.keywords &&<Autocomplete
                            loading={!clip?.keywords}
                            loadingText="Carregando..."
                            noOptionsText="Nenhuma opção encontrada"
                            multiple
                            id="tags-filled"
                            value={clip.keywords}
                            freeSolo
                            fullWidth
                            options={clip.keywords.map((option) => option)}
                            filterSelectedOptions={true}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Insira as palavras chaves" placeholder="" />
                            )}
                            onChange={(event, newValue) => {
                                clip.modified_date = Utils.formattedDate(); 
                                clip.keywords = newValue; 
                                window.clipsComponent.notifyChangeClips(clips); 
                            }}
                        />}
                    </div>
                    
                    
                </div>
                <div className="clip-data-block">
                    <FormGroup column="true">
                        <FormControlLabel
                            control={
                                <GetYouTubeTooltip clips={clips} selected={selected}/>
                            }
                            label="YouTube"
                        />                                   
                    </FormGroup>
                    <br  />
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                        >
                            <Grid item>
                                {(window.clipsComponent.state.accounts.youtube && window.clipsComponent.state.accounts.youtube[0]?.youTubeSelectedChannel && window.clipsComponent.state.accounts.youtube?.length >= 0) && <Autocomplete
                                    loading={window.clipsComponent.state.accounts.youtube?.length < 0}
                                    loadingText="Carregando..."
                                    noOptionsText="Nenhuma opção encontrada"
                                    id="combo-box-channel"
                                    autoSelect
                                    disableClearable
                                    disabled={window.clipsComponent.state.downloadWaitFor || !clip.youTubeChecked || clip.youTubeChecked === false }
                                    value={window.clipsComponent.state.accounts.youtube[0].youTubeSelectedChannel}
                                    options={window.clipsComponent.state.accounts.youtube[0].youTubeChannels}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(event, newValue) => {
                                        let accounts = window.clipsComponent.state.accounts;
                                        accounts.youtube[0].youTubeSelectedChannel = newValue;
                                        window.clipsComponent.setState({accounts: accounts});
                                    }}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>
                                                <ComboBoxAvatar alt="option.name" src={option ? option.thumbnail : ''} />
                                            </span>
                                            {option ? option.name : ''}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Canal" variant="outlined" />)}
                                />}
                            </Grid>
                            <br  />
                            <Grid item>
                             {(window.clipsComponent.state.accounts.youtube && window.clipsComponent.state.accounts.youtube[0]?.youTubeSelectedChannel && window.clipsComponent.state.accounts.youtube?.length >= 0) && <Autocomplete
                                    loading={window.clipsComponent.state.accounts.youtube?.length < 0}
                                    loadingText="Carregando..."
                                    noOptionsText="Nenhuma opção encontrada"
                                    id="combo-box-privacy"
                                    autoSelect
                                    disableClearable
                                    disabled={window.clipsComponent.state.downloadWaitFor || !clip.youTubeChecked || clip.youTubeChecked === false}
                                    value={window.clipsComponent.state.accounts.youtube[0].youTubeSelectedPrivacy}
                                    options={window.clipsComponent.state.accounts.youtube[0].youTubePrivacies}
                                    getOptionLabel={(option) => option ? option.label : ''}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(event, newValue) => {
                                        let accounts = window.clipsComponent.state.accounts;
                                        accounts.youtube[0].youTubeSelectedPrivacy = newValue;
                                        window.clipsComponent.setState({accounts: accounts});
                                    }}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option ? option.label : ''}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Privacidade" variant="outlined" />)}
                                />}
                            </Grid>
                        </Grid>
                </div>
                <div className="clip-data-block">
                    <FormGroup column="true">
                        <FormControlLabel
                            control={<GetFacebookTooltip clips={clips} selected={selected}/>}
                            label="Facebook"
                        />       
                    </FormGroup>
                    <br  />
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                        >
                            <Grid item>
                                 {(window.clipsComponent.state.accounts.facebook && window.clipsComponent.state.accounts.facebook[0]?.facebookSelectedPage && window.clipsComponent.state.accounts.facebook?.length >= 0) && <Autocomplete
                                    loading={window.clipsComponent.state.accounts.facebook?.length < 0}
                                    loadingText="Carregando..."
                                    noOptionsText="Nenhuma opção encontrada"
                                    id="combo-box-page-fb"
                                    autoSelect
                                    disableClearable
                                    disabled={window.clipsComponent.state.downloadWaitFor || !clip.facebookChecked || clip.facebookChecked === false}
                                    value={window.clipsComponent.state.accounts.facebook[0].facebookSelectedPage}
                                    options={window.clipsComponent.state.accounts.facebook[0].facebookPages}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(event, newValue) => {
                                        let accounts = window.clipsComponent.state.accounts;
                                        accounts.facebook[0].facebookSelectedPage = newValue;
                                        window.clipsComponent.setState({accounts: accounts});
                                    }}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option ? option.name : ''}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Página" variant="outlined" />)}
                                />}
                            </Grid>
                        </Grid>
                </div>
                <div className="clip-data-block">
                    <FormGroup column="true">
                        <FormControlLabel
                            control={<GetInstagramTooltip clips={clips} selected={selected}/>}
                            label="Instagram"
                        />  
                        <br  />     
                    </FormGroup>
                    <br  />
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                        >
                            <Grid item>
                                {(window.clipsComponent.state.accounts.instagram && window.clipsComponent.state.accounts.instagram[0]?.instagramSelectedPage && window.clipsComponent.state.accounts.facebook?.length >= 0) && <Autocomplete
                                    loading={window.clipsComponent.state.accounts.instagram?.length < 0}
                                    loadingText="Carregando..."
                                    noOptionsText="Nenhuma opção encontrada"
                                    id="combo-box-page-insta"
                                    autoSelect
                                    disableClearable
                                    disabled={window.clipsComponent.state.downloadWaitFor || !clip.instagramChecked || clip.instagramChecked === false}
                                    value={window.clipsComponent.state.accounts.instagram[0].instagramSelectedPage}
                                    options={window.clipsComponent.state.accounts.instagram[0].instagramPages}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(event, newValue) => {
                                        let accounts = window.clipsComponent.state.accounts;
                                        accounts.instagram[0].instagramSelectedPage = newValue;
                                        window.clipsComponent.setState({accounts: accounts});
                                    }}
                                    style={{ width: 300 }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option ? option.name : ''}
                                        </React.Fragment>
                                    )}
                                    renderInput={(option) => (<TextField {...option} label="Página" variant="outlined" />)}
                                />}
                            </Grid>
                            <br/>
                            <Grid item>
                                {(window.clipsComponent.state.accounts.instagram && window.clipsComponent.state.accounts.instagram[0]?.instagramCaption && window.clipsComponent.state.accounts.facebook?.length) >= 0 && <TextField
                                    disabled={window.clipsComponent.state.downloadWaitFor || !clip.instagramChecked || clip.instagramChecked === false}
                                    id="text-field-caption-insta"
                                    variant="outlined" 
                                    color="primary" 
                                    margin="dense"
                                    label="Legenda do vídeo" 
                                    fullWidth
                                    value={window.clipsComponent.state.accounts.instagram ? window.clipsComponent.state.accounts.instagram[0].instagramCaption : ''}
                                    onChange={(e) => {
                                        let accounts = window.clipsComponent.state.accounts;
                                        accounts.instagram[0].instagramCaption = e.target.value;
                                        window.clipsComponent.setState({accounts: accounts});
                                    }}
                                    multiline
                                    rows={10}
                                    style={{ width: 300 }}
                                    helperText="Maximo 30 hastags e 20 menções"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />}
                            </Grid>
                        </Grid>
                </div>
                <div className="clip-data-actions">
                    
                    <Button 
                        variant="contained" 
                        color="primary" 
                        type="submit"
                        disableRipple
                        onClick={(e) => {window.clipsComponent.notifyDownloadClip(e,clip);}}
                        startIcon={<AvatarButtonCrabber alt="thumb" src={downloadThumbIcon} className={ (window.clipsComponent.state.downloadWaitFor) ? 'avatar-disabled' : ''}/>}
                        disabled={window.clipsComponent.state.downloadWaitFor}
                        >
                        DOWNLOAD
                        {window.clipsComponent.state.downloadWaitFor  && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 24,
                                                                height: 24
                                                            }}/>}
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        type="submit"
                        disableRipple
                        onClick={(e) => {window.clipsComponent.notifyUploadClip(e);}}
                        startIcon={<AvatarButtonCrabber alt="thumb" src={onlineStorageThumbIcon} className={ (window.clipsComponent.state.uploadWaitFor) ? 'avatar-disabled' : ''}/>}
                        disabled={window.clipsComponent.state.uploadWaitFor}
                        >
                        ACERVAR
                        {window.clipsComponent.state.uploadWaitFor && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 24,
                                                                height: 24
                                                            }}/>}
                    </Button>
                    {window.clipsComponent.state.videoEditingBetaEnabled && (
                        <Button 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            disabled={!window.clipsComponent.state.videoEditingBetaEnabled}
                            disableRipple
                            onClick={(e) => {window.clipsComponent.uploadClipInProject(e, clip);}}
                            startIcon={<AvatarButtonCrabber alt="thumb" src={onlineStorageThumbIcon} />}
                        >
                            VIDEO EDITOR
                        </Button>
                    )}                    
                    <Button 
                        variant="contained" 
                        color="primary" 
                        type="submit"
                        disableRipple
                        onClick={(e) => {
                            if (localStorage.getItem('@crabber/hideCheckedPublishConfirmation') === 'true') {
                                window.clipsComponent.notifyShareClip(e);
                            } else {
                                window.clipsComponent.setState({openPublishConfirmation: true});    
                            }
                            
                        }}
                        startIcon={<AvatarButtonCrabber alt="thumb" src={socialIcon} className={ (
                            window.clipsComponent.state.publishWaitFor || 
                            (!clip.youTubeChecked && 
                                !clip.facebookChecked && 
                                !clip.instagramChecked) || 
                            (clip.youTubeChecked === false && 
                                clip.facebookChecked === false && 
                                clip.instagramChecked === false) || 
                            (window.clipsComponent.state.accounts.youtube?.length === 0 && 
                                window.clipsComponent.state.accounts.facebook?.length === 0 && 
                                window.clipsComponent.state.accounts.instagram?.length === 0)
                            ) ? 'avatar-disabled' : ''} />}
                        disabled={
                            window.clipsComponent.state.publishWaitFor || 
                            (!clip.youTubeChecked && 
                                !clip.facebookChecked &&
                                !clip.instagramChecked) || 
                            (clip.youTubeChecked === false && 
                                clip.facebookChecked === false && 
                                clip.instagramChecked === false) ||
                            (window.clipsComponent.state.accounts.youtube?.length === 0 && 
                                window.clipsComponent.state.accounts.facebook?.length === 0  && 
                                window.clipsComponent.state.accounts.instagram?.length === 0)
                        }
                        >
                        PUBLICAR
                        {window.clipsComponent.state.publishWaitFor && <CircularProgress style={{
                                                                color: 'white',
                                                                position: 'absolute',
                                                                width: 24,
                                                                height: 24
                                                            }}/>}
                    </Button>
                </div>
            </div>
}

const PublishConfirmationCheckbox = withStyles((theme) => ({
    root: {
        '&:hover': {
          backgroundColor: '#70707020',
        },
        color: '#ffffff23',
    },
    colorSecondary: {
        '&:hover': {
            backgroundColor: '#70707020',
        },
        '&$checked': {
            color: '#ffffff',
        },
        
    },
    checked: {},
}))(Checkbox);

class ClipsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clips: [],
            open: false,
            openPublish: false,
            openPublishConfirmation: false,
            openUpload: false,
            openUploadForbidden: false,
            selected: -1,
            details: false,
            downloadWaitFor: false,
            publishWaitFor: false,
            uploadWaitFor: false,
            accounts: {},
            hideCheckedPublishConfirmation: false,
            videoEditingBetaEnabled: false,
        };

        
        this.notifyChangeClips = this.notifyChangeClips.bind(this);
        this.notifyDownloadClip = this.notifyDownloadClip.bind(this);
        this.uploadClipInProject = this.uploadClipInProject.bind(this);
        this.notifyUploadClip = this.notifyUploadClip.bind(this);
        this.notifyShareClip = this.notifyShareClip.bind(this);
        this.notifyCloseDetails = this.notifyCloseDetails.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseUpload = this.handleCloseUpload.bind(this);
        this.handleCloseUploadForbidden = this.handleCloseUploadForbidden.bind(this);
        this.handleClosePublish = this.handleClosePublish.bind(this);
        this.handleClosePublishConfirmation = this.handleClosePublishConfirmation.bind(this);
        this.handleDetails = this.handleDetails.bind(this);
        this.loadVideoEditingEnabled = this.loadVideoEditingEnabled.bind(this);
        
        window.clipsComponent = this;
        window.clipsComponent.hls = undefined;
    }


    handleOpenDialog(index) {
        
        this.setState({
            selected: index,
            open: true,
        });
    }

    handleDetails(index) {
        this.setState({
            details: true,
            selected: index,
        });
    }

    handleRemove() {
        if (this.state.selected < 0) {
            return;
        }
        let clips = this.state.clips;
        let index = this.state.selected;
        let body = JSON.stringify(clips[index]);
        let subscriber = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber'));
        let id = subscriber.id;

        axios({
            url: FeedingSubscriberApi.uri + id + `/clips`,
            method: 'DELETE',
            data: body,
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
        }).then(async response => {
        })
        .catch(error => {
            console.error(error);
        });
        

        clips.splice(index, 1);
        this.setState({
            clips: clips,
            open: false,
        });
        localStorage.setItem('@crabber/Clips.data', JSON.stringify(clips)) ;
    }

    handleClose() {
        
        this.setState({
            open: false,
            selected: -1,
        })
    }

    handleCloseUpload() {
        this.setState({
            openUpload: false,
        });
        window.sidebarMenu.notifyOpenLibraryTooltip();
    }

    handleCloseUploadForbidden() {
        this.setState({
            openUploadForbidden: false,
        });
    }

    handleClosePublish() {
        this.setState({
            openPublish: false,
        })
    }

    handleClosePublishConfirmation() {
        this.setState({
            openPublishConfirmation: false,
        })
    }

    notifyChangeClips(clips) {
        localStorage.setItem('@crabber/Clips.data', JSON.stringify(clips));
        this.setState({
            clips: clips,
        });
    }

    notifyShareClip(event) {
        if (this.state.selected < 0) {
            return;
        }

        this.setState({
            openPublishConfirmation: false,
            publishWaitFor: true,
        });
        let clips = this.state.clips;
        let index = this.state.selected;
        let clip = clips[index];
        
        
        let subscriberId = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber')).id;
        let crabberKey = localStorage.getItem('@crabber/username');
        let youTubeKey = JSON.parse(localStorage.getItem('@crabber/accounts')).youtube[0]?.key;
        let channelId = this.state.accounts.youtube[0]?.youTubeSelectedChannel?.id;
        let privacyId = this.state.accounts.youtube[0]?.youTubeSelectedPrivacy?.id;
        let facebookKey = this.state.accounts.facebook[0]?.key;
        //let groupId = this.state.accounts.facebook[0].facebookSelectedGroup?.id;
        let pageId = this.state.accounts.facebook[0]?.facebookSelectedPage?.id;
        let instagramPageId = this.state.accounts.instagram[0]?.instagramSelectedPage?.id;
        let caption = this.state.accounts.instagram[0] && this.state.accounts.instagram[0]?.instagramCaption ? this.state.accounts.instagram[0]?.instagramCaption : '';


        let clipId = clip.id;
        let url= FeedingSubscriberApi.uri + subscriberId + '/clips/' + clipId + '/target';

        let youTube = clip.youTubeChecked ?  {
            key: youTubeKey,
            channel_id: channelId,
            privacy_id: privacyId,
        } : null;
        let facebook = clip.facebookChecked ? {
            key: facebookKey,
            //group_id: groupId,
            page_id: pageId,
        } : null;
        let instagram = clip.instagramChecked ? {
            key: facebookKey,
            //group_id: groupId,
            page_id: instagramPageId,
            caption: caption,
        } : null;
        let data = {
                title: clip.title,
                description: clip.message,
                tags: clip.keywords ? clip.keywords : [],
                crabber_key: crabberKey,
                youtube:  youTube,
                facebook: facebook,
                instagram: instagram,
            };
        axios({
            url: url,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: data,
        }).then(async response => {
            this.setState({
                publishWaitFor: false,
                openPublish: true,
            });
        })
        .catch(error => {
            this.setState({
                publishWaitFor: false,
                openPublish: true,
            });
            console.error(error);
        });
    }

    notifyUploadClip(event) {
        if (window.usageStorageContainerComponent.state.storage.excedeed > 0) {
            this.setState({
                openUploadForbidden: true,
            });
            return;
        }

        this.setState({
            uploadWaitFor: true,
        });

        let clips = this.state.clips;
        let index = this.state.selected;
        let clip = clips[index];
        
        
        let subscriberId = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber')).id;
        let crabberKey = localStorage.getItem('@crabber/username');

        let clipId = clip.id;
        
        let metadata = {};
        metadata.duration = clip.duration;
        metadata.start_date = clip.start_date;
        metadata.start_time = clip.start_time;
        metadata.end_time = clip.end_time;
        

        let ingestData = JSON.parse(localStorage.getItem('@crabber/Ingest.ingestData'));
        
        let origin = {};
        origin.address = localStorage.getItem('@crabber/Ingest.youtubeAddress');
        origin.thumbnail = ingestData.thumbnail;
        origin.title = ingestData.title;
        origin.uploader = ingestData.uploader;
        origin.type = "YOUTUBE";

        
        let data = {
            title: clip.title,
            description: clip.message,
            tags: clip.keywords ? clip.keywords : [],
            crabber_key: crabberKey,
            metadata: metadata,
            origin: origin,
        };
        let url= FeedingSubscriberApi.uri + subscriberId + '/clips/' + clipId + '/storage';
        
        axios({
            url: url,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: data,
        }).then(async response => {
            this.setState({
                uploadWaitFor: false,
                openUpload: true,
            });
        })
        .catch(error => {
            this.setState({
                uploadWaitFor: false,
                openUpload: true,
            });
            console.error(error);
        });

    }

    notifyDownloadClip(event, clip) {

        this.setState({
            downloadWaitFor: true,
        });

        let subscriberId = JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber')).id;
        let clipId = clip.id;
        axios({
            url: FeedingSubscriberApi.uri + subscriberId + '/clips/' + clipId,
            method: 'GET',
            responseType: 'arraybuffer',
        }).then(async response => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'crabber-'+ clipId.replaceAll('-','') + '.mp4'); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
            this.setState({
                downloadWaitFor: false
            });
        })
        .catch(error => {
            this.setState({
                downloadWaitFor: false,
            });
            console.error(error);
        });
    }

    uploadClipInProject(event, clip){
        var objClip = {
            clipId: clip.id,
            liveId: JSON.parse(localStorage.getItem('@crabber/Ingest.subscriber')).id,
        };
        var encodedClip = btoa(JSON.stringify(objClip));

        var obj = {username: localStorage.getItem('@crabber/username'), token: localStorage.getItem('@crabber/token'), refresh: localStorage.getItem('@crabber/refresh')};
        var encoded = btoa(JSON.stringify(obj));

        window.location.href = `${crabberVideoEditingURI}?crab-clip-project=${encodedClip}&crab-control=${encoded}`;
    }

    notifyCloseDetails(){
        window.clipsComponent.hls = undefined;
        this.setState({
            details: false,
        })
    }

    loadVideoEditingEnabled(){
        let username = localStorage.getItem('@crabber/username');
        let url = UsersApi.uri + '/' + username
        const requestOptionsFacebook = {
            url: url,
            method: 'GET',
        };
        axios(requestOptionsFacebook)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        user: data,
                    });
                }
            })
            .catch(error => {
                console.error(error);
            });

        axios({
            url: UserUsageApi.uri + '/' + username, 
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
                let overview = response.data;
                let config = overview.config;

                this.setState({
                    videoEditingBetaEnabled: config.video_editing_beta_enabled
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
        
    }
    
    componentWillUnmount() {

    }
    
    
    componentDidMount() {
        let clips = localStorage.getItem('@crabber/Clips.data') ? JSON.parse(localStorage.getItem('@crabber/Clips.data')) : [] ;
        
        let email = localStorage.getItem('@crabber/username');
        let url = UsersApi.uri + '/' + email + '/accounts';
        const requestOptions = {
            url: url,
            method: 'GET',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                if (response.status === 200) {
                    this.setState({
                        youTube: data.youtube,
                        facebook: data.facebook,
                        instagram: data.instagram,
                    });
                    localStorage.setItem('@crabber/accounts',JSON.stringify(data));

                    let email = localStorage.getItem('@crabber/username');
                    
                    if (data.youtube.length > 0) {
                        let youTubeKey = data.youtube[0].key;
                        let url = YouTubeApi.uri + '/' + email + '/youtube/' + youTubeKey;
                        
                        const requestOptions = {
                            url: url,
                            method: 'GET',
                        };
                        axios(requestOptions)
                            .then(async response => {
                                const youTubeData = response.data;
                                if (response.status === 200) {
                                    data.youtube[0].youTubeChannels = youTubeData.option.channels;
                                    data.youtube[0].youTubePrivacies = youTubeData.option.privacies;
                                    data.youtube[0].youTubeSelectedChannel = youTubeData.option.channels.find(function( item ) {
                                        return youTubeData.selected.channel_id === item.id;
                                    });
                                    data.youtube[0].youTubeSelectedPrivacy= youTubeData.option.privacies.find(function( item ) {
                                        return youTubeData.selected.privacy_id === item.id;
                                    });
                                    localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                    this.setState({
                                        accounts: data,
                                    });
                                        
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                
                            });
                    }
                    
                    if (data.facebook.length > 0) {
                        let facebookKey = this.state.facebook[0].key;
                        
                        let urlFacebook = FacebookApi.uri + '/' + email + '/facebook/' + facebookKey;
                
                        const requestOptionsFacebook = {
                            url: urlFacebook,
                            method: 'GET',
                        };
                        axios(requestOptionsFacebook)
                            .then(async responseFacebook => {
                                const facebookData = responseFacebook.data;
                                if (responseFacebook.status === 200) {
                                    
                                    data.facebook[0].facebookGroups = facebookData.option.groups;
                                    data.facebook[0].facebookPages = facebookData.option.pages;
                                    data.facebook[0].facebookSelectedGroup = facebookData.option.groups.find(function( item ) {
                                        return facebookData.selected.group_id === item.id;
                                    });
                                    data.facebook[0].facebookSelectedPage = facebookData.option.pages.find(function( item ) {
                                        return facebookData.selected.page_id === item.id;
                                    });
                                    localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                    this.setState({
                                        accounts: data,
                                    });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                
                            });
                        }
                    if (data.instagram.length > 0) {
                        let instagramKey = this.state.instagram[0].key;
                        let urlInstagram = FacebookApi.uri + '/' + email + '/instagram/' + instagramKey;

                        const requestOptionsInstagram = {
                            url: urlInstagram,
                            method: 'GET',
                        };
                        axios(requestOptionsInstagram)
                            .then(async responseInstagram => {
                                const instagramData = responseInstagram.data;
                                if (responseInstagram.status === 200) {
                                    data.instagram[0].instagramPages = instagramData.option.pages;
                                    data.instagram[0].instagramSelectedPage = instagramData.option.pages.find(function( item ) {
                                        return instagramData.selected.page_id === item.id;
                                    });
                                    data.instagram[0].instagramCaption = instagramData.selected.caption;
                                    localStorage.setItem('@crabber/accounts', JSON.stringify(data));
                                    this.setState({
                                        accounts: data,
                                    });
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                
                            });
                    }

                }
                
            })
            .catch(error => {
                console.error(error);
                
            });
        
        this.setState({
            clips: clips,
        });

        this.loadVideoEditingEnabled();
    }
    

    render() {
        return (
            
            <div className='clips-container'>
                <ClipContainer details={this.state.details} selected={this.state.selected} clips={this.state.clips}/>

                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Deseja excluir o clip?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Tem certeza que deseja excluir?"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Anular
                            </Button>
                            <Button onClick={this.handleRemove} color="primary" variant="contained" disableRipple type="submit">
                                Sim
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openPublishConfirmation}
                        onClose={this.handleClosePublishConfirmation}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{"Estamos quase lá!"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"É importante você saber que o tempo para finalizar a publicação pode variar conforme a rede social e o tamanho do clip. Clique em CONTINUAR e aguarde a publicação."}
                            </DialogContentText>
                            
                            <br />
                            <FormControlLabel
                                control={
                                <PublishConfirmationCheckbox
                                    checked={this.hideCheckedPublishConfirmation}
                                    onChange={(e) => {
                                        this.setState({
                                            hideCheckedPublishConfirmation: e.target.checked
                                        });
                                        localStorage.setItem('@crabber/hideCheckedPublishConfirmation', e.target.checked);
                                    }}
                                />
                                }
                                label="Não exibir essa mensagem novamente"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClosePublishConfirmation} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                Anular
                            </Button>
                            <Button onClick={this.notifyShareClip} color="primary" variant="contained" disableRipple type="submit">
                                Continuar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openPublish}
                        onClose={this.handleClosePublish}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-pub"
                        aria-describedby="alert-dialog-description-pub">
                        <DialogTitle id="alert-dialog-title-pub">{"Publicar"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Clipe enviado para publicação"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClosePublish} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openUpload}
                        onClose={this.handleCloseUpload}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-pub"
                        aria-describedby="alert-dialog-description-pub">
                        <DialogTitle id="alert-dialog-title-pub">{"Acervar"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Clipe enviado para o acervo"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseUpload} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openUploadForbidden}
                        onClose={this.handleCloseUploadForbidden}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-pub-forbidden"
                        aria-describedby="alert-dialog-description-pub-forbidden">
                        <DialogTitle id="alert-dialog-title-pub-forbidden">{"Acervar"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {"Ops, você está tentando acervar um vídeo maior do que o espaço disponível. Para liberar espaço, vá  em  acervo e exclua os vídeos que você não queira mais ou contata o comercial do Crabber através do WhatsApp 48 98808-5900 para aumentar o limite da sua conta"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCloseUploadForbidden} color="primary" variant="contained" autoFocus disableRipple type="submit">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            
        )
    }
}


function Clips(props) {

    return (
        <div >
            <ClipsContainer />
        </div>
    )

}

export default Clips