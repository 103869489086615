import React from 'react';
import 'App.css';
import 'fontsource-roboto';
import Sidebar from 'components/commons/Sidebar'
import Ingest from 'components/studio/Ingest';
import {theme} from 'theme.js';
import { CssBaseline, ThemeProvider } from '@material-ui/core';



function App() {
  return (
    <div className='app-container'>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Sidebar />
        <Ingest />
      </ThemeProvider>
    </div>
  )
}
export default App;



