const Utils = {
    obfuscadeEmail: function(email) {
        if (!email) {
            return "";
        }

        let res = email.split('@');
        let who = res[0];
        var last = who.length - 1;
        var obfuscadeWho = '';
        for (var i = 0; i < who.length; i++) {
            if (i === 0 || i === last) {
                obfuscadeWho = obfuscadeWho.concat(who.charAt(i));
            } else {
                obfuscadeWho = obfuscadeWho.concat('*');
            }
        }
        res[0] = obfuscadeWho;
        return res.join('@');
    },

    formattedDate: function() {
        let d = new Date();
        let formatted =  
                d.toLocaleDateString("pt-BR").replaceAll('/','-')
                + ' as ' +
                d.toLocaleTimeString("pt-BR");
        return formatted;
    },

    formattedFromDate: function(date) {
        let d = new Date(date);
        let formatted =  
                d.toLocaleDateString("pt-BR").replaceAll('/','-')
                + ' as ' +
                d.toLocaleTimeString("pt-BR");
        return formatted;
    },

    humanFileSize: function(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;
      
        if (Math.abs(bytes) < thresh) {
          return bytes + ' B';
        }
      
        const units = si 
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;
      
        do {
          bytes /= thresh;
          ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
      
      
        return bytes.toFixed(dp) + ' ' + units[u];
    },
    
    humanMinutes: function(minutes) {
      let prefix = '';
      let h = parseInt(minutes / 60);
      let m = minutes % 60;
      
      if (h > 0) {
          prefix = prefix + h + ' hora';
          if (h > 1) {
              prefix = prefix + 's'
          }
      }
  
      if (m === 0) {
          return prefix;
      }
  
      if (h > 0) {
          prefix = prefix + ' e '
      }
  
      if (m === 1) {
          return prefix + m + ' minuto';
      }
  
      if (m > 1) {
          return prefix + m + ' minutos';
      }
    }

}

export default Utils;