import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

const ControllerCircularProgress = withStyles((theme) => ({
    root: {
        color: "#fff",
        height: "8vh !important",
        width: "8vh !important",
    }

}))(CircularProgress);

export default ControllerCircularProgress;