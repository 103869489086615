import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaskedInput from 'react-text-mask';
import { withStyles } from "@material-ui/core/styles";
import {UsersApi, AuthTokenApi} from 'config/config'
import { Redirect } from 'react-router-dom';
import Utils from 'functions/Utils'
import logoLogin from 'static/images/logo/login.svg'
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import axios from 'interceptors';

import './SigninForm.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const LoginTextField = withStyles((theme) => ({
    root: {
        color: "#FF9666",
        backgroundColor: '#fcfcfb',
        width: '100%',
        "& .MuiFormLabel-root": {
            color: "#FF9666" 
        },
        "& .MuiInputBase-root": {
            color: '#FF9666',
        },
        "& .MuiInputBase-input + fieldset": {
            borderColor: '#FF9666',
            borderWidth: 1,
            color: '#FF9666',
        },
        "& .MuiOutlinedInput-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #fcfcfb inset",
            WebkitTextFillColor: '#FF9666',
            fontFamily: "GalanoGrotesque, Roboto, sans-serif",
        }
    },
}))(TextField);

const LoginButton = withStyles((theme) => ({
    root: {
        color: "#fcfcfb",
        backgroundColor: '#FF9666',
        width: '100%',
        '&:hover': {
            backgroundColor: '#FF966690',
        },
        "&:disabled": {
            backgroundColor: "#fcfcfb",
            color: "#FF9666",
        }
    },
    
    
}))(Button);

const LoginLink = withStyles((theme) => ({
    root: {
        color: "#FF9666",
        marginTop:'2%',
    },
    
}))(Link);

const LoginDivider = withStyles((theme) => ({
    root: {
        backgroundColor: "#FF9666",
        margin:'8% 0%',
    },
    
}))(Divider);

const LoginIconButton = withStyles((theme) => ({
    root: {
        color: "#FF9666",
    },
    
}))(IconButton);

class SigninFormContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            waitFor: false,
            //email: 'casamassima.silvio@gmail.com',
            email: '',
            obfuscadeEmail: '',
            //password: 'sonoilvio11',
            password: '',
            cpf: '',
            redirect: null,
            open: false,
            openChangePassword: false,
            openConfirmation: false,
            openInvalidCPF: false,
            invalidCPF: true,
            showPassword: false,
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCpfChange = this.handleCpfChange.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleMouseUpPassword = this.handleMouseUpPassword.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    componentDidMount() {
    
    }

    componentDidUpdate() { 
    
    }

    handleMouseDownPassword = (event) => {
        this.setState({showPassword: true });
        event.preventDefault();
    };
    
    handleMouseUpPassword = (event) => {
        this.setState({showPassword: false });
        event.preventDefault();
    };

    handleChangePassword() {
        this.setState({
            openChangePassword: false,
        });
        const requestOptions = {
            url: UsersApi.uri + "/" + this.state.cpf + "/password",
            method: 'POST',
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                
                if (response.status === 200 || response.status === 201) {
                    let obfuscadeEmail = Utils.obfuscadeEmail(data.email);
                    this.setState({
                        obfuscadeEmail: obfuscadeEmail,
                    })
                    localStorage.setItem('@crabber/email', obfuscadeEmail);
                    this.setState({ 
                        openConfirmation: true,
                    });
                } else {
                    const error = (data && data) || response.status;
                    this.setState({ 
                        openInvalidCPF: true,
                    });
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                this.setState({ 
                    openInvalidCPF: true,
                });
                console.error(error);
            });
    }

    handleCpfChange(event) {
        let cpf = event.target.value;
        let invalidCPF = cpf.indexOf('_') >= 0;
        this.setState({
            cpf: cpf,
            invalidCPF: invalidCPF,
        });
    }


    handleEmailChange(event) {
        this.setState({
            email: event.target.value,
        });
    }



    handlePasswordChange(event) {
        this.setState({
            password: event.target.value,
        });
    }

    handleSubmit(event) {
        this.setState({ waitFor: true });
        const requestOptions = {
            url: AuthTokenApi.uri,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: { 
                                    username: this.state.email,
                                    password: this.state.password
                                 },
            
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                if (response.status === 200 || response.status === 201) {
                    localStorage.setItem('@crabber/token', data.token);
                    localStorage.setItem('@crabber/refresh', data.refresh);
                    localStorage.setItem('@crabber/username', this.state.email);
                    this.setState({ redirect: "/" });
                } else {
                    const error = (data && data) || response.status;
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ 
                    waitFor: false,
                    open: true,
                });
            });

    }

    handleClose() {
        this.setState({
            open: false,
            openChangePassword: false,
            openConfirmation: false,
            openInvalidCPF: false,
        });
    }


    render() {
        
        const openDialogChangePassword = (event) => {
            event.preventDefault();
            this.setState({
                openChangePassword: true,
            });
        };
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="container">
                <Grid container
                    direction="column"
                    justify="center"
                    alignItems="stretch" className='login-grid-container' >
                        <Grid className="signin-space">
                        </Grid>
                        <Grid >
                            <div className="signin-container">
                                <span className="left-side">
                                    <div className="welcome">
                                        <h1>Bem-vindo</h1>
                                    </div>
                                    <div className="login-form-container">
                                        <LoginTextField
                                            variant="outlined" 
                                            color="primary" 
                                            margin="dense" 
                                            placeholder="E-mail"
                                            label="E-mail" 
                                            required
                                            value={this.state.email}
                                            onChange={this.handleEmailChange}
                                            onKeyPress={(e) => {e.persist(); if ("Enter" === e.key) this.handleSubmit();}}
                                        />
                                        <LoginTextField
                                            variant="outlined" 
                                            color="primary" 
                                            margin="dense" 
                                            placeholder="Senha"
                                            label="Senha" 
                                            required
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            value={this.state.password}
                                            onChange={this.handlePasswordChange}
                                            onKeyPress={(e) => {e.persist(); if ("Enter" === e.key) this.handleSubmit();}}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                      <LoginIconButton
                                                        aria-label="toggle password visibility"
                                                        onMouseDown={this.handleMouseDownPassword}
                                                        onMouseUp={this.handleMouseUpPassword}
                                                        disableRipple
                                                        edge="end"
                                                      >
                                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                      </LoginIconButton>
                                                    </InputAdornment>
                                                  
                                            }}
                                        />
                                        <LoginLink href="" onClick={openDialogChangePassword} className="link">
                                            Esqueceu a senha?
                                        </LoginLink>
                                        <LoginButton 
                                        variant="contained" 
                                        color="primary" 
                                        type="submit"
                                        autoFocus
                                        disabled={!this.state.email || !this.state.password || this.state.waitFor}
                                        disableRipple
                                        onClick={this.handleSubmit}
                                        style={{
                                            marginTop: '10%'
                                        }}>
                                        Login
                                        {this.state.waitFor && <CircularProgress style={{
                                                                        color: 'white',
                                                                        position: 'absolute',
                                                                        width: 24,
                                                                        height: 24
                                                                    }}/>}
                                        </LoginButton>
                                        <LoginDivider variant="fullWidth"/>
                                        
                                        <div className="signup-phrase">
                                            Ainda não tem uma conta?  
                                            <LoginButton 
                                                variant="contained" 
                                                color="primary" 
                                                type="submit"
                                                autoFocus
                                                disableRipple
                                                href="/signup">
                                                CADASTRE-SE
                                                {this.state.waitFor && <CircularProgress style={{
                                                                                color: 'white',
                                                                                position: 'absolute',
                                                                                width: 24,
                                                                                height: 24
                                                                            }}/>}
                                                </LoginButton>
                                            
                                        </div>
                                    </div>
                                </span>
                                <span className="right-side">
                                    <img alt="logo" src={logoLogin} className='logo-login' />
                                </span>
                            </div>
                        </Grid>
                        <Grid >
                            
                        </Grid>
                        
                </Grid>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        
                        aria-describedby="alert-dialog-description"
                        >
                            
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                E-mail ou senha invalido(s). Verifique novamente.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openChangePassword}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="form-dialog-title"
                        aria-describedby="alert-dialog-description-cp"
                        >
                            <DialogTitle id="form-dialog-title">Esqueceu a senha?</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-cp">
                                    Para recuperar seu email e senha, digite o seu CPF.
                                </DialogContentText>
                                        <TextField
                                                variant="outlined" 
                                                color="primary" 
                                                margin="dense" 
                                                placeholder="CPF"
                                                label="CPF" 
                                                required
                                                fullWidth
                                                value={this.state.cpf}
                                                onChange={this.handleCpfChange}
                                                InputProps={{
                                                    inputComponent: TextMaskCustom,
                                                }}
                                        >
                                            
                                        </TextField>
                            </DialogContent>
                                
                                
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus disableRipple 
                                        type="submit">
                                    CANCELAR
                                </Button>
                                <Button onClick={this.handleChangePassword} 
                                        color="primary" 
                                        variant="contained" 
                                        disabled={this.state.invalidCPF}
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openConfirmation}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-c"
                        aria-describedby="alert-dialog-description-c"
                        >
                            
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-c">
                                Seu dados foram enviados para o email {this.state.obfuscadeEmail}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.openInvalidCPF}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title-cpf"
                        aria-describedby="alert-dialog-description-cpf"
                        >
                            
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description-cpf">
                                CPF não encontrado.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    OK
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        showMask
      />
    );
  }

function SigninForm(props) {
    

    return (
        <div>
            <SigninFormContainer />
        </div>
    )

}

export default SigninForm