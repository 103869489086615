import React, { useMemo, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import logo from 'static/images/avatar/AVATAR-04.png';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import studioIcon from 'static/images/icons/studio.png';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Player from 'components/studio/Player';
import libraryIcon from 'static/images/icons/library.png';
import personIcon from 'static/images/icons/person.png';
import socialIcon from 'static/images/icons/social.png';
import { Redirect } from 'react-router';
import { doVerifyToken } from 'config/network';
import './Sidebar.css';

const NavigationListItem = withStyles({
    root: {
        paddingBottom: 10,
        paddingTop: 10,
        flexDirection: 'column',
    }, 
  })(ListItem);

  const NavigationListItemText = withStyles({
    root: {
        textAlign: 'center',
    }, 
  })(ListItemText);

const CrabberDrawer = withStyles({
    paper: {
        width: 97
    }
})(Drawer)

class FormSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            intervalId: null,
            selected: '',
            openLibraryTooltip: false,
        };
        window.sidebarMenu = this;

        this.handleListItemClick = this.handleRedirectTo.bind(this);
        this.notifyOpenLibraryTooltip = this.notifyOpenLibraryTooltip.bind(this);
        this.notifyCloseLibraryTooltip = this.notifyCloseLibraryTooltip.bind(this);
    }
    componentDidMount(){
        this.setState({
            selected: this.props.selected,
        });
    }

    notifyOpenLibraryTooltip() {
        if (localStorage.getItem('@crabber/openLibraryTooltipAgain') === 'false') {
            return;
        }
        
        this.setState({
            openLibraryTooltip: true,
        });

    }

    notifyCloseLibraryTooltip() {
        this.setState({
            openLibraryTooltip: false,
        });
        localStorage.setItem('@crabber/openLibraryTooltipAgain', 'false');
    }

    handleRedirectTo(to) {
        
        this.setState({
            redirect: to,
        });

    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div>
                <CrabberDrawer 
                variant="permanent"
                anchor='left'
                open>
                    <div className='navigation'>
                        <List>
                            <NavigationListItem  button
                                onClick={() => {
                                    if (window.playerControllerComponent && this.state.selected === '/studio' && localStorage.getItem('@crabber/Player.liveStatus') !== 'Sessão encerrada' && Player.hls && Player.hls.liveSyncPosition) {
                                        window.playerControllerComponent.setState({open: true})
                                    } else {
                                        this.handleRedirectTo('/')
                                    }
                                }}>
                                <ListItemIcon>
                                    <Avatar alt="Home Page" src={logo} />
                                </ListItemIcon>
                                <NavigationListItemText secondary="Painel de captura" />
                            </NavigationListItem >
                        </List>
                        <Divider />
                        <List > 
                            <NavigationListItem 
                                button 
                                selected={this.state.selected === '/studio'}
                                onClick={() => this.handleRedirectTo('/studio')}>
                                    <ListItemIcon>
                                        <Avatar alt="Studio" src={studioIcon} />
                                    </ListItemIcon>
                                    <NavigationListItemText secondary="Estúdio" />
                            </NavigationListItem>
                            <Tooltip arrow interactive placement="right"
                                open={this.state.openLibraryTooltip}
                                title={
                                    <React.Fragment>
                                        <div className="tooltip-icons">
                                           <CloseOutlinedIcon onClick={() =>{ this.notifyCloseLibraryTooltip(); }}/>
                                        </div>
                                        <Typography color="inherit">
                                        Clique aqui para ver seus clipes acervados.
                                        </Typography>
                                    </React.Fragment>
                                } >
                                <div>
                                    <NavigationListItem 
                                        button 
                                        selected={this.state.selected === '/storage'}
                                        onClick={() => this.handleRedirectTo('/storage')}>
                                            <ListItemIcon>
                                                <Avatar alt="Storage" src={libraryIcon} />
                                            </ListItemIcon>
                                            <NavigationListItemText secondary="Acervo" />
                                    </NavigationListItem>
                                </div>
                            </Tooltip>

                            
                            <NavigationListItem 
                                button 
                                selected={this.state.selected === '/person'}
                                onClick={() => this.handleRedirectTo('/person')}>
                                    <ListItemIcon>
                                        <Avatar alt="Person" src={personIcon} />
                                    </ListItemIcon>
                                    <NavigationListItemText secondary="Conta" />
                            </NavigationListItem>
                            <NavigationListItem 
                                button 
                                selected={this.state.selected === '/social'}
                                onClick={() => this.handleRedirectTo('/social')}>
                                    <ListItemIcon>
                                        <Avatar alt="Social" src={socialIcon} />
                                    </ListItemIcon>
                                    <NavigationListItemText secondary="Redes Sociais" />
                            </NavigationListItem>
                        </List>
                        <Divider />
                        <List>
                            <NavigationListItem button 
                                onClick={() => this.handleRedirectTo('/login')}>
                                    <NavigationListItemText secondary="Sair" />
                            </NavigationListItem>
                        </List>
                    </div>
                    
                </CrabberDrawer>
            </div>
        )
    }
}

function Sidebar() {
    const location = useLocation();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
        if(searchParams.has('crab-control')){
            const crabControl = searchParams.get('crab-control');
            var decoded = JSON.parse(atob(crabControl));
            localStorage.setItem('@crabber/username', decoded.username);
            localStorage.setItem('@crabber/token', decoded.token);
            localStorage.setItem('@crabber/refresh', decoded.refresh);

            const fetchVerifyToken = async () => {
                await doVerifyToken();
            }
            
            fetchVerifyToken();

            // history.push('/');
        }else{
            doVerifyToken();
        }

        const interval = setInterval(function(){
            doVerifyToken();
        }, 60000);
        return () => clearInterval(interval);

    }, []);

    return (   
        <FormSidebar selected={location.pathname} />
    )
}
  

export default Sidebar;