import React from 'react';
import { theme } from 'theme.js';
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ChangePasswordApi} from 'config/config'
import { Redirect } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import logo from 'static/images/logo/LOGOS_TRANSPARENTE-08.png'
import {Translation, initReactI18next } from 'react-i18next';
import i18n from 'i18n'
import axios from 'interceptors';
import 'ChangePassword.css'

i18n
  .use(initReactI18next)

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const SignupCheckbox = withStyles((theme) => ({
    root: {
        '&:hover': {
          backgroundColor: '#70707020',
        },
        color: '#ffffff23',
    },
    colorSecondary: {
        '&:hover': {
            backgroundColor: '#70707020',
        },
        '&$checked': {
            color: '#ffffff',
        },
        
    },
    checked: {},
}))(Checkbox);

const PasswordTextField = withStyles((theme) => ({
    root: {
        '& .MuiFormHelperText-root': {
            color: "#FFFFFF",
            fontSize: "0.85rem"
        }
    }
}))(TextField);

class ChangePasswordContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            pin: '',
            password: '',
            repeatPassword: '',
            waitFor: false,
            redirect: null,
            open: false,
            errorMessage: '',
            showPasswordChecked: false,
            passwordHelperText: 'Importante: Sua senha deve possuir no mínimo 8 caracteres e conter letras e números.',
            checkPasswordHelperText: '',
        };

        this.handlePinChange = this.handlePinChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRepeatPasswordChange = this.handleRepeatPasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowPasswordChange = this.handleShowPasswordChange.bind(this);
        this.getErrorNumber = this.getErrorNumber.bind(this);
    }

    getErrorNumber() {
        if (!this.state.errorMessage) {
            return '#000000';
        }
        return this.state.errorMessage.substring(0,7);
    }

    handleShowPasswordChange(event) {
        this.setState({
            showPasswordChecked: event.target.checked,
        });
    }

    componentDidMount() {
        this.setState({
            email: this.props.email,
        });

    }

    componentDidUpdate() { 
    
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }

    handleSubmit(){

        var pinRegex = new RegExp("^[1-9]{6}$");
        var strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])?(?=.{8,})");
        
        
        if(!pinRegex.test(this.state.pin)) {
            this.setState({
                open: true,
                errorMessage: '#130501',
            });
            return;
        }

        if(!strongRegex.test(this.state.password)) {
            this.setState({
                open: true,
                errorMessage: '#120413',
                passwordHelperText: 'Importante, sua senha deve possuir no mínimo 8 caracteres e conter letras e números.'
            });
            return;
        }

        this.setState({ waitFor: true });
        let url = ChangePasswordApi.uri + '/' + this.props.email + '/password';
        const requestOptions = {
            url: url,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            data:   { 
                        secret: this.state.pin,
                        password: this.state.password,
                    },
            
        };
        axios(requestOptions)
            .then(async response => {
                const data = response.data;
                this.setState({ waitFor: false });
                
                if (response.status === 200 || response.status === 201) {
                    
                    this.setState({ redirect: "/login" });
                } else {
                    const error = (data && data) || response.status;
                    
                    return Promise.reject(error);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ 
                    waitFor: false,
                    open: true,
                    errorMessage: error.response.data?.reason,
                });
            });

    }
    

    handlePinChange(event) {
        this.setState({
            pin: event.target.value,
        });
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value,
        });
        if (event.target.value === this.state.repeatPassword) {
            this.setState({
                checkPasswordHelperText: ''
            });
        } else {
            this.setState({
                checkPasswordHelperText: 'Senhas diferentes'
            });
        }
        var strongRegex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])?(?=.{8,})");
        if(strongRegex.test(event.target.value)) {
            this.setState({
                passwordHelperText: ''
            });
        } else {
            this.setState({
                passwordHelperText: 'Importante: Sua senha deve possuir no mínimo 8 caracteres e conter letras e números.'
            });
        }
    }

    handleRepeatPasswordChange(event) {
        this.setState({
            repeatPassword: event.target.value,
        });
        if (event.target.value === this.state.password) {
            this.setState({
                checkPasswordHelperText: ''
            });
        } else {
            this.setState({
                checkPasswordHelperText: 'Senhas diferentes'
            });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <Translation>
            {
            t =>
            <div>
                
                <Grid container
                    direction="row"
                    justify="space-between"
                    alignItems="stretch" className='change-password-grid-container' >
                    <Grid item xs={4}>
                        
                    </Grid>
                    <Grid item xs={4} >
                        <img alt="logo" src={logo} className='logo' />
                        <Typography gutterBottom variant="h5" component="h2">
                                Redefinir a senha
                        </Typography>
                        <TextField
                            variant="outlined" 
                            color="primary" 
                            margin="dense" 
                            placeholder="PIN"
                            label="PIN" 
                            fullWidth
                            required
                            value={this.state.pin}
                            onChange={this.handlePinChange}
                            InputProps={{
                                inputComponent: TextMaskCustom,
                            }}
                        />
                        <PasswordTextField
                            variant="outlined" 
                            color="primary" 
                            margin="dense" 
                            placeholder="Senha"
                            label="Senha"
                            helperText={this.state.passwordHelperText} 
                            required
                            fullWidth
                            type={this.state.showPasswordChecked ? 'text' : 'password'}
                            value={this.state.password}
                            onChange={this.handlePasswordChange}
                        />
                        <PasswordTextField
                            variant="outlined" 
                            color="primary" 
                            margin="dense" 
                            placeholder="Repetir senha"
                            label="Repetir senha"
                            helperText={this.state.checkPasswordHelperText} 
                            required
                            fullWidth
                            type={this.state.showPasswordChecked ? 'text' : 'password'}
                            value={this.state.repeatPassword}
                            onChange={this.handleRepeatPasswordChange}
                        />
                        <div>
                                <SignupCheckbox
                                        checked={this.state.showPasswordChecked}
                                        onChange={this.handleShowPasswordChange}
                                    />Mostrar as senhas
                        </div>
                        <div className="change-password-spacing"></div>
                        <Button 
                            className="change-password-reset-button"
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            autoFocus
                            disabled={!this.state.pin || !this.state.password || this.state.repeatPassword !== this.state.password || this.state.waitFor}
                            disableRipple
                            onClick={this.handleSubmit}>
                            Redefinir
                            {this.state.waitFor && <CircularProgress style={{
                                                            color: 'white',
                                                            position: 'absolute',
                                                            width: 24,
                                                            height: 24
                                                        }}/>}
                        </Button>
                    </Grid>
                    <Grid item xs={4} >
                        
                    </Grid>
                </Grid>
                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                            
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t(this.getErrorNumber())}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            
                                <Button onClick={this.handleClose} 
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus 
                                        disableRipple 
                                        type="submit">
                                    FECHAR
                                </Button>
                            
                            </DialogActions>
                    </Dialog>
                </div>
            </div>
            }
            </Translation>
        )
    }
}
function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        showMask
      />
    );
  }


function ChangePassword(props) {
    return (
        <div className='changePassword-container'>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ChangePasswordContainer email={props.match.params.email} />
            </ThemeProvider>
        </div>
    )

}

export default ChangePassword