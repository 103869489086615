import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom"
import 'index.css';
import * as serviceWorker from './serviceWorker';
import App from 'App';
import Studio from 'Studio';
import Login from 'Login';
import ChangePassword from 'ChangePassword';
import ConfirmRegistration from 'ConfirmRegistration';
import YouTubeAccount from 'YouTubeAccount';
import FacebookAccount from 'FacebookAccount';
import Signup from 'Signup';
import Social from 'Social';
import Person from 'Person';
import Library from 'Library';



ReactDOM.render(
    <Router forceRefresh={true}>
      <div>
        <Route exact path="/" component={App} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/studio" component={Studio} />
        <Route exact path="/social" component={Social} />
        <Route exact path="/person" component={Person} />
        <Route exact path="/storage" component={Library} />
        <Route exact path="/change-password/:email" component={ChangePassword} />
        <Route exact path="/signup/:email/:confirmationId" component={ConfirmRegistration} />
        <Route exact path="/youtube/:youtubeKey" component={YouTubeAccount} />
        <Route exact path="/facebook/:facebookKey" component={FacebookAccount} />
      </div>
    </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
