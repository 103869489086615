import React from 'react';
import { theme } from 'theme.js';
import { Redirect } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import {UsersApi} from 'config/config'
import axios from 'interceptors';


class FacebookAccountContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            facebookKey: '',
            redirect: null,
        };

        
    }

    
    componentDidMount() {
        this.setState({
            facebookKey: this.props.facebookKey,
        });
        let email = localStorage.getItem('@crabber/username');
        let url = UsersApi.uri + '/' + email + '/facebook/' + this.props.facebookKey + '/bind';
        const requestOptions = {
            url: url,
            method: 'POST',
        };
        axios(requestOptions)
            .then(async response => {
                this.setState({ redirect: "/social" });
            })
            .catch(error => {
                console.error(error);
                this.setState({ 
                    redirect: "/social",
                });
            });

    }

    componentDidUpdate() { 
    
    }


    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
           
            <div>
                
            </div>
            
        )
    }
}

function FacebookAccount(props) {
    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <FacebookAccountContainer facebookKey={props.match.params.facebookKey} />
            </ThemeProvider>
        </div>
    )

}

export default FacebookAccount